import { Box, createStyles, Divider, Grid, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { makeStyles, withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import feedbackPage from 'assets/jss/material-kit-react/views/feedbackPage';
import homeCalPageStyle from 'assets/jss/material-kit-react/views/homeCalPage';
import portfolioPageStyle from 'assets/jss/material-kit-react/views/portfolioPage';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { resetEditTableField } from 'redux/actions/DebtCalActions';
import Alert from '@mui/material/Alert';
import RenderField from 'utils/RenderField';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import RecurringPaymentUpload from './RecurringPaymentCalUpload';
import CustomDialogTitle from 'components/DialogTitle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { getRecurringFiles, submitRecurring, submitRecurringSuccess, fetchSelectedRecurring, getRecurringCategory } from 'redux/actions/RecurringPaymentActions';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...homeCalPageStyle(theme),
        ...portfolioPageStyle(theme),
        ...feedbackPage(theme)
    })
);

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
};
const AddNewRecurringPayment = (props) => {
    const theme = useTheme();
    const classes = mergedStyle();
    const dispatch = useDispatch();
    const recurringPayment = useSelector((state) => state.recurringPayment);
    const [selectedTab, setSelectedTab] = useState(0);
    const [open, setOpen] = useState(false);
    const handleTabChange = (event, newValue) => setSelectedTab(newValue);
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        reset
    } = useForm({ mode: 'onChange' });
    const handleCategoryOptions = () => {
        const default_list = [
            { value: 'cable', label: 'Cable' },
            { value: 'credit card', label: 'Credit Card' },
            { value: 'utility', label: 'Utility' },
            { value: 'subscription', label: 'Subscription' },
            { value: 'addNew', label: 'Other' }
        ]
        const list = props?.recurringCategory?.map((val) => {
            return {
                value: val.name,
                label: val.name
            };
        });
        return list && list.length > 0
            ? [...list, ...default_list]
            : default_list;
    };
    const [fieldsDetail, setFieldsDetail] = useState([
        { name: 'recurringFileId', label: 'Recurring Payment File', type: 'select', disableEmptyValue: true },
        {
            name: 'name',
            label: 'Name',
            type: 'text',
            placeholder: 'Enter Name',
            multiline: true,
            required: true,
            maxRows: 4,
            maxLength: 150,
            custompattern: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g
        },
        { name: 'category', label: 'Category', type: 'select'},
        {
            name: 'monthly_amount',
            label: 'Monthly Amount',
            type: 'number',
            required: true,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100000'
        },
        { name: 'pay_type', label: 'Pay Type', type: 'select',defaultValue: "auto pay", options: [
            { value: 'auto pay', label: 'Auto Pay' },
            { value: 'online', label: 'Online' },
            { value: 'check', label: 'Check' }
        ] },
        { name: 'auto_pay_account_name', label: 'Auto Pay Account Name', type: 'text', customPatternName: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g, maxLength: 100 },
        { name: 'payment_period', label: 'Payment Period', type: 'select',defaultValue: 'monthly', options: [
            { value: 'monthly', label: 'Monthly' },
            { value: 'quaterly', label: 'Quaterly' },
            { value: 'annualy', label: 'Annualy' }
        ] },
        { name: 'auto_renewal', label: 'Auto Renewal', type: 'select', options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
        ] },
        {
            name: 'importance',
            label: 'Importance',
            type: 'select',
            defaultValue: 1,
            options: [
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
                { value: 5, label: 5 },
                { value: 6, label: 6 },
                { value: 7, label: 7 },
                { value: 8, label: 8 },
                { value: 9, label: 9 },
                { value: 10, label: 10 }
            ]
        },
        {
            name: 'comment',
            label: 'Comments',
            type: 'text',
            placeholder: 'Enter Comments',
            multiline: true,
            maxRows: 4,
            maxLength: 150,
            custompattern: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g
        },
        { name: 'contact', label: 'Contact', type: 'text', customPatternName: /^[a-zA-Z0-9@&()]+$/, maxLength: 100 }
    ]);
    const { setRowValue, setInputValue } = props;
    const tabLabel = props.open ? 'Edit item' : 'Single Upload';

    //open add new purchase popup
    const handleClickOpen = () => {
        setOpen(true);
        dispatch(resetEditTableField(true));
    };

    const matchfile = (filename) => {
        // Use find instead of map for searching
        const matchingFile = recurringPayment?.recurringFileList?.find((item) => item.file_name === filename);

        // Check if a matching file was found
        if (matchingFile) {
            return matchingFile.id;
        }
    };


    //close add new purchase popup
    const handleClose = () => {
        if (props.rowValue != undefined) {
            setInputValue(false);
            setRowValue('');
        }
        reset({});
        setIsCustomCategory(false);
        setCustomCategory('');
        setOpen(false);
    };
    const [customCategory, setCustomCategory] = useState('');
    const [isCustomCategory, setIsCustomCategory] = useState(false);

    const handleCategoryChange = (event) => {
        if (event.target.value === 'addNew') {
            setIsCustomCategory(true);
            setValue('category', '');
        } else {
            setIsCustomCategory(false);
            setValue('category', event.target.value);
        }
    };


    //to submit the form data
    const onSubmit = (data) => {
        data.monthly_amount.toString().replace(/,/g, '');
        const finalCategory = isCustomCategory ? customCategory : data.category;
        setIsCustomCategory(false);
        setCustomCategory('');
        let objToPass = {
            name: data.name || null,
            category: finalCategory || "",
            pay_type: data.pay_type || "",
            monthly_amount: data.monthly_amountUnFormattedValue || data.monthly_amount || null,
            auto_pay_account_name: data.auto_pay_account_name || "",
            payment_period: data.payment_period || "",
            auto_renewal: data.auto_renewal || "",
            importance: data.importance || null,
            comment: data.comment || "",
            contact: data.contact || ""
        };
        // let edit_data = objToPass;
        objToPass = { 
            fileName:
                data.recurringFileId === undefined && props.open == true
                    ? matchfile(data.recurringFileId.file_name)
                    : data.recurringFileId,
            data: [objToPass]
         };
        if (data && props.open && props.rowValue) {
            // dispatch(submitHomePurchSmartTable(edit_data, 'put'));
            setInputValue(false);
            setRowValue('');
        } else {
            dispatch(submitRecurring(objToPass, 'post'));
        }
    };
    // create format to pass file options
    const fileOptions = () => {
        return recurringPayment.recurringFileList.map((val) => ({
            value: val.id,
            label: val.file_name
        }));
    };
    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (recurringPayment?.submitSuccess) {
            handleClose();
            dispatch(getRecurringFiles());
            dispatch(fetchSelectedRecurring({"ids": [props.selectedRecurring.id] }));
            dispatch(submitRecurringSuccess(false));
            dispatch(getRecurringCategory());
        }
    }, [recurringPayment?.submitSuccess]);
    return (
        <Box>
            {!props.open && (
                <Tooltip title="Add" arrow>
                    <CustomButton
                        style={{ width: '100%' }}
                        variant="text"
                        startIcon={<AddIcon />}
                        aria-label="add"
                        onClick={handleClickOpen}
                    >
                        Add New Data
                    </CustomButton>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                    {props.open ? 'Edit Recurring Payment' : 'Add New Recurring Payment'}
                </CustomDialogTitle>
                <Divider />
                <TopBlockBox>
                    <Tabs
                        className="topblock-tabs"
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="full width tabs example"
                    >
                        <Tab label={tabLabel} {...a11yProps(0)} className={classes.appbarTab} />
                        {!props.open && <Tab label="Batch Upload" {...a11yProps(1)} className={classes.appbarTab} />}
                    </Tabs>
                    <SwipeableViews
                        className={classes.tabBody}
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={selectedTab}
                        onChangeIndex={(index) => setSelectedTab(index)}
                    >
                        <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                            {/* <CustomPaper elevation={3}> */}
                            <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '6px' }}>
                                <Grid container spacing={2}>
                                    {fieldsDetail.map((val, i) => {
                                        const optionsList = val.name == 'recurringFileId' ? fileOptions() : val.options;
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                key={i + 'addNewRecurrPayField'}
                                                className={val.name === 'sold_listed_date' ? classes.autoMargin : ''}
                                            >
                                                { val.name === 'category' ? isCustomCategory ? (
                                                    <RenderField
                                                        label="Custom Category"
                                                        name={val.name}
                                                        disableEmptyValue={true}
                                                        control={control}
                                                        id={val.name}
                                                        type={'text'}
                                                        value={customCategory}
                                                        onChange={(e) => setCustomCategory(e.target.value)}
                                                        required={val.required || false}
                                                    />
                                                ) : 
                                                (
                                                    <RenderField
                                                        name={val.name}
                                                        disableEmptyValue={true}
                                                        control={control}
                                                        label={val.label}
                                                        id={val.name}
                                                        type={val.type}
                                                        options={handleCategoryOptions()}
                                                        required={val.required || false}
                                                        thousandSeparator={val.commaSep}
                                                        customPatternName={val.custompattern}
                                                        placeholder={val.placeholder}
                                                        setValue={setValue}
                                                        onChange={handleCategoryChange}
                                                        addNew="addNew"
                                                        {...val}
                                                    />
                                                )
                                                 : (
                                                    <RenderField
                                                        name={val.name}
                                                        disableEmptyValue={true}
                                                        control={control}
                                                        label={val.label}
                                                        id={val.name}
                                                        type={val.type}
                                                        options={optionsList}
                                                        required={val.required || false}
                                                        thousandSeparator={val.commaSep}
                                                        customPatternName={val.custompattern}
                                                        placeholder={val.placeholder}
                                                        setValue={setValue}
                                                        addNew="addNew"
                                                        {...val}
                                                    />
                                                )}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <CalCardActions className="contactus-cardaction">
                                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </CustomButton>
                                </CalCardActions>
                            </form>
                            {/* </CustomPaper> */}
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                            <RecurringPaymentUpload dispatch={dispatch} a11yProps={a11yProps}></RecurringPaymentUpload>
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </Dialog>
        </Box>
    );
};
export default AddNewRecurringPayment;
