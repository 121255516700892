import React from 'react';
import { CategoryCard } from 'assets/jss/KnowledgeResearchStyle';
import { CardTextBox } from 'assets/jss/KnowledgeResearchStyle';
import moment from 'moment';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { Typography, Grid, Box, useMediaQuery } from '@mui/material';
import { BlogTitleToolTipStyle } from 'assets/jss/BlogStyle';
import './index.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

const extractBase64Image = (htmlString) => {
    const regex = /<img.*?src="data:image\/(.*?);base64,(.*?)".*?>/g;
    const matches = regex.exec(htmlString);

    if (matches && matches.length >= 3) {
        return `data:image/${matches[1]};base64,${matches[2]}`;
    }
    return null;
};

const extractImageUrl = (htmlString) => {
    const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g;
    const matches = regex.exec(htmlString);
    if (matches && matches.length >= 3) {
        return matches[2];
    }
    return null;
};

function BlogsTileView({ blogsList, blogCategory }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // Organize blogs by category name
    const blogsByCategory = blogsList?.reduce((acc, blog) => {
        if (Array.isArray(blog.category_name)) {
            blog.category_name.forEach((category) => {
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(blog);
            });
        }
        return acc;
    }, {});

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            { blogsList?.category_name ? (
                <h2 style={{ height: '40px', color: '#ff0707', fontWeight: 900 }}>{ blogsList[0]?.category_name[0]?.toUpperCase() }</h2>
            )  : "" }
            { blogsByCategory ? Object.entries(blogsByCategory).map(([categoryName, blogs], categoryIndex) => (
                <React.Fragment key={categoryIndex}>
                    <Grid item xs={12}>
                        {blogCategory === 'All' && <hr style={{ border: '#beb4b4 solid 1px' }} />}
                        {categoryIndex === 0 ? (
                            ''
                        ) : (
                            <h4 style={{ lineHeight: '0.5em', color: '#ff0707', fontWeight: 900 }}>{categoryName?.toUpperCase()}</h4>
                        )}
                    </Grid>
                    {blogs?.length ? (
                        <>
                            {blogs.map((blog, index) => {
                                const parser = new DOMParser();
                                const parsedContent = parser.parseFromString(blog.content, 'text/html');
                                parsedContent.querySelectorAll('a').forEach((a) => a.remove());
                                const textContent = parsedContent.body.textContent;
                                const imageUrl = extractImageUrl(blog.content);
                                const imageSrc = extractBase64Image(blog.content);

                                return (
                                    <>
                                        {!isMobile ? (
                                            <>
                                                {index === 0 ? (
                                                    <>
                                                        {blogs.length !== 1 && (blogs.length === 2 || blogs.length === 3) ? (
                                                            <Grid display="flex">
                                                                <Grid item xs={blogs.length === 2 ? 8.9 : 6} key={index}>
                                                                    <a
                                                                        href={`/knowledge-and-research/blog/${blog.title
                                                                            .toLowerCase()
                                                                            .replace(/\s+/g, '-')
                                                                            .replace(/[^a-z0-9\-]/g, '')}`}
                                                                        target="_blank"
                                                                    >
                                                                        <Box className="blog-item" position="relative">
                                                                            <Box marginBottom="10px">
                                                                                <CategoryCard
                                                                                    className="category-card"
                                                                                    height="425px"
                                                                                    backgroundImage={
                                                                                        blog?.image_url !==
                                                                                        'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                            ? blog.image_url
                                                                                            : imageSrc
                                                                                            ? imageSrc
                                                                                            : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                    }
                                                                                    style={{
                                                                                        borderRadius: '8px',
                                                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                                                        transition: 'transform 0.3s ease',
                                                                                        position: 'relative',
                                                                                        overflow: 'hidden'
                                                                                    }}
                                                                                >
                                                                                    <Box
                                                                                        position="absolute"
                                                                                        top="10px"
                                                                                        right="10px"
                                                                                        backgroundColor="rgba(0, 0, 0, 0.6)"
                                                                                        padding="5px 10px"
                                                                                        borderRadius="4px"
                                                                                    >
                                                                                        <Typography
                                                                                            variant="caption"
                                                                                            color="white"
                                                                                            fontSize="12px"
                                                                                        >
                                                                                            {moment(blog.published_at, 'DD-MM-YYYY').format(
                                                                                                'MM/DD/YYYY'
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                     <Typography
                                                                                            variant="h4"
                                                                                            fontWeight="bold"
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                bottom: 0,
                                                                                                left: 0,
                                                                                                right: 0,
                                                                                                padding: '10px',
                                                                                                backgroundColor:"#d9d9d9"
                                                                                            }}
                                                                                        >
                                                                                            {blog.title.charAt(0).toUpperCase() +
                                                                                                blog.title.slice(1).toLowerCase()}
                                                                                        </Typography>
                                                                                </CategoryCard>
                                                                            </Box>
                                                                        </Box>
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={3} display="flex">
                                                                    {blogs.slice(1).map((blog, index) => {
                                                                        //    Parse the HTML content string
                                                                        const parser = new DOMParser();
                                                                        const parsedContent = parser.parseFromString(
                                                                            blog.content,
                                                                            'text/html'
                                                                        );
                                                                        // Remove all anchor tags from the parsed content
                                                                        parsedContent.querySelectorAll('a').forEach((a) => a.remove());
                                                                        // Get the text content from the parsed HTML
                                                                        const textContent = parsedContent.body.textContent;
                                                                        const imageUrl = extractImageUrl(blog.content);
                                                                        const imageSrc = extractBase64Image(blog.content);

                                                                        return (
                                                                            <>
                                                                                <a
                                                                                    key={index}
                                                                                    href={`/knowledge-and-research/blog/${blog.title
                                                                                        .toLowerCase()
                                                                                        .replace(/\s+/g, '-')
                                                                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                                                                    target="_blank"
                                                                                >
                                                                                    <Card className="card" sx={{ width: '250px' }}>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            height="194"
                                                                                            image={
                                                                                                blog.image_url !==
                                                                                                'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                                    ? blog.image_url
                                                                                                    : // : imageUrl
                                                                                                    // ? imageUrl
                                                                                                    imageSrc
                                                                                                    ? imageSrc
                                                                                                    : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                            }
                                                                                            alt="Paella dish"
                                                                                            className="category-card"
                                                                                        />
                                                                                        <CardContent>
                                                                                            <Typography
                                                                                                variant="h6"
                                                                                                color="black"
                                                                                                overflow="hidden"
                                                                                                textOverflow="ellipsis"
                                                                                                // whiteSpace="nowrap"
                                                                                                style={{ marginTop: '-25px' }}
                                                                                                display="flex"
                                                                                                flexWrap="nowrap"
                                                                                                className="category-title"
                                                                                            >
                                                                                                {blog.title.length < 35
                                                                                                    ? blog.title.charAt(0).toUpperCase() +
                                                                                                      blog.title.slice(1, 35)
                                                                                                    : blog.title.charAt(0).toUpperCase() +
                                                                                                      blog.title.slice(1, 45) +
                                                                                                      '....'}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                color="black"
                                                                                                overflow="hidden"
                                                                                                textOverflow="ellipsis"
                                                                                                // whiteSpace="nowrap"
                                                                                                style={{
                                                                                                    paddingTop: '5px',
                                                                                                    maxHeight: '130px'
                                                                                                }}
                                                                                                display="flex"
                                                                                                flexWrap="nowrap"
                                                                                                className="category-content"
                                                                                            >
                                                                                                {textContent.length < 120
                                                                                                    ? textContent.slice(0, 120)
                                                                                                    : textContent.slice(0, 120) + '....'}
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                color="gray"
                                                                                                style={{
                                                                                                    marginTop: '5px',
                                                                                                    fontSize: '12px'
                                                                                                }}
                                                                                            >
                                                                                                {moment(
                                                                                                    blog.published_at,
                                                                                                    'DD-MM-YYYY'
                                                                                                ).format('MM/DD/YYYY')}
                                                                                            </Typography>
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </a>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12} key={index}>
                                                                <a
                                                                    href={`/knowledge-and-research/blog/${blog.title
                                                                        .toLowerCase()
                                                                        .replace(/\s+/g, '-')
                                                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                                                    target="_blank"
                                                                >
                                                                    <Box className="blog-item">
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="row"
                                                                            marginTop="30px"
                                                                            marginBottom="10px"
                                                                        >
                                                                            <Box width="50%">
                                                                                <CategoryCard
                                                                                    className="category-card"
                                                                                    height="350px"
                                                                                    backgroundImage={
                                                                                        blog?.image_url !==
                                                                                        'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                            ? blog?.image_url
                                                                                            : // : imageUrl
                                                                                            // ? imageUrl
                                                                                            imageSrc
                                                                                            ? imageSrc
                                                                                            : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                                    }
                                                                                    style={{
                                                                                        borderRadius: '8px',
                                                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                                                        transition: 'transform 0.3s ease'
                                                                                    }}
                                                                                ></CategoryCard>
                                                                            </Box>
                                                                            <Box width="50%" paddingLeft="20px" paddingTop="10px">
                                                                                <BlogTitleToolTipStyle>
                                                                                    <Typography
                                                                                        variant="h5"
                                                                                        color="black"
                                                                                        overflow="hidden"
                                                                                        textOverflow="ellipsis"
                                                                                        display="flex"
                                                                                        flexWrap="nowrap"
                                                                                        className="category-title"
                                                                                        style={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '1.5rem'
                                                                                        }}
                                                                                    >
                                                                                        {blog.title.charAt(0).toUpperCase() +
                                                                                            blog.title.slice(1).toLowerCase()}
                                                                                    </Typography>
                                                                                </BlogTitleToolTipStyle>

                                                                                <Typography
                                                                                    variant="h6"
                                                                                    color="black"
                                                                                    overflow="hidden"
                                                                                    textOverflow="ellipsis"
                                                                                    style={{
                                                                                        paddingTop: textContent.length > 2 ? '20px' : '0px'
                                                                                    }}
                                                                                    display="flex"
                                                                                    flexWrap="nowrap"
                                                                                    className="category-content"
                                                                                >
                                                                                    {textContent.length < 500
                                                                                        ? textContent.slice(0, 500)
                                                                                        : textContent.slice(0, 630) + '....'}
                                                                                </Typography>
                                                                                <Typography
                                                                                    color="black"
                                                                                    style={{
                                                                                        paddingTop: '20px',
                                                                                        fontSize: '14px'
                                                                                    }}
                                                                                >
                                                                                    {moment(blog.published_at, 'DD-MM-YYYY').format(
                                                                                        'MM/DD/YYYY'
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </a>
                                                            </Grid>
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : (
                                            <Grid item xs={12} key={index}>
                                                <a
                                                    key={index}
                                                    href={`/knowledge-and-research/blog/${blog.title
                                                        .toLowerCase()
                                                        .replace(/\s+/g, '-')
                                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                                    target="_blank"
                                                >
                                                    <Box className="blog-item">
                                                        <CategoryCard
                                                            className="category-card"
                                                            backgroundImage={
                                                                blog.image_url !==
                                                                'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                    ? blog.image_url
                                                                    : // : imageUrl
                                                                    // ? imageUrl
                                                                    imageSrc
                                                                    ? imageSrc
                                                                    : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                            }
                                                            style={{
                                                                borderRadius: '8px',
                                                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                                transition: 'transform 0.3s ease'
                                                            }}
                                                        ></CategoryCard>
                                                        <CardTextBox>
                                                            <Typography
                                                                variant="h6"
                                                                color="black"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                style={{ marginTop: '10px' }}
                                                                display="flex"
                                                                flexWrap="nowrap"
                                                                className="category-title"
                                                            >
                                                                {blog.title.length < 35
                                                                    ? blog.title.charAt(0).toUpperCase() + blog.title.slice(1, 35)
                                                                    : blog.title.charAt(0).toUpperCase() + blog.title.slice(1, 35) + '....'}
                                                            </Typography>
                                                            <Typography
                                                                color="black"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                style={{ marginTop: '10px' }}
                                                                display="flex"
                                                                flexWrap="nowrap"
                                                                className="category-content"
                                                            >
                                                                {textContent.length < 120
                                                                    ? textContent.slice(0, 120)
                                                                    : textContent.slice(0, 120) + '....'}{' '}
                                                            </Typography>
                                                            <Typography color="gray" style={{ marginTop: '5px', fontSize: '12px' }}>
                                                                {moment(blog.published_at, 'DD-MM-YYYY').format('MM/DD/YYYY')}
                                                            </Typography>
                                                        </CardTextBox>
                                                    </Box>
                                                </a>
                                                {index !== blogs.length - 1 ? <hr /> : null}
                                            </Grid>
                                        )}
                                    </>
                                );
                            })}
                            {!isMobile && blogs.length >= 4 && (
                                <Carousel
                                    responsive={responsive}
                                    // autoPlay={true}
                                    swipeable={true}
                                    draggable={true}
                                    // showDots={true}
                                    infinite={true}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
                                    style={{ gap: '20px' }}
                                    className="carousel-gallery"
                                >
                                    {blogs.slice(1).map((blog, index) => {
                                        //    Parse the HTML content string
                                        const parser = new DOMParser();
                                        const parsedContent = parser.parseFromString(blog.content, 'text/html');
                                        // Remove all anchor tags from the parsed content
                                        parsedContent.querySelectorAll('a').forEach((a) => a.remove());
                                        // Get the text content from the parsed HTML
                                        const textContent = parsedContent.body.textContent;
                                        const imageUrl = extractImageUrl(blog.content);
                                        const imageSrc = extractBase64Image(blog.content);

                                        return (
                                            <>
                                                <a
                                                    key={index}
                                                    href={`/knowledge-and-research/blog/${blog.title
                                                        .toLowerCase()
                                                        .replace(/\s+/g, '-')
                                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                                    target="_blank"
                                                >
                                                    <Card className="card">
                                                        <CardMedia
                                                            component="img"
                                                            height="194"
                                                            image={
                                                                blog.image_url !==
                                                                'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                                    ? blog.image_url
                                                                    : // : imageUrl
                                                                    // ? imageUrl
                                                                    imageSrc
                                                                    ? imageSrc
                                                                    : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                                                            }
                                                            alt="Paella dish"
                                                            className="category-card"
                                                        />
                                                        <CardContent>
                                                            <Typography
                                                                variant="h6"
                                                                color="black"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                // whiteSpace="nowrap"
                                                                style={{ marginTop: '-25px' }}
                                                                display="flex"
                                                                flexWrap="nowrap"
                                                                className="category-title"
                                                            >
                                                                {blog.title.length < 35
                                                                    ? blog.title.charAt(0).toUpperCase() + blog.title.slice(1, 35)
                                                                    : blog.title.charAt(0).toUpperCase() + blog.title.slice(1, 45) + '....'}
                                                            </Typography>
                                                            <Typography
                                                                color="black"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                // whiteSpace="nowrap"
                                                                style={{ paddingTop: '5px', maxHeight: '130px' }}
                                                                display="flex"
                                                                flexWrap="nowrap"
                                                                className="category-content"
                                                            >
                                                                {textContent.length < 120
                                                                    ? textContent.slice(0, 120)
                                                                    : textContent.slice(0, 120) + '....'}
                                                            </Typography>
                                                            <Typography color="gray" style={{ marginTop: '5px', fontSize: '12px' }}>
                                                                {moment(blog.published_at, 'DD-MM-YYYY').format('MM/DD/YYYY')}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </a>
                                            </>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </>
                    ) : (
                        Object.keys(blogsByCategory).length === 0 && (
                            <NoDataFound msg="No articles found." style={{ padding: '20px', fontWeight: '700' }} />
                        )
                    )}
                </React.Fragment>
            )) : (
                <NoDataFound msg="No articles found." style={{ padding: '20px', fontWeight: '700' }} />
            )}
        </>
    );
}

export default BlogsTileView;
