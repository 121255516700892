import {
    AppBar,
    Box,
    Card,
    CardContent,
    Container,
    createStyles,
    CssBaseline,
    Grid,
    Paper,
    Tabs,
    Typography,
    useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import budgetPage from 'assets/jss/material-kit-react/views/budgetPage';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import portfolioPageStyle from 'assets/jss/material-kit-react/views/portfolioPage';
import studentCalPageStyle from 'assets/jss/material-kit-react/views/studentCalPage';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { getRecurringFiles, getRecurringCategory } from 'redux/actions/RecurringPaymentActions';
import PortfolioTabPanel from '../PortfolioValueUpload/PortfolioTabPanel';
import RecurringBreadcrumb from './recurringBreadcrumb';
import { RecurringSummary } from './RecurringSummary';
import { a11yProps } from 'utils/Utils';
import { MainBox } from 'assets/jss/GeneralStyle';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { getConnectedAccountDetail } from 'redux/actions/SettingActions';
import DoRegistration from 'views/DoRegistration';
import { TableSearchBox } from 'assets/jss/PortfolioStyle';
import Meta from 'components/meta';
import RecurringPaymentCalDesc from './RecurringPaymentCalDesc';
import RecurringPaymentTopBlock from './RecurringPaymentTopBlock';
import RecurringPaymentUpload from './RecurringPaymentCalUpload';
import RecurringPaymentAnalysisOutput from './RecurringPaymentAnalysisOutput';
import AddNewRecurringPayment from './AddNewRecurringPayment';

const tabs = [{ label: 'Your Recurring Payments' }, { label: 'Upload Data' }];

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...portfolioPageStyle(theme),
        ...styles(theme),
        ...budgetPage(theme),
        ...studentCalPageStyle(theme)
    })
);
export default function RecurringPaymentCalculator(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const recurringPayment = useSelector((state) => state.recurringPayment);
    const setting = useSelector((state) => state.setting);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedRecurringCount, setSelecteRecurringCount] = useState([]);
    const [selectedRecurring, setSelectedRecurring] = useState([]);
    const [renderInputList, setInputList] = useState(false);
    const [recurrInput, setRecurringInput] = useState([]);
    const classes = mergedStyle();
    const container = React.useRef(null);
    const fileList = [...recurringPayment.recurringFileList];
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const searchcontainer = useRef(null);
    const handleTabChange = (event, newValue) => typeof newValue !== 'undefined' && setSelectedTab(newValue);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'budAna';
        }
    }, []);

    useEffect(() => {
        dispatch(getConnectedAccountDetail());
        //fetch the list of uploaded files
        dispatch(getRecurringFiles());
        dispatch(getRecurringCategory());
    }, []);


    useEffect(() => {
        let data = recurringPayment.selectedRecurringDetail;
        if (Object.keys(data).length > 0) {
            setRecurringInput(data.input);
        }
    }, [recurringPayment.selectedRecurringDetail]);
    // const [filteredTableData, setFilteredData] = useState([]);
    // useEffect(() => {
    //     setRecurringInput(filteredTableData);
    // }, [filteredTableData]);

    // if (setting.profileDetail.allow_external_account) {
    //     setting.plaidDetail.user_detail.map((bank) => {
    //         fileList.push({ id: `${bank.id}**`, file_name: `Bank Data: ${bank.bank_name}`, is_bank: true });
    //     });
    // }

    const recurringTableData = recurrInput.map((data) => {
        if (data.id) {
            return data;
        }else{
            return { ...data };
        }
    });

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(index);
    };

    const render = () => {
        return (
            <>
                {recurringPayment.loader ? <Loader /> : ''}
                <RecurringPaymentTopBlock data={ recurringPayment.selectedRecurringDetail } />
                <Grid container>
                    <Grid item xs m={'auto'}>
                        <RecurringBreadcrumb
                            dispatch={dispatch}
                            classes={classes}
                            handleTabChange={handleTabChange}
                            list={fileList}
                            renderInputList={renderInputList}
                            resetInputList={() => setInputList(false)}
                            selectedRecurringCount={(count) => setSelecteRecurringCount(count)}
                            setSelectedTab={setSelectedTab}
                            setSelecteRecurringVal={(val) => setSelectedRecurring(val)}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <Box ref={container} />
                    </Grid>
                </Grid>
                <TopBlockBox>
                    <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('backward')}
                        >
                            {' '}
                            &lt;
                        </div>
                        <div
                            style={{
                                overflowX: 'auto',
                                display: 'flex'
                            }}
                            className="scroll"
                            onScroll={handleScroll}
                        >
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    style={{
                                        flex: '0 0 auto',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderBottom: '3px solid transparent',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                        color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                    }}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {tab.label}
                                </div>
                            ))}
                            <TableSearchBox m={'auto 0'}>
                                <Box width="150px" ref={searchcontainer} />
                            </TableSearchBox>
                            <AddNewRecurringPayment selectedRecurring={ selectedRecurring } recurringCategory={ recurringPayment.recurringCategory }>
                            </AddNewRecurringPayment>
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('forward')}
                        >
                            &gt;
                        </div>
                    </div>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={selectedTab}
                        onChangeIndex={(index) => {
                            setActiveTab(index);
                            setSelectedTab(index);
                        }}
                    >
                        <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                            <RecurringSummary
                                container={container}
                                dispatch={dispatch}
                                recurringPayment={recurringPayment}
                                setInputRenderList={(val) => setInputList(val)}
                                selectedRecurringCount={selectedRecurringCount}
                                data={recurringTableData}
                                classes={classes}
                                searchcontainer={searchcontainer}
                                recurringCategory={ recurringPayment.recurringCategory }
                            />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                            <Grid container className={classes.uploadWrapper} spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <RecurringPaymentUpload dispatch={dispatch} a11yProps={a11yProps}></RecurringPaymentUpload>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RecurringPaymentAnalysisOutput classes={classes} dispatch={dispatch} data={recurringPayment.recurringFileList} />
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'recurringPayment'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Regular Payment and Bank Tracking Tool
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Regular Payment and Bank Tracking Tool | TPE Hub"
                    description="Calculate and manage your recurring expenses with ease using our Regular Payment and Bank Tracking Tool. Enter your monthly expenses and get a clear picture of your ongoing financial commitments. Simplify budgeting and track your regular costs efficiently."
                    page="#"
                />
                <DoRegistration />
                <CommonCalculator variantText="Regular Payment and Bank Tracking Tool" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <RecurringPaymentCalDesc />{' '}
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}