import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Checkbox, Paper, Typography } from '@mui/material';
import { ComparisonPaper } from 'assets/jss/CalculatorStyle';
import { TickerList } from 'assets/jss/LandingStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import Button from 'components/CustomButtons/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCollegeData, SearchSchoolName } from 'redux/actions/collegeCalAction';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CollegeComparsion = (props) => {
    const collegeData = useSelector((state) => state.collegeCal);
    const [value, setValue] = useState([]);
    const [tickerList, setTickerList] = useState([]);
    const [comparisonDetail, setComparisonDetail] = useState([]);
    const [searchData, setSearchData] = React.useState([]);
    const dispatch = useDispatch();

    // handle the search button and convert selected ticker into table format
    const handleTickerSearch = (e) => {
        let combinedValue = [...value];
        const ids = combinedValue?.map((item) => item.label);
        dispatch(fetchCollegeData({ school_name: ids }));
        combinedValue = [];
    };

    const handleClearAll = () => {
        setComparisonDetail({});
        setValue([]);
    };

    const onEnterKeyPressed = (e) => {
        if (e.key === 'Enter' && e.target.value.trim().length > 0) {
            if (value.some((obj) => obj.label.trim() === e.target.value.trim())) {
                return toast.error('Duplicate values not allowed');
            } else {
                setValue((oldValue) => [...oldValue, { label: e.target.value.trim() }]);
            }
        }
    };

    useEffect(() => {
        if (collegeData.comparisonList) {
            setComparisonDetail({
                data: collegeData.comparisonList.data,
                column: collegeData.comparisonList.column
            });
            setSearchData(collegeData.comparisonList.data);
        }
    }, [collegeData.comparisonList]);

    useEffect(() => {
        if (collegeData?.SearchNames?.data) {
            const list = collegeData.SearchNames.data.map((val) => ({
                label: val
            }));
            setTickerList(list);
        }
    }, [collegeData?.SearchNames?.data]);

    return (
        <>
            <Paper elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} style={{ paddingTop: '0px', margin: '10px' }} id="school">
                        <Autocomplete
                            multiple
                            id="college_comp_list"
                            options={tickerList}
                            disableCloseOnSelect
                            // defaultValue={[]}
                            limitTags={3}
                            onKeyDown={(e) => onEnterKeyPressed(e)}
                            onFocus={() => {
                                if (!collegeData.SearchNames) dispatch(SearchSchoolName({ school_name: [''] }));
                            }}
                            onInputChange={(e) => {
                                if (e?.target?.value?.length > 1) {
                                    dispatch(SearchSchoolName({ school_name: [e.target.value] }));
                                }
                            }}
                            onChange={(event, newValue) => {
                                if (newValue.length <= 10) {
                                    setValue(newValue);
                                } else {
                                    return toast.error('Maximum selection of tickers reached');
                                }
                            }}
                            getOptionLabel={(option) => option?.label?.toString() || ''}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="School" placeholder="Select/Enter School(s)" />}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        display="flex"
                        justifyContent="center"
                        style={{ paddingTop: '0px', margin: 'auto auto auto 30px' }}
                    >
                        <Button
                            id="btn-1"
                            variant="contained"
                            size="sm"
                            color="info"
                            style={{ marginRight: '40px' }}
                            onClick={(e) => (value.length ? handleTickerSearch(e) : null)}
                        >
                            Comparison
                        </Button>
                        <Button
                            id="btn-2"
                            variant="contained"
                            size="sm"
                            color="secondary"
                            onClick={() => (value.length ? handleClearAll() : null)}
                        >
                            Reset Result
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {searchData.length > 0 && (
                <ComparisonPaper elevation={3}>
                    <Grid container>
                        <Grid item xs>
                            <Typography margin="10px" variant="body1">
                                Comparisons
                            </Typography>
                            <Box sx={{ height: 'fit-content', overflow: 'auto' }}>
                                <EnhancedTable
                                    enableSearch={false}
                                    enablePagination={false}
                                    columns={comparisonDetail?.column || []}
                                    data={comparisonDetail?.data || []}
                                    setData={(newData) => {
                                        setComparisonDetail((oldState) => ({ ...oldState, data: newData }));
                                    }}
                                    setColumns={(newColumns) => {
                                        setComparisonDetail((oldState) => ({ ...oldState, column: newColumns }));
                                    }}
                                    enableSort={false}
                                    hideToolBar={true}
                                    tablestyle={{ maxHeight: 'max-content' }}
                                    collegeComparision={true}
                                    columnDnd
                                    rowDnd
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </ComparisonPaper>
            )}
        </>
    );
};
export default CollegeComparsion;
