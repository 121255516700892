import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { submitMutualFundTicker } from 'redux/actions/MutualFundActions';
import { titleCase } from 'utils/Utils';
import { compareNumericString } from 'utils/Utils';
import { numberWithCommas } from 'utils/Utils';
import AddNewTicker from './AddNewTicker';
import { MutualFundViewPopup } from './MutualFundViewPopup';
import { StyledStatusChip } from 'assets/jss/GeneralStyle';
import { TableSearchBox } from 'assets/jss/PortfolioStyle';
import * as tooltip from 'Tooltip.json';
import { MutualFundSearchPaginationBox } from 'assets/jss/CalculatorStyle';
import { fetchMutualTickerScrapping, fetchMutualFundColumnFilter } from 'redux/actions/MutualFundActions';
import { getColumnName } from 'utils/getColumnName';
import { findDOMNode } from 'react-dom';

export const includes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id] || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue == filterValue;
        });
    });
};

const rowsPerPageOption = [5, 10, 25];

export const MutualFundTable = (props) => {
    const { mutualdata, dispatch, totalRecords } = props;
    const searchcontainer = useRef(null);
    const [viewData, setOpenViewDialog] = useState(false);
    const [fundData, setFundData] = useState({});
    const paginationContainer = useRef(null);
    const [columnTable, setTableColumn] = useState([]);
    const [page, setPage] = useState(0);
    const [pageCopy, setPageCopy] = useState(0);
    const [pageSize, setPageSize] = useState(rowsPerPageOption[0]);
    const [pageSizeCopy, setPageSizeCopy] = useState(5);
    const [filter, setFilter] = useState('');
    const [isDesc, setIsDesc] = useState(false);
    const [columnName, setColumnName] = useState('');
    const [clickedCount, setClickedCount] = useState(1);
    const [orderName, setOrderName] = useState('');
    const [isOwned, setIsOwned] = useState('all');
    const [inputValue, setInputValue] = useState({
        Ticker: '',
        'Fund Name': '',
        'Legal type': '',
        'Broad Asset type': ''
    });
    const [inputStyle, setInputStyle] = useState({
        border: 'none',
        borderBottom: '1px solid #000',
        padding: '5px',
        outline: 'none',
        width: '100%',
        maxWidth: '120px'
    });

    const handleInputChange = (name, event) => {
        setInputValue({ ...inputValue, [name]: event.target.value });
        const getData = setTimeout(() => {
            dispatch(fetchMutualTickerScrapping(page + 1, pageSize, event.target.value));
        }, 2000);

        return () => clearTimeout(getData);
    };

    useEffect(() => {
        fetchMutualFunds();
    }, [page, pageSize, filter]);

    const fetchMutualFunds = () => {
        const getData = setTimeout(() => {
            dispatch(fetchMutualTickerScrapping(page + 1, pageSize, filter));
        }, 2000);

        return () => clearTimeout(getData);
    };

    const handleChangePage = (e, n) => {
        setPage(n);
    };
    const handleChangeRowsPerPage = (e) => {
        setPageSize(Number(e.target.value));
    };

    const handleOwnStatus = (data, flag) => {
        dispatch(submitMutualFundTicker({ ownedFund: flag, id: data.mid }, 'put'));
    };
    const ownedFundIncludes = (rows, ids, filterValue) => {
        return rows.filter((row) => {
            return ids.some((id) => {
                const rowValue =
                    (id === 'owned fund' && row.values[id]?.toString().toLowerCase().trim() === 'true' && 'Owned') || 'Not owned';
                return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
            });
        });
    };
    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
        // Calculate the options for filtering
        // using the preFilteredRows

        // const options = useMemo(() => {
        //     const options = new Set();
        //     preFilteredRows.forEach((row) => {
        //         const customValForOwnedFund =
        //             (id === 'owned fund' && row.values[id]?.toString().toLowerCase().trim() === 'true' && 'Owned') || 'Not owned';
        //         options.add(customValForOwnedFund || row.values[id]?.toString().toLowerCase().trim() || '-');
        //     });
        //     let list = [...options.values()];
        //     list = list.map((option, i) => ({ value: option, label: titleCase(option) }));
        //     list.unshift({ value: 'all', label: 'All' });
        //     return list;
        // }, [id, preFilteredRows]);
        const options = [
            { value: 'all', label: 'All' },
            { value: 'false', label: 'Not owned' },
            { value: 'true', label: 'Owned' }
        ];
        // Render a multi-select box
        return (
            <CustomSelect
                disableEmptyValue={true}
                defaultValue={isOwned}
                formControlProps={{
                    fullWidth: true
                }}
                options={options}
                onChange={(e) => {
                    // setFilter(e.target.value || null);
                    // selectOwnedFund(e.target.value);
                    setIsOwned(e.target.value);
                }}
                selectOwnedFund={selectOwnedFund}
                mutualFundFilter={true}
                size="small"
            />
        );
    }
    useEffect(() => {
        // make column list for output table
        if (mutualdata.mutualFundData.length > 0) {
            const ignoreFields = ['mid', 'id'];
            const stickyFields = ['Ticker'];
            const StringFields = [
                'Ticker',
                'Fund Name',
                'owned fund',
                'Sector/Industry 1',
                'Sector/Industry 2',
                'Sector/Industry 3',
                'Sector/Industry 4',
                'Top holdings 1',
                'Top holdings 2',
                'Top holdings 3',
                'Top holdings 4',
                'Top holdings 5',
                'Top holdings 6',
                'Top holdings 7',
                'Top holdings 8',
                'Top holdings 9',
                'Top holdings 10',
                'Country Exposure 1',
                'Country Exposure 2',
                'Country Exposure 3',
                'Country Exposure 4',
                'Country Exposure 5',
                'Country Exposure 6',
                'Country Exposure 7',
                'Country Exposure 8',
                'Country Exposure 9',
                'Country Exposure 10'
            ];
            const additionalFields = ['owned fund'];
            const FieldColors = {
                '1 Year Returns': '#20c997',
                Ticker: '#ffc107',
                'Fund Name': '#ffc107',
                Price: '#ffc107',
                'Exp Ratio': '#dc3545',
                'BM 1 Year Returns': '#20c997',
                '3 Year Returns': '#20c997',
                'BM 3 Year Returns': '#20c997',
                'Sharpe Ratio': '#20c997'
            };
            const arr = ['Ticker', 'Fund Name', 'Legal type', 'Broad Asset type'];
            let formatChartData = [];
            Object.keys(mutualdata.mutualFundData[0]).forEach((val) => {
                if (!ignoreFields.includes(val)) {
                    let objToPass = {
                        Header: val,
                        accessor: val,
                        tooltip: tooltip.default?.[val] || undefined,
                        headerColor: FieldColors[val] || '#fd7e14',
                        sticky: stickyFields.includes(val),
                        disableFilters: !arr.includes(val),
                        Cell: ({ value, row }) => {
                            if (val === 'U.S. Government') {
                                return row.original[val] ? row.original[val] : '-';
                            }
                            if (val === 'owned fund') {
                                return (
                                    <StyledStatusChip
                                        // onClick={() => handleOwnStatus(row.original, !value)}
                                        label={value ? 'Owned' : 'Not Owned'}
                                        className={`${value ? 'success' : ''}`}
                                    />
                                );
                            }
                            return typeof value === 'boolean'
                                ? value
                                : (!isNaN(value) && value?.toString().indexOf('.') !== -1
                                      ? value && numberWithCommas(parseFloat(value)?.toFixed(2))
                                      : numberWithCommas(value)) ||
                                      value ||
                                      '-';
                        }
                    };
                    if (additionalFields.includes(val)) {
                        objToPass = {
                            ...objToPass,
                            disableFilters: false,
                            Filter: SelectColumnFilter,
                            filter: ownedFundIncludes
                        };
                    }
                    if (!StringFields.includes(val)) {
                        objToPass = { ...objToPass, sortType: compareNumericString };
                    }
                    formatChartData.push(objToPass);
                }
            });
            // to reorder the owned fund key in array table
            const eleIndex = formatChartData.indexOf(formatChartData.filter((item) => item.Header == 'owned fund')[0]);
            var element = formatChartData[eleIndex];
            formatChartData.splice(eleIndex, 1);
            formatChartData.splice(1, 0, element);
            formatChartData.unshift({
                Header: 'Action',
                width: 80,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="view">
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    setFundData(row.original);
                                    setOpenViewDialog(true);
                                }}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="delete"
                                onClick={() => dispatch(submitMutualFundTicker({ tickers: [row.original.mid] }, 'delete'))}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            });
            setTableColumn(formatChartData);
        }
    }, [mutualdata.mutualFundData]);

    const handleClose = () => {
        setFundData({});
        setOpenViewDialog(false);
    };

    useEffect(() => {
        if (page !== pageCopy || pageSize !== pageSizeCopy) {
            setClickedCount(1);
            setColumnName('');
            setIsDesc(false);
            setOrderName('');
            setPageCopy(page);
            setPageSizeCopy(pageSize);
        }
    }, [page, pageSize]);

    const mutualFundColumnFilter = (column) => {
        if (columnName && column !== columnName) {
            setColumnName(column);
            setClickedCount(2);
            const order = getColumnName(column);
            setOrderName(order);
            const getData = setTimeout(() => {
                dispatch(fetchMutualFundColumnFilter(page + 1, pageSize, order, false, isOwned));
            }, 2000);

            return () => clearTimeout(getData);
        } else {
            if (clickedCount % 3 === 0) {
                setIsDesc(false);
                setColumnName('');
                setClickedCount(clickedCount + 1);
                fetchMutualFunds();
            } else {
                setIsDesc(!isDesc);
                setColumnName(column);
                setClickedCount(clickedCount + 1);
                fetchColumnName(column);
            }
        }
    };

    const fetchColumnName = (col) => {
        const order = getColumnName(col);
        setOrderName(order);
        const getData = setTimeout(() => {
            dispatch(fetchMutualFundColumnFilter(page + 1, pageSize, order, isDesc, isOwned));
        }, 2000);

        return () => clearTimeout(getData);
    };

    const selectOwnedFund = (value) => {
        const getData = setTimeout(() => {
            dispatch(fetchMutualFundColumnFilter(page + 1, pageSize, orderName, isDesc, value));
        }, 2000);

        return () => clearTimeout(getData);
    };

    const tableRef = useRef(null);

    const handleColumnTitleClick = (title) => {
        // Wait for the DOM to update before scrolling
        setTimeout(() => {
            // Find the index of the column
            const columnIndex = columnTable.findIndex((col) => col.accessor === title);

            // Scroll to the column
            if (columnIndex !== -1) {
                const tableElement = tableRef.current.querySelector('table');
                const headerCell = tableElement.querySelector(`thead th:nth-child(${columnIndex + 1})`);
                if (headerCell) {
                    headerCell.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }, 0);
    };

    return (
        <>
            <MutualFundViewPopup
                onColumnTitleClick={handleColumnTitleClick}
                handleClose={handleClose}
                viewData={viewData}
                data={fundData}
            />
            <MutualFundSearchPaginationBox>
                <TableSearchBox m={'auto 0'}>
                    <Box ref={searchcontainer} />
                    {/* <TableSearch finalTableData={holdingsData} sendFilteredData={(val) => setFilteredData(val)} enableCellColor={true} /> */}
                </TableSearchBox>
                <Box ref={paginationContainer} style={{ display: 'flex', justifyContent: 'end' }} />
            </MutualFundSearchPaginationBox>
            <EnhancedTable
                ref={tableRef}
                enableSearch={true}
                mutualFundFilter={true}
                columnName={columnName}
                clickedCount={clickedCount}
                setFilter={setFilter}
                filter={filter}
                rowPageOption={rowsPerPageOption}
                searchcontainer={searchcontainer}
                container={paginationContainer}
                columns={columnTable}
                data={mutualdata.mutualFundData}
                enableSort={true}
                loader={mutualdata.loader}
                addDataDialogCompo={AddNewTicker}
                mutualFundColumnFilter={mutualFundColumnFilter}
                enablePagination={true}
                customPagination={true}
                customPaginationData={{
                    rowPageOption: rowsPerPageOption,
                    totalCount: totalRecords || 50,
                    pageSize: pageSize,
                    pageIndex: page,
                    handleChangePage: handleChangePage,
                    handleChangeRowsPerPage: handleChangeRowsPerPage
                }}
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                inputStyle={inputStyle}
            />
        </>
    );
};
