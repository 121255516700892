import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import { numberWithCommas } from "utils/Utils";
import { limitWords } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import WatchListChart from "./EconomicChart";
import { CustomButton } from "assets/jss/ButtonStyle";
import { Button } from "@mui/material";
import { fetchEconomicDashboardData } from "redux/actions/EconomicDashboardActions";
import EconomicChart from "./EconomicChart";
import { fetchEconomicHistoryChart } from "redux/actions/EconomicDashboardActions";

const EquityDashTable =(props)=> {
  const dispatch = useDispatch();
  const [resultData, setResult] = useState([]);
  const [enableChart, setEnableChart] = useState({});
  const [economicDetail, setEconomicDetail] = useState([]);

    const getEconomicDashboardData = () => {
    dispatch(fetchEconomicDashboardData());
  };

  const handleRangeSelection = (range) => {
    //   setEnableChart(range);
      dispatch(fetchEconomicHistoryChart(enableChart.ticker, range));
  };

  const handleSymbolClick = (data) => {
      setEnableChart(data);
      dispatch(fetchEconomicHistoryChart(data.ticker, '1y'));
  };

  useEffect(() => {
    getEconomicDashboardData();
  }, []);

  useEffect(() => {
      if (props.economicDashboardData.ecoDashboardDetail.length) {
          const resultData = props.economicDashboardData.ecoDashboardDetail;
          setResult(resultData);
      }
  }, [props.economicDashboardData]);


  function convertDateStringToTimestamp(dateString) {
    const date = new Date(dateString);
    return date.getTime();
}

  function compareNumericString(rowA, rowB, id, desc) {
    let a, b;
    if ((typeof rowA.values[id] == "string") && (typeof rowB.values[id] == "string")) {
        const timestampA = convertDateStringToTimestamp(rowA.values[id]);
        const timestampB = convertDateStringToTimestamp(rowB.values[id]);

        a =  Number.parseFloat(timestampA);
        b =  Number.parseFloat(timestampB);
    } else {
        a = Number.parseFloat(rowA.values[id]);
        b = Number.parseFloat(rowB.values[id]);
    }
    if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
  }

  const columns = React.useMemo(() => {
      return [
          {
            //   Header: 'Measure',
              Header: () => {
                return (
                  <div style={{marginLeft: "10px"}}>
                    Measure
                  </div>
                );
              },
              accessor: 'name',
              sticky: true,
              disableFilters: true,
              width: 120,
               Cell: ({ row, value }) => {
                const cellStyle = {
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: 120,
                    height: 50,
                    whiteSpace: 'pre-wrap', /* Prevent line breaks */
                    overflow: 'hidden',
                    wordWrap: 'break-word', /* Wrap long words onto the next line */
                    textOverflow: 'ellipsis'
                    
                  };
                    return (
                        <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)} style={cellStyle}>
                            {value}
                        </CustomButton>
                    );
                }
          },
          {
            Header: 'Latest',
            accessor: 'latest',
            Cell: ({ value, row, cell }) => 
              (value >= 1000 || value <= -1000) 
                  ? numberWithCommas(value.toFixed(0)) 
                  : numberWithCommas(value.toFixed(2)),
            sortType: compareNumericString,
            disableFilters: true,
            width: 70
        },
        {
          Header: 'Prior',
          accessor: 'prior',
          Cell: ({ value, row, cell }) => 
            (value >= 1000 || value <= -1000) 
                ? numberWithCommas(value.toFixed(0)) 
                : numberWithCommas(value.toFixed(2)),
          sortType: compareNumericString,
          disableFilters: true,
          width: 70
      },
          {
              Header: 'Absolute Change',
              accessor: 'absolute_change',
              sortType: compareNumericString,
              Cell: ({ value, row, cell }) => 
                (value >= 1000 || value <= -1000) 
                    ? numberWithCommas(value.toFixed(0)) 
                    : numberWithCommas(value.toFixed(2)),
              disableFilters: true,
              width: 70
          },
          {
              Header: 'Year Over Year Change',
              accessor: 'yoy_change',
              Cell: ({ value, row, cell }) => 
                (value >= 1000 || value <= -1000) 
                    ? numberWithCommas(value.toFixed(0)) 
                    : numberWithCommas(value.toFixed(2)),
              sortType: compareNumericString,
              disableFilters: true,
              width: 70
          },
          {
            Header: 'As of date',
            accessor: 'asofdate',
            sortType: compareNumericString,
            Cell: ({ value, row, cell }) => value,
            disableFilters: true,
            width: 70
        },
        {
            Header: 'Frequency',
            accessor: 'frequency',
            disableFilters: true,
            width: 70
        },
          {
              Header: 'Category',
              accessor: 'category',
              disableFilters: true,
              width: 70
          },
          {
              Header: 'Units',
              accessor: 'units',
              disableFilters: true,
              width: 70
          },

            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value, row, cell }) => {
                    return value ? (
                        limitWords(value, 7) !== value ? (
                            <CustomTooltip tooltipText={value} textTooltip={false} textLimit={7} align="left" />
                        ) : (
                            value
                        )
                    ) : (
                        '-'
                    );
                },
                disableFilters: true
            }

      ];
  }, [resultData]);

  return (
          // <Card elevation={2}>
          <>
          {Object.keys(enableChart).length > 0 ? (
              <EconomicChart
                  chartdetails={enableChart}
                  backButtonClicked={() => setEnableChart({})}
                  rangeSelectionClicked={(val) => handleRangeSelection(val)}
              />
          ) : (
            <EnhancedTable
                enableSearch={false}
                handleLoadData={getEconomicDashboardData}
                title={'Equities'}
                columns={columns}
                data={resultData}
                enableSort={true}
                enablePagination={false}
                loader={props.economicDashboardData.loader}
            />)}
           {/* </Card> */}
            </>
  );
}

export default EquityDashTable;
