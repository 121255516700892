import * as ActionTypes from "../actions/ActionTypes";


const initialState = {
  loanDashboardDetail: {},
  loanDashboardHistoricalData: {},
  loader: false
};


export const LoanDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LOAN_DASHBOARD_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_SUCCESS:
      return {
        ...state,
        loader: false,
        loanDashboardHistoricalData: action.data.data
      };
    case ActionTypes.FETCH_LOAN_DASHBOARD_SUCCESS:
      let data = [];
      action.data.data.data.map((val, i) => {
        return Object.keys(val).map((subval, subI) => {
          if (i === 0) {
            return data.push({ mort_label: subval, mort_Values: val[subval] });
          } else if (i === 1) {
            return data[subI] = {
              ...data[subI],
              consum_label: subval,
              consum_Values: val[subval]
            };
          }else{
            return data[subI] = {
              ...data[subI],
              car_label: subval,
              car_values: val[subval] + '%'
            }
          }
        });
      });
      if (action.simpleOutput) {
        data = action.data.data.data;
      }
      return {
        ...state,
        loader: false,
        loanDashboardDetail: { data,sync_date:action.data.data.sync_date }
      };
    case ActionTypes.FETCH_LOAN_DASHBOARD_ERROR:
      return { ...state, loader: false };
    case ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_ERROR:
      return { ...state, loader: false };

    default:
      return state;
  }
};
