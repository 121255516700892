import { Card, CardContent, Grid, IconButton, Tabs, Tooltip, Typography, useTheme } from '@mui/material';
import { ChartCardParent, ChartSelectionBox, SummaryAllocationBox, TopBlockBox } from 'assets/jss/PortfolioStyle';
import BarChart from './recurringBarChart';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { Fragment, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps, numberWithCommas } from 'utils/Utils';
import PortfolioTabPanel from '../PortfolioValueUpload/PortfolioTabPanel';

import CustomScroll from 'components/CustomScroll';

const tabs = [ { label: 'Rank of score of expenses' }, { label: 'Rank of Annual Expenses' }, { label: 'Top 5 Expenses Table' }, { label: 'Top 5 Annual Expenses where importance is less than 6' }];

export default function RecurringPaymentTopBlock(props) {
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState(0);
    const [topScoreData, setTopScoreData] = useState([]);
    const [annualExpenses, setAnnualExpenses] = useState([]);
    const [topAnnualExpense, setTopAnnualExpense] = useState({});
    const [expenseData, setExpenseData] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const { data } = props;


    useEffect(() => {
        //make format for pie , bar and tables
        if (Object.keys(data).length > 0) {
            if (Object.keys(data.top_5_annual_expenses).length > 0) {
                const parentCat = Object.keys(data.top_5_annual_expenses)[0];
                const individualChartData = [];
                Object.keys(data.top_5_annual_expenses[parentCat]).forEach((subVal) => {
                    individualChartData.push({
                        Header: subVal,
                        accessor: subVal,
                        disableFilters: true
                    });
                });
                Object.keys(data.top_5_annual_expenses).map((key) => {
                    data.top_5_annual_expenses[key].annual_expense = numberWithCommas(Number(data.top_5_annual_expenses[key].annual_expense)?.toFixed(2));
                });
                setTopAnnualExpense({ data: Object.values(data.top_5_annual_expenses), column: individualChartData });
            }
            if (Object.keys(data.top_5_annual_expenses_acc_importance).length > 0) {
                const parentCat = Object.keys(data.top_5_annual_expenses_acc_importance)[0];
                const individualChartData = [];
                Object.keys(data.top_5_annual_expenses_acc_importance[parentCat]).forEach((subVal) => {
                    individualChartData.push({
                        Header: subVal,
                        accessor: subVal,
                        disableFilters: true
                    });
                });
                Object.keys(data.top_5_annual_expenses_acc_importance).map((key) => {
                    data.top_5_annual_expenses_acc_importance[key].annual_expense = numberWithCommas(Number(data.top_5_annual_expenses_acc_importance[key].annual_expense)?.toFixed(2));
                });
                setTopScoreData({ data: Object.values(data.top_5_annual_expenses_acc_importance), column: individualChartData });
            }
            if (Object.keys(data.largest_annual_expenses).length > 0) {
                const formatChartData = Object.keys(data.largest_annual_expenses).map((key) => ({
                    label: data.largest_annual_expenses[key].name,
                    'Annual Expense': data.largest_annual_expenses[key].annual_expense
                }));
                setAnnualExpenses(formatChartData);
            }

            if (Object.keys(data.largest_expense_score).length > 0) {
                const formatTableData = Object.keys(data.largest_expense_score).map((key) => ({
                    label: data.largest_expense_score[key].Name,
                    'Score': data.largest_expense_score[key].Score
                }));
                setExpenseData(formatTableData);
            }
        }
    }, [data]);
    const renderBarChart = (rankdata) => {
        return rankdata.length > 0 ? (
            <Grid item xs={12} sm={12}>
                <ChartCardParent elevation={2}>
                    <BarChart
                        marginProp={{ top: 20, right: 20, bottom: 90, left: 50 }}
                        chartData={{
                            data: rankdata
                        }}
                    />
                </ChartCardParent>
            </Grid>
        ) : (
            <Grid item xs={12}>
                <ChartCardParent elevation={2} style={{ display: 'flex' }}>
                    <NoDataFound msg="Results will be shown after uploading." style={{ margin: 'auto' }} />
                </ChartCardParent>
            </Grid>
        );
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(index);
    };

    return (
        <TopBlockBox>
            <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} tooltipText ={'Rank of score of expenses'}/>
         
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={selectedTab}
                onChangeIndex={(index) => {
                    setActiveTab(index)
                    setSelectedTab(index)}}
            >
                <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                        {renderBarChart(expenseData)}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                    {renderBarChart(annualExpenses)}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={selectedTab} index={2} dir={theme.direction}>
                    {Object.keys(topAnnualExpense).length > 0 ? (
                        <EnhancedTable
                            enableSearch={false}
                            columns={topAnnualExpense.column}
                            data={topAnnualExpense.data}
                            enableSort={false}
                            hideToolBar={true}
                        />
                    ) : (
                        <Card>
                            <CardContent id="outputWrapper">
                                <NoDataFound msg="Results will be shown after uploading." />
                            </CardContent>
                        </Card>
                    )}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={selectedTab} index={3} dir={theme.direction}>
                    {Object.keys(topScoreData).length > 0 ? (
                        <EnhancedTable
                            enableSearch={false}
                            columns={topScoreData.column}
                            data={topScoreData.data}
                            enableSort={false}
                            hideToolBar={true}
                        />
                    ) : (
                        <Card>
                            <CardContent id="outputWrapper">
                                <NoDataFound msg="Results will be shown after uploading." />
                            </CardContent>
                        </Card>
                    )}
                </PortfolioTabPanel>
            </SwipeableViews>
        </TopBlockBox>
    );
}
