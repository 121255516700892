export const HOME_VALUATION_CAL_INIT = 'HOME_VALUATION_CAL_INIT';
export const HOME_VALUATION_CAL_SUCCESS = 'HOME_VALUATION_CAL_SUCCESS';
export const HOME_VALUATION_CAL_ERROR = 'HOME_VALUATION_CAL_ERROR';
export const FETCH_SAVED_DATA_INIT = 'FETCH_SAVED_DATA_INIT';
export const FETCH_SAVED_DATA_SUCCESS = 'FETCH_SAVED_DATA_SUCCESS';
export const FETCH_SAVED_DATA_ERROR = 'FETCH_SAVED_DATA_ERROR';
export const SUBMIT_HOME_PURCH_SUCCESS = 'SUBMIT_HOME_PURCH_SUCCESS';
export const SUBMIT_HOME_PURCH_ERROR = 'SUBMIT_HOME_PURCH_ERROR';
export const SUBMIT_HOME_PURCH_INIT = 'SUBMIT_HOME_PURCH_INIT';
export const SUBMIT_HOME_PURCH_SMART_TABLE_SUCCESS = 'SUBMIT_HOME_PURCH_SMART_TABLE_SUCCESS';
export const SUBMIT_HOME_PURCH_SMART_TABLE_ERROR = 'SUBMIT_HOME_PURCH_SMART_TABLE_ERROR';
export const SUBMIT_HOME_PURCH_SMART_TABLE_INIT = 'SUBMIT_HOME_PURCH_SMART_TABLE_INIT';
export const FETCH_HOME_PURCH_FILES_SUCCESS = 'FETCH_HOME_PURCH_FILES_SUCCESS';
export const FETCH_HOME_PURCH_FILES_ERROR = 'FETCH_HOME_PURCH_FILES_ERROR';
export const FETCH_HOME_PURCH_FILES_INIT = 'FETCH_HOME_PURCH_FILES_INIT';
export const FETCH_HOME_PURCH_SELECTED_SUCCESS = 'FETCH_HOME_PURCH_SELECTED_SUCCESS';
export const FETCH_HOME_PURCH_SELECTED_ERROR = 'FETCH_HOME_PURCH_SELECTED_ERROR';
export const FETCH_HOME_PURCH_SELECTED_INIT = 'FETCH_HOME_PURCH_SELECTED_INIT';
export const UPDATE_HOME_PURCH_SELECTED_SUCCESS = 'UPDATE_HOME_PURCH_SELECTED_SUCCESS';
export const UPDATE_HOME_PURCH_SELECTED_ERROR = 'UPDATE_HOME_PURCH_SELECTED_ERROR';
export const UPDATE_HOME_PURCH_SELECTED_INIT = 'UPDATE_HOME_PURCH_SELECTED_INIT';

export const OPEN_CHATBOT_POPUP = 'OPEN_CHATBOT_POPUP';
export const FETCH_INVEST_DASHBOARD_INIT = 'FETCH_INVEST_DASHBOARD_INIT';
export const FETCH_INVEST_DASHBOARD_SUCCESS = 'FETCH_INVEST_DASHBOARD_SUCCESS';
export const FETCH_INVEST_DASHBOARD_ERROR = 'FETCH_INVEST_DASHBOARD_ERROR';

export const FETCH_EQUITY_DASHBOARD_INIT = 'FETCH_EQUITY_DASHBOARD_INIT';
export const FETCH_EQUITY_DASHBOARD_SUCCESS = 'FETCH_EQUITY_DASHBOARD_SUCCESS';
export const FETCH_EQUITY_DASHBOARD_ERROR = 'FETCH_EQUITY_DASHBOARD_ERROR';

export const FETCH_ECONOMIC_DASHBOARD_INIT = 'FETCH_ECONOMIC_DASHBOARD_INIT';
export const FETCH_ECONOMIC_DASHBOARD_SUCCESS = 'FETCH_ECONOMIC_DASHBOARD_SUCCESS';
export const FETCH_ECONOMIC_DASHBOARD_ERROR = 'FETCH_ECONOMIC_DASHBOARD_ERROR';
export const UPDATE_ECONOMIC_DASHBOARD_INIT = 'UPDATE_ECONOMIC_DASHBOARD_INIT';
export const UPDATE_ECONOMIC_DASHBOARD_SUCCESS = 'UPDATE_ECONOMIC_DASHBOARD_SUCCESS';
export const UPDATE_ECONOMIC_DASHBOARD_ERROR = 'UPDATE_ECONOMIC_DASHBOARD_ERROR';
export const FETCH_ECO_HISTORY_INIT = 'FETCH_ECO_HISTORY_INIT';
export const FETCH_ECO_HISTORY_SUCCESS = 'FETCH_ECO_HISTORY_SUCCESS';
export const FETCH_ECO_HISTORY_ERROR = 'FETCH_ECO_HISTORY_ERROR';

export const FETCH_LOAN_DASHBOARD_INIT = 'FETCH_LOAN_DASHBOARD_INIT';
export const FETCH_LOAN_DASHBOARD_SUCCESS = 'FETCH_LOAN_DASHBOARD_SUCCESS';
export const FETCH_LOAN_DASHBOARD_ERROR = 'FETCH_LOAN_DASHBOARD_ERROR';

export const FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_INIT = 'FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_INIT';
export const FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_SUCCESS = 'FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_SUCCESS';
export const FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_ERROR = 'FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_ERROR';

export const OPTION_PRICING_CAL_INIT = 'OPTION_PRICING_CAL_INIT';
export const OPTION_PRICING_CAL_SUCCESS = 'OPTION_PRICING_CAL_SUCCESS';
export const OPTION_PRICING_CAL_ERROR = 'OPTION_PRICING_CAL_ERROR';

export const SUBMIT_LOGIN_INIT = 'SUBMIT_LOGIN_INIT';
export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_ERROR = 'SUBMIT_LOGIN_ERROR';
export const SUBMIT_REGISTER_INIT = 'SUBMIT_REGISTER_INIT';
export const SUBMIT_REGISTER_SUCCESS = 'SUBMIT_REGISTER_SUCCESS';
export const SUBMIT_REGISTER_ERROR = 'SUBMIT_REGISTER_ERROR';
export const HANDLE_LOGOUT_INIT = 'HANDLE_LOGOUT_INIT';
export const HANDLE_LOGOUT_SUCCESS = 'HANDLE_LOGOUT_SUCCESS';
export const HANDLE_LOGOUT_ERROR = 'HANDLE_LOGOUT_ERROR';
export const SUBMIT_FORGOT_RESET_PASS_SUCCESS = 'SUBMIT_FORGOT_RESET_PASS_SUCCESS';
export const SUBMIT_FORGOT_RESET_PASS_ERROR = 'SUBMIT_FORGOT_RESET_PASS_ERROR';
export const SUBMIT_FORGOT_RESET_PASS_INIT = 'SUBMIT_FORGOT_RESET_PASS_INIT';

export const SUBMIT_RETIRE_CAL_INIT = 'SUBMIT_RETIRE_CAL_INIT';
export const SUBMIT_RETIRE_CAL_SUCCESS = 'SUBMIT_RETIRE_CAL_SUCCESS';
export const SUBMIT_RETIRE_CAL_ERROR = 'SUBMIT_RETIRE_CAL_ERROR';
export const FETCH_SAVED_DATA__RETIRE_INIT = 'FETCH_SAVED_DATA__RETIRE_INIT';
export const FETCH_SAVED_DATA__RETIRE_SUCCESS = 'FETCH_SAVED_DATA__RETIRE_SUCCESS';
export const FETCH_SAVED_DATA__RETIRE_ERROR = 'FETCH_SAVED_DATA__RETIRE_ERROR';

export const SUBMIT_PORTFOLIO_FILES_INIT = 'SUBMIT_PORTFOLIO_FILES_INIT';
export const SUBMIT_PORTFOLIO_FILES_SUCCESS = 'SUBMIT_PORTFOLIO_FILES_SUCCESS';
export const SUBMIT_PORTFOLIO_FILES_ERROR = 'SUBMIT_PORTFOLIO_FILES_ERROR';
export const SUBMIT_PORT_ALLOCATION_INIT = 'SUBMIT_PORT_ALLOCATION_INIT';
export const SUBMIT_PORT_ALLOCATION_SUCCESS = 'SUBMIT_PORT_ALLOCATION_SUCCESS';
export const SUBMIT_PORT_ALLOCATION_ERROR = 'SUBMIT_PORT_ALLOCATION_ERROR';
export const FETCH_PORTFOLIO_LIST_INIT = 'FETCH_PORTFOLIO_LIST_INIT';
export const FETCH_PORTFOLIO_LIST_SUCCESS = 'FETCH_PORTFOLIO_LIST_SUCCESS';
export const FETCH_PORTFOLIO_LIST_ERROR = 'FETCH_PORTFOLIO_LIST_ERROR';
export const FETCH_PORTFOLIO_DETAILS_INIT = 'FETCH_PORTFOLIO_DETAILS_INIT';
export const FETCH_PORTFOLIO_DETAILS_SUCCESS = 'FETCH_PORTFOLIO_DETAILS_SUCCESS';
export const FETCH_PORTFOLIO_DETAILS_ERROR = 'FETCH_PORTFOLIO_DETAILS_ERROR';
export const DELETE_PORTFOLIO_INIT = 'DELETE_PORTFOLIO_INIT';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_ERROR = 'DELETE_PORTFOLIO_ERROR';
export const SMART_PORTFOLIO_INIT = 'SMART_PORTFOLIO_INIT';
export const SMART_PORTFOLIO_SUCCESS = 'SMART_PORTFOLIO_SUCCESS';
export const SMART_PORTFOLIO_ERROR = 'SMART_PORTFOLIO_ERROR';
export const FETCH_PORTFOLIO_CHART_SUCCESS = 'FETCH_PORTFOLIO_CHART_SUCCESS';
export const FETCH_PORTFOLIO_CHART_ERROR = 'FETCH_PORTFOLIO_CHART_ERROR';
export const FETCH_PORTFOLIO_CHART_INIT = 'FETCH_PORTFOLIO_CHART_INIT';
export const FETCH_PORTFOLIO_VALUATION_DATA_INIT = 'FETCH_PORTFOLIO_VALUATION_DATA_INIT';
export const FETCH_PORTFOLIO_VALUATION_DATA_SUCCESS = 'FETCH_PORTFOLIO_VALUATION_DATA_SUCCESS';
export const FETCH_PORTFOLIO_VALUATION_DATA_ERROR = 'FETCH_PORTFOLIO_VALUTION_DATA_ERROR';

export const SEND_CHAT_BOT_MSG_INIT = 'SEND_CHAT_BOT_MSG_INIT';
export const SEND_CHAT_BOT_MSG_SUCCESS = 'SEND_CHAT_BOT_MSG_SUCCESS';
export const SEND_CHAT_BOT_MSG_ERROR = 'SEND_CHAT_BOT_MSG_ERROR';

export const FETCH_CHAT_HISTORY_INIT = 'FETCH_CHAT_HISTORY_INIT';
export const FETCH_CHAT_HISTORY_SUCCESS = 'FETCH_CHAT_HISTORY_SUCCESS';
export const FETCH_CHAT_HISTORY_ERROR = 'FETCH_CHAT_HISTORY_ERROR';

export const CONVERT_CURRENCY_INIT = 'CONVERT_CURRENCY_INIT';
export const CONVERT_CURRENCY_SUCCESS = 'CONVERT_CURRENCY_SUCCESS';
export const CONVERT_CURRENCY_ERROR = 'CONVERT_CURRENCY_ERROR';

export const SUBMIT_STUD_LOAN_INIT = 'SUBMIT_STUD_LOAN_INIT';
export const SUBMIT_STUD_LOAN_SUCCESS = 'SUBMIT_STUD_LOAN_SUCCESS';
export const SUBMIT_STUD_LOAN_ERROR = 'SUBMIT_STUD_LOAN_ERROR';
export const GET_STUD_LOAN_SAVED_SUCCESS = 'GET_STUD_LOAN_SAVED_SUCCESS';
export const GET_STUD_LOAN_SAVED_ERROR = 'GET_STUD_LOAN_SAVED_ERROR';
export const GET_STUD_LOAN_SAVED_INIT = 'GET_STUD_LOAN_SAVED_INIT';

export const FETCH_TODOS_INIT = 'FETCH_TODOS_INIT';
export const FETCH_TODOS_SUCCESS = 'FETCH_TODOS_SUCCESS';
export const FETCH_TODOS_ERROR = 'FETCH_TODOS_ERROR';
export const FETCH_TODO_CATEGORY_INIT = 'FETCH_TODO_CATEGORY_INIT';
export const FETCH_TODO_CATEGORY_SUCCESS = 'FETCH_TODO_CATEGORY_SUCCESS';
export const FETCH_TODO_CATEGORY_ERROR = 'FETCH_TODO_CATEGORY_ERROR';
export const UPDATE_TODO_INIT = 'UPDATE_TODO_INIT';
export const UPDATE_TODO_SUCCESS = 'UPDATE_TODO_SUCCESS';
export const UPDATE_TODO_ERROR = 'UPDATE_TODO_ERROR';
export const SUBMIT_TODO_INIT = 'SUBMIT_TODO_INIT';
export const SUBMIT_TODO_SUCCESS = 'SUBMIT_TODO_SUCCESS';
export const SUBMIT_TODO_ERROR = 'SUBMIT_TODO_ERROR';
export const DELETE_TODO_INIT = 'DELETE_TODO_INIT';
export const DELETE_TODO_SUCCESS = 'DELETE_TODO_SUCCESS';
export const DELETE_TODO_ERROR = 'DELETE_TODO_ERROR';
export const SEARCH_TODO_INIT = 'SEARCH_TODO_INIT';
export const SEARCH_TODO_SUCCESS = 'SEARCH_TODO_SUCCESS';
export const SEARCH_TODO_ERROR = 'SEARCH_TODO_ERROR';
export const TODO_LIFE_GRAPH_INIT = 'TODO_LIFE_GRAPH_INIT';
export const TODO_LIFE_GRAPH_SUCCESS = 'TODO_LIFE_GRAPH_SUCCESS';
export const TODO_LIFE_GRAPH_ERROR = 'TODO_LIFE_GRAPH_ERROR';
export const TRIGGER_TODO_GIF = 'TRIGGER_TODO_GIF';
export const TODO_PERIODICALLY_GRAPH_INIT = 'TODO_PERIODICALLY_GRAPH_INIT';
export const TODO_PERIODICALLY_GRAPH_SUCCESS = 'TODO_PERIODICALLY_GRAPH_SUCCESS';
export const TODO_PERIODICALLY_GRAPH_ERROR = 'TODO_PERIODICALLY_GRAPH_ERROR';

export const SUBMIT_INFLATION_CAL_INIT = 'SUBMIT_INFLATION_CAL_INIT';
export const SUBMIT_INFLATION_CAL_SUCCESS = 'SUBMIT_INFLATION_CAL_SUCCESS';
export const SUBMIT_INFLATION_CAL_ERROR = 'SUBMIT_INFLATION_CAL_ERROR';

export const SUBMIT_TIPS_CAL_INIT = 'SUBMIT_TIPS_CAL_INIT';
export const SUBMIT_TIPS_CAL_SUCCESS = 'SUBMIT_TIPS_CAL_SUCCESS';
export const SUBMIT_TIPS_CAL_ERROR = 'SUBMIT_TIPS_CAL_ERROR';

export const SUBMIT_COMPOUND_CAL_INIT = 'SUBMIT_COMPOUND_CAL_INIT';
export const SUBMIT_COMPOUND_CAL_SUCCESS = 'SUBMIT_COMPOUND_CAL_SUCCESS';
export const SUBMIT_COMPOUND_CAL_ERROR = 'SUBMIT_COMPOUND_CAL_ERROR';

export const SUBMIT_PROPERTY_INVEST_INIT = 'SUBMIT_PROPERTY_INVEST_INIT';
export const SUBMIT_PROPERTY_INVEST_SUCCESS = 'SUBMIT_PROPERTY_INVEST_SUCCESS';
export const SUBMIT_PROPERTY_INVEST_ERROR = 'SUBMIT_PROPERTY_INVEST_ERROR';
export const SUBMIT_LIFE_INS_INIT = 'SUBMIT_LIFE_INS_INIT';
export const SUBMIT_LIFE_INS_SUCCESS = 'SUBMIT_LIFE_INS_SUCCESS';
export const SUBMIT_LIFE_INS_ERROR = 'SUBMIT_LIFE_INS_ERROR';

export const SUBSCRIBE_PUSH_NOTI_INIT = 'SUBSCRIBE_PUSH_NOTI_INIT';
export const SUBSCRIBE_PUSH_NOTI_SUCCESS = 'SUBSCRIBE_PUSH_NOTI_SUCCESS';
export const SUBSCRIBE_PUSH_NOTI_ERROR = 'SUBSCRIBE_PUSH_NOTI_ERROR';

export const SUBMIT_BUDGET_CAL_INIT = 'SUBMIT_BUDGET_CAL_INIT';
export const SUBMIT_BUDGET_CAL_SUCCESS = 'SUBMIT_BUDGET_CAL_SUCCESS';
export const SUBMIT_BUDGET_CAL_ERROR = 'SUBMIT_BUDGET_CAL_ERROR';
export const UPLOAD_BUDGET_FILE_SUCCESS = 'UPLOAD_BUDGET_FILE_SUCCESS';
export const UPLOAD_BUDGET_FILE_ERROR = 'UPLOAD_BUDGET_FILE_ERROR';
export const UPLOAD_BUDGET_FILE_INIT = 'UPLOAD_BUDGET_FILE_INIT';
export const GET_BUDGET_FILE_SUCCESS = 'GET_BUDGET_FILE_SUCCESS';
export const GET_BUDGET_FILE_ERROR = 'GET_BUDGET_FILE_ERROR';
export const GET_BUDGET_FILE_INIT = 'GET_BUDGET_FILE_INIT';
export const DELETE_BUDGET_FILE_SUCCESS = 'DELETE_BUDGET_FILE_SUCCESS';
export const DELETE_BUDGET_FILE_ERROR = 'DELETE_BUDGET_FILE_ERROR';
export const DELETE_BUDGET_FILE_INIT = 'DELETE_BUDGET_FILE_INIT';
export const FETCH_SELECTED_BUDGET_FILE_SUCCESS = 'FETCH_SELECTED_BUDGET_FILE_SUCCESS';
export const FETCH_SELECTED_BUDGET_FILE_ERROR = 'FETCH_SELECTED_BUDGET_FILE_ERROR';
export const FETCH_SELECTED_BUDGET_FILE_INIT = 'FETCH_SELECTED_BUDGET_FILE_INIT';
export const SMART_BUDGET_SUCCESS = 'SMART_BUDGET_SUCCESS';
export const SMART_BUDGET_ERROR = 'SMART_BUDGET_ERROR';
export const SMART_BUDGET_INIT = 'SMART_BUDGET_INIT';
export const FETCH_SAVED_BUDGET_SUCCESS = 'FETCH_SAVED_BUDGET_SUCCESS';
export const FETCH_SAVED_BUDGET_ERROR = 'FETCH_SAVED_BUDGET_ERROR';
export const FETCH_SAVED_BUDGET_INIT = 'FETCH_SAVED_BUDGET_INIT';
export const DELETE_SAVED_BUDGET_SUCCESS = 'DELETE_SAVED_BUDGET_SUCCESS';
export const DELETE_SAVED_BUDGET_ERROR = 'DELETE_SAVED_BUDGET_ERROR';
export const DELETE_SAVED_BUDGET_INIT = 'DELETE_SAVED_BUDGET_INIT';
export const GET_PLAID_BANK_DETAILS_INIT = 'GET_PLAID_BANK_DETAILS_INIT';
export const GET_PLAID_BANK_DETAILS_SUCCESS = 'GET_PLAID_BANK_DETAILS_SUCCESS';
export const GET_PLAID_BANK_DETAILS_ERROR = 'GET_PLAID_BANK_DETAILS_ERROR';

export const FETCH_ARCHIVED_BUDGET_INIT = 'FETCH_ARCHIVED_BUDGET_INIT';
export const FETCH_ARCHIVED_BUDGET_SUCCESS = 'FETCH_ARCHIVED_BUDGET_SUCCESS';
export const FETCH_ARCHIVED_BUDGET_ERROR = 'FETCH_ARCHIVED_BUDGET_ERROR';

export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const FETCH_PROFILE_INIT = 'FETCH_PROFILE_INIT';
export const SUBMIT_PROFILE_SUCCESS = 'SUBMIT_PROFILE_SUCCESS';
export const SUBMIT_PROFILE_ERROR = 'SUBMIT_PROFILE_ERROR';
export const SUBMIT_PROFILE_INIT = 'SUBMIT_PROFILE_INIT';

export const SUBMIT_PROFILE_SETTINGS_SUCCESS = 'SUBMIT_PROFILE_SETTINGS_SUCCESS';
export const SUBMIT_PROFILE_SETTINGS_ERROR = 'SUBMIT_PROFILE_SETTINGS_ERROR';
export const SUBMIT_PROFILE_SETTINGS_INIT = 'SUBMIT_PROFILE_SETTINGS_INIT';

export const SUBMIT_TRAFFIC_LIGHT_SUCCESS = 'SUBMIT_TRAFFIC_LIGHT_SUCCESS';
export const SUBMIT_TRAFFIC_LIGHT_ERROR = 'SUBMIT_TRAFFIC_LIGHT_ERROR';
export const SUBMIT_TRAFFIC_LIGHT_INIT = 'SUBMIT_TRAFFIC_LIGHT_INIT';
export const FETCH_TRAFFIC_LIGHT_SUCCESS = 'FETCH_TRAFFIC_LIGHT_SUCCESS';
export const FETCH_TRAFFIC_LIGHT_ERROR = 'FETCH_TRAFFIC_LIGHT_ERROR';
export const FETCH_TRAFFIC_LIGHT_INIT = 'FETCH_TRAFFIC_LIGHT_INIT';
export const HANDLE_DIALOG_VISIBLITY = 'HANDLE_DIALOG_VISIBLITY';
export const SUBMIT_TPE_MODULE_SUCCESS = 'SUBMIT_TPE_MODULE_SUCCESS';
export const SUBMIT_TPE_MODULE_ERROR = 'SUBMIT_TPE_MODULE_ERROR';
export const SUBMIT_TPE_MODULE_INIT = 'SUBMIT_TPE_MODULE_INIT';
export const FETCH_TPE_MODULE_SUCCESS = 'FETCH_TPE_MODULE_SUCCESS';
export const FETCH_TPE_MODULE_ERROR = 'FETCH_TPE_MODULE_ERROR';
export const FETCH_TPE_MODULE_INIT = 'FETCH_TPE_MODULE_INIT';
export const SUBMIT_SUBSCRIPTION_SUCCESS = 'SUBMIT_SUBSCRIPTION_SUCCESS';
export const SUBMIT_SUBSCRIPTION_ERROR = 'SUBMIT_SUBSCRIPTION_ERROR';
export const SUBMIT_SUBSCRIPTION_INIT = 'SUBMIT_SUBSCRIPTION_INIT';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';
export const FETCH_SUBSCRIPTION_INIT = 'FETCH_SUBSCRIPTION_INIT';
export const SUBMIT_EQUITY_TICKER_SUCCESS = 'SUBMIT_EQUITY_TICKER_SUCCESS';
export const SUBMIT_EQUITY_TICKER_ERROR = 'SUBMIT_EQUITY_TICKER_ERROR';
export const SUBMIT_EQUITY_TICKER_INIT = 'SUBMIT_EQUITY_TICKER_INIT';
export const FETCH_EQUITY_TICKER_SUCCESS = 'FETCH_EQUITY_TICKER_SUCCESS';
export const FETCH_EQUITY_TICKER_ERROR = 'FETCH_EQUITY_TICKER_ERROR';
export const FETCH_EQUITY_TICKER_INIT = 'FETCH_EQUITY_TICKER_INIT';
export const SUBMIT_ECONOMIC_TICKER_SUCCESS = 'SUBMIT_ECONOMIC_TICKER_SUCCESS';
export const SUBMIT_ECONOMIC_TICKER_ERROR = 'SUBMIT_ECONOMIC_TICKER_ERROR';
export const SUBMIT_ECONOMIC_TICKER_INIT = 'SUBMIT_ECONOMIC_TICKER_INIT';
export const SUBMIT_PORTFOLIO_ALLOCATION_SUCCESS = 'SUBMIT_PORTFOLIO_ALLOCATION_SUCCESS';
export const SUBMIT_PORTFOLIO_ALLOCATION_ERROR = 'SUBMIT_PORTFOLIO_ALLOCATION_ERROR';
export const SUBMIT_PORTFOLIO_ALLOCATION_INIT = 'SUBMIT_PORTFOLIO_ALLOCATION_INIT';
export const FETCH_ECONOMIC_TICKER_SUCCESS = 'FETCH_ECONOMIC_TICKER_SUCCESS';
export const FETCH_ECONOMIC_TICKER_ERROR = 'FETCH_ECONOMIC_TICKER_ERROR';
export const FETCH_ECONOMIC_TICKER_INIT = 'FETCH_ECONOMIC_TICKER_INIT';
export const FETCH_PORTFOLIO_ALLOCATION_SUCCESS = 'FETCH_PORTFOLIO_ALLOCATION_SUCCESS';
export const FETCH_PORTFOLIO_ALLOCATION_ERROR = 'FETCH_PORTFOLIO_ALLOCATION_ERROR';
export const FETCH_PORTFOLIO_ALLOCATION_INIT = 'FETCH_PORTFOLIO_ALLOCATION_INIT';
export const LOAD_SUB_SETTING_PAGE = 'LOAD_SUB_SETTING_PAGE';
export const FETCH_INVOICE_HISTORY_SUCCESS = 'FETCH_INVOICE_HISTORY_SUCCESS';
export const FETCH_INVOICE_HISTORY_ERROR = 'FETCH_INVOICE_HISTORY_ERROR';
export const FETCH_INVOICE_HISTORY_INIT = 'FETCH_INVOICE_HISTORY_INIT';
export const FETCH_BILLING_DETAILS_SUCCESS = 'FETCH_BILLING_DETAILS_SUCCESS';
export const FETCH_BILLING_DETAILS_ERROR = 'FETCH_BILLING_DETAILS_ERROR';
export const FETCH_BILLING_DETAILS_INIT = 'FETCH_BILLING_DETAILS_INIT';
export const SUBMIT_BILLING_UPDATE_SUCCESS = 'SUBMIT_BILLING_UPDATE_SUCCESS';
export const SUBMIT_BILLING_UPDATE_ERROR = 'SUBMIT_BILLING_UPDATE_ERROR';
export const SUBMIT_BILLING_UPDATE_INIT = 'SUBMIT_BILLING_UPDATE_INIT';

export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_ERROR = 'SUBMIT_FEEDBACK_ERROR';
export const SUBMIT_FEEDBACK_INIT = 'SUBMIT_FEEDBACK_INIT';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_ERROR = 'FETCH_FEEDBACK_ERROR';
export const FETCH_FEEDBACK_INIT = 'FETCH_FEEDBACK_INIT';
export const SUBMIT_CONTACTUS_SUCCESS = 'SUBMIT_CONTACTUS_SUCCESS';
export const SUBMIT_CONTACTUS_ERROR = 'SUBMIT_CONTACTUS_ERROR';
export const SUBMIT_CONTACTUS_INIT = 'SUBMIT_CONTACTUS_INIT';
export const SUBMIT_DEFAULT_OVERRIDE_SUCCESS = 'SUBMIT_DEFAULT_OVERRIDE_SUCCESS';
export const SUBMIT_DEFAULT_OVERRIDE_ERROR = 'SUBMIT_DEFAULT_OVERRIDE_ERROR';
export const SUBMIT_DEFAULT_OVERRIDE_INIT = 'SUBMIT_DEFAULT_OVERRIDE_INIT';
export const GET_DEFAULT_OVERRIDE_SUCCESS = 'GET_DEFAULT_OVERRIDE_SUCCESS';
export const GET_DEFAULT_OVERRIDE_ERROR = 'GET_DEFAULT_OVERRIDE_ERROR';
export const GET_DEFAULT_OVERRIDE_INIT = 'GET_DEFAULT_OVERRIDE_INIT';
export const SUBMIT_ANNUITY_CAL_SUCCESS = 'SUBMIT_ANNUITY_CAL_SUCCESS';
export const SUBMIT_ANNUITY_CAL_ERROR = 'SUBMIT_ANNUITY_CAL_ERROR';
export const SUBMIT_ANNUITY_CAL_INIT = 'SUBMIT_ANNUITY_CAL_INIT';

export const SUBMIT_CREDIT_CARD_CAL_INIT = 'SUBMIT_CREDIT_CARD_CAL_INIT';
export const SUBMIT_CREDIT_CARD_CAL_SUCCESS = 'SUBMIT_CREDIT_CARD_CAL_SUCCESS';
export const SUBMIT_CREDIT_CARD_CAL_ERROR = 'SUBMIT_CREDIT_CARD_CAL_ERROR';
export const FETCH_CREDIT_CARD_CAL_SUCCESS = 'FETCH_CREDIT_CARD_CAL_SUCCESS';
export const FETCH_CREDIT_CARD_CAL_ERROR = 'FETCH_CREDIT_CARD_CAL_ERROR';
export const FETCH_CREDIT_CARD_CAL_INIT = 'FETCH_CREDIT_CARD_CAL_INIT';
export const SUBMIT_CREDIT_CARD_DATA = 'SUBMIT_CREDIT_CARD_DATA';

export const SUBMIT_DEBT_CAL_INIT = 'SUBMIT_DEBT_CAL_INIT';
export const SUBMIT_DEBT_CAL_SUCCESS = 'SUBMIT_DEBT_CAL_SUCCESS';
export const SUBMIT_DEBT_CAL_ERROR = 'SUBMIT_DEBT_CAL_ERROR';
export const FETCH_DEBT_CAL_SUCCESS = 'FETCH_DEBT_CAL_SUCCESS';
export const FETCH_DEBT_CAL_ERROR = 'FETCH_DEBT_CAL_ERROR';
export const FETCH_DEBT_CAL_INIT = 'FETCH_DEBT_CAL_INIT';
export const RESET_EDIT_TABLE_FIELD = 'RESET_EDIT_TABLE_FIELD';
export const SIGNIN_REQUIRED_ACTION = 'SIGNIN_REQUIRED_ACTION';

export const VIEW_DEBT_SUCCESS = 'VIEW_DEBT_SUCCESS';
export const VIEW_DEBT_ERROR = 'VIEW_DEBT_ERROR';
export const VIEW_DEBT_INIT = 'VIEW_DEBT_INIT';

export const DELETE_RESEARCH_TABLE_INIT = 'DELETE_RESEARCH_TABLE_INIT';
export const DELETE_RESEARCH_TABLE_SUCCESS = 'DELETE_RESEARCH_TABLE_SUCCESS';
export const DELETE_RESEARCH_TABLE_ERROR = 'DELETE_RESEARCH_TABLE_ERROR';

export const SUBMIT_MUTUAL_TICKER_INIT = 'SUBMIT_MUTUAL_TICKER_INIT';
export const SUBMIT_MUTUAL_TICKER_SUCCESS = 'SUBMIT_MUTUAL_TICKER_SUCCESS';
export const SUBMIT_MUTUAL_TICKER_ERROR = 'SUBMIT_MUTUAL_TICKER_ERROR';
export const FETCH_MUTUAL_TICKER_SUCCESS = 'FETCH_MUTUAL_TICKER_SUCCESS';
export const FETCH_MUTUAL_TICKER_ERROR = 'FETCH_MUTUAL_TICKER_ERROR';
export const FETCH_MUTUAL_TICKER_INIT = 'FETCH_MUTUAL_TICKER_INIT';
export const FETCH_ALL_TICKERS_SUCCESS = 'FETCH_ALL_TICKERS_SUCCESS';
export const FETCH_ALL_TICKERS_ERROR = 'FETCH_ALL_TICKERS_ERROR';
export const FETCH_ALL_TICKERS_INIT = 'FETCH_ALL_TICKERS_INIT';
export const GET_SELECTED_MFTICKER_SUCCESS = 'GET_SELECTED_MFTICKER_SUCCESS';
export const GET_SELECTED_MFTICKER_ERROR = 'GET_SELECTED_MFTICKER_ERROR';
export const GET_SELECTED_MFTICKER_INIT = 'GET_SELECTED_MFTICKER_INIT';
export const RESET_CLOSE_POPUP = 'RESET_CLOSE_POPUP';

export const SUBMIT_TAX_ESTI_SUCCESS = 'SUBMIT_TAX_ESTI_SUCCESS';
export const SUBMIT_TAX_ESTI_ERROR = 'SUBMIT_TAX_ESTI_ERROR';
export const SUBMIT_TAX_ESTI_INIT = 'SUBMIT_TAX_ESTI_INIT';

export const FETCH_WIDGET_CAL_LIST_SUCCESS = 'FETCH_WIDGET_CAL_LIST_SUCCESS';
export const FETCH_WIDGET_CAL_LIST_ERROR = 'FETCH_WIDGET_CAL_LIST_ERROR';
export const FETCH_WIDGET_CAL_LIST_INIT = 'FETCH_WIDGET_CAL_LIST_INIT';
export const FETCH_WIDGET_USER_CAL_LIST_SUCCESS = 'FETCH_WIDGET_USER_CAL_LIST_SUCCESS';
export const FETCH_WIDGET_USER_CAL_LIST_ERROR = 'FETCH_WIDGET_USER_CAL_LIST_ERROR';
export const FETCH_WIDGET_USER_CAL_LIST_INIT = 'FETCH_WIDGET_USER_CAL_LIST_INIT';
export const SUBMIT_WIDGET_CAL_SUCCESS = 'SUBMIT_WIDGET_CAL_SUCCESS';
export const SUBMIT_WIDGET_CAL_ERROR = 'SUBMIT_WIDGET_CAL_ERROR';
export const SUBMIT_WIDGET_CAL_INIT = 'SUBMIT_WIDGET_CAL_INIT';
export const SUBMIT_WIDGET_USER_CAL_SUCCESS = 'SUBMIT_WIDGET_USER_CAL_SUCCESS';
export const SUBMIT_WIDGET_USER_CAL_ERROR = 'SUBMIT_WIDGET_USER_CAL_ERROR';
export const SUBMIT_WIDGET_USER_CAL_INIT = 'SUBMIT_WIDGET_USER_CAL_INIT';
export const SUBMIT_WIDGET_DIALOG_SUCCESS = 'SUBMIT_WIDGET_DIALOG_SUCCESS';

export const FETCH_PEERTO_PEER_COLL_SUCCESS = 'FETCH_PEERTO_PEER_COLL_SUCCESS';
export const FETCH_PEERTO_PEER_COLL_ERROR = 'FETCH_PEERTO_PEER_COLL_ERROR';
export const FETCH_PEERTO_PEER_COLL_INIT = 'FETCH_PEERTO_PEER_COLL_INIT';
export const SUBMIT_PEERTO_PEER_SUCCESS = 'SUBMIT_PEERTO_PEER_SUCCESS';
export const SUBMIT_PEERTO_PEER_ERROR = 'SUBMIT_PEERTO_PEER_ERROR';
export const SUBMIT_PEERTO_PEER_INIT = 'SUBMIT_PEERTO_PEER_INIT';
export const SUBMIT_PEERTO_PEER_CAL_SUCCESS = 'SUBMIT_PEERTO_PEER_CAL_SUCCESS';
export const SUBMIT_PEERTO_PEER_CAL_ERROR = 'SUBMIT_PEERTO_PEER_CAL_ERROR';
export const SUBMIT_PEERTO_PEER_CAL_INIT = 'SUBMIT_PEERTO_PEER_CAL_INIT';

export const SUBMIT_NEWSLETTER_SUCCESS = 'SUBMIT_NEWSLETTER_SUCCESS';
export const SUBMIT_NEWSLETTER_ERROR = 'SUBMIT_NEWSLETTER_ERROR';
export const SUBMIT_NEWSLETTER_INIT = 'SUBMIT_NEWSLETTER_INIT';

export const GENERATE_PLAID_TOKEN_SUCCESS = 'GENERATE_PLAID_TOKEN_SUCCESS';
export const GENERATE_PLAID_TOKEN_ERROR = 'GENERATE_PLAID_TOKEN_ERROR';
export const GENERATE_PLAID_TOKEN_INIT = 'GENERATE_PLAID_TOKEN_INIT';
export const CONNECT_PLAID_SUCCESS = 'CONNECT_PLAID_SUCCESS';
export const CONNECT_PLAID_ERROR = 'CONNECT_PLAID_ERROR';
export const CONNECT_PLAID_INIT = 'CONNECT_PLAID_INIT';
export const GET_CONNECTED_ACC_DETAIL_SUCCESS = 'GET_CONNECTED_ACC_DETAIL_SUCCESS';
export const DELETE_CONNECTED_ACC_DETAIL_SUCCESS = 'DELETE_CONNECTED_ACC_DETAIL_SUCCESS';
export const GET_CONNECTED_ACC_DETAIL_ERROR = 'GET_CONNECTED_ACC_DETAIL_ERROR';
export const GET_CONNECTED_ACC_DETAIL_INIT = 'GET_CONNECTED_ACC_DETAIL_INIT';

export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS';
export const FETCH_WATCHLIST_ERROR = 'FETCH_WATCHLIST_ERROR';
export const FETCH_WATCHLIST_INIT = 'FETCH_WATCHLIST_INIT';

export const SAVE_WATCHLIST_INIT = 'SAVE_WATCHLIST_INIT';
export const UPDATE_WATCHLIST_INIT = 'UPDATE_WATCHLIST_INIT';
export const DELETE_WATCHLIST_INIT = 'DELETE_WATCHLIST_INIT';

export const SAVE_WATCHLIST_SUCCESS = 'SAVE_WATCHLIST_SUCCESS';
export const UPDATE_WATCHLIST_SUCCESS = 'UPDATE_WATCHLIST_SUCCESS';
export const DELETE_WATCHLIST_SUCCESS = 'DELETE_WATCHLIST_SUCCESS';

export const HANDLE_DATA_FETCH = 'HANDLE_DATA_FETCH';
export const FETCH_WATCHLIST_CHART_INIT = 'FETCH_WATCHLIST_CHART_INIT';
export const FETCH_WATCHLIST_CHART_SUCCESS = 'FETCH_WATCHLIST_CHART_SUCCESS';
export const FETCH_WATCHLIST_CHART_ERROR = 'FETCH_WATCHLIST_CHART_ERROR';

export const FETCH_WATCHLIST_COMPANY_NAME_INIT = 'FETCH_WATCHLIST_COMPANY_NAME_INIT';
export const FETCH_WATCHLIST_COMPANY_NAME_SUCCESS = 'FETCH_WATCHLIST_COMPANY_NAME_SUCCESS';
export const FETCH_WATCHLIST_COMPANY_NAME_ERROR = 'FETCH_WATCHLIST_COMPANY_NAME_ERROR';

export const FETCH_TICKER_LIST_INIT = 'FETCH_TICKER_LIST_INIT';
export const FETCH_TICKER_LIST_SUCCESS = 'FETCH_TICKER_LIST_SUCCESS';
export const FETCH_TICKER_LIST_ERROR = 'FETCH_TICKER_LIST_ERROR';

export const FETCH_COLLEGE_CAL_INIT = 'FETCH_COLLEGE_CAL_INIT';
export const FETCH_COLLEGE_CAL_SUCCESS = 'FETCH_COLLEGE_CAL_SUCCESS';
export const FETCH_COLLEGE_CAL_ERROR = 'FETCH_COLLEGE_CAL_ERROR';

export const GET_SCHOOL_NAMES_INIT = 'GET_SCHOOL_NAMES_INIT';
export const GET_SCHOOL_NAMES_ERROR = 'GET_SCHOOL_NAMES_ERROR';
export const SEARCH_SCHOOL_NAMES = 'SEARCH_SCHOOL_NAMES';

export const HOUSE_AFFORD_CAL_INIT = 'HOUSE_AFFORD_CAL_INIT';
export const HOUSE_AFFORD_CAL_SUCCESS = 'HOUSE_AFFORD_CAL_SUCCESS';
export const HOUSE_AFFORD_CAL_ERROR = 'HOUSE_AFFORD_CAL_ERROR';

export const FETCH_RELOAD_PORTFOLIO_INIT = 'FETCH_RELOAD_PORTFOLIO_INIT';
export const FETCH_RELOAD_PORTFOLIO_SUCCESS = 'FETCH_RELOAD_PORTFOLIO_SUCCESS';
export const FETCH_RELOAD_PORTFOLIO_ERROR = 'FETCH_RELOAD_PORTFOLIO_ERROR';

export const FETCH_BLOG_CATEGORY_INIT = 'FETCH_BLOG_CATEGORY_INIT';
export const FETCH_BLOG_CATEGORY_SUCCESS = 'FETCH_BLOG_CATEGORY_SUCCESS';
export const FETCH_BLOG_CATEGORY_ERROR = 'FETCH_BLOG_CATEGORY_ERROR';

export const FETCH_BLOG_DASHBOARD_CATEGORY_INIT = 'FETCH_BLOG_DASHBOARD_CATEGORY_INIT';
export const FETCH_BLOG_DASHBOARD_CATEGORY_SUCCESS = 'FETCH_BLOG_DASHBOARD_CATEGORY_SUCCESS';
export const FETCH_BLOG_DASHBOARD_CATEGORY_ERROR = 'FETCH_BLOG_DASHBOARD_CATEGORY_ERROR';

export const FETCH_ALL_BLOGS_INIT = 'FETCH_ALL_BLOGS_INIT';
export const FETCH_ALL_BLOGS_SUCCESS = 'FETCH_ALL_BLOGS_SUCCESS';
export const FETCH_ALL_BLOGS_ERROR = 'FETCH_ALL_BLOGS_ERROR';

export const FETCH_IMAGE_LINK_INIT = 'FETCH_IMAGE_LINK_INIT';
export const FETCH_IMAGE_LINK_SUCCESS = 'FETCH_IMAGE_LINK_SUCCESS';
export const FETCH_IMAGE_LINK_ERROR = 'FETCH_IMAGE_LINK_ERROR';

export const DELETE_IMAGE_LINK_INIT = 'DELETE_IMAGE_LINK_INIT';
export const DELETE_IMAGE_LINK_SUCCESS = 'DELETE_IMAGE_LINK_SUCCESS';
export const DELETE_IMAGE_LINK_ERROR = 'DELETE_IMAGE_LINK_ERROR';

export const FETCH_ALL_TABLE_INIT = 'FETCH_ALL_TABLE_INIT';
export const FETCH_ALL_TABLE_SUCCESS = 'FETCH_ALL_TABLE_SUCCESS';
export const FETCH_ALL_TABLE_ERROR = 'FETCH_ALL_TABLE_ERROR';

export const FETCH_ALL_DASHBOARD_BLOGS_INIT = 'FETCH_ALL_DASHBOARD_BLOGS_INIT';
export const FETCH_ALL_DASHBOARD_BLOGS_SUCCESS = 'FETCH_ALL_DASHBOARD_BLOGS_SUCCESS';
export const FETCH_ALL_DASHBOARD_BLOGS_ERROR = 'FETCH_ALL_DASHBOARD_BLOGS_ERROR';

export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_ERROR = 'GET_BLOG_LIST_ERROR';
export const GET_BLOG_LIST_INIT = 'GET_BLOG_LIST_INIT';

export const FETCH_BLOG_BY_ID_INIT = 'FETCH_BLOG_BY_ID_INIT';
export const FETCH_BLOG_BY_ID_SUCCESS = 'FETCH_BLOG_BY_ID_SUCCESS';
export const FETCH_BLOG_BY_ID_ERROR = 'FETCH_BLOG_BY_ID_ERROR';

export const FETCH_DASHBOARD_BLOG_BY_ID_INIT = 'FETCH_DASHBOARD_BLOG_BY_ID_INIT';
export const FETCH_DASHBOARD_BLOG_BY_ID_SUCCESS = 'FETCH_DASHBOARD_BLOG_BY_ID_SUCCESS';
export const FETCH_DASHBOARD_BLOG_BY_ID_ERROR = 'FETCH_DASHBOARD_BLOG_BY_ID_ERROR';

export const CREATE_NEW_BLOG_INIT = 'CREATE_NEW_BLOG_INIT';
export const CREATE_NEW_BLOG_SUCCESS = 'CREATE_NEW_BLOG_SUCCESS';
export const CREATE_NEW_BLOG_ERROR = 'CREATE_NEW_BLOG_ERROR';

export const CREATE_NEW_TABLE_INIT = 'CREATE_NEW_TABLE_INIT';
export const CREATE_NEW_TABLE_SUCCESS = 'CREATE_NEW_TABLE_SUCCESS';
export const CREATE_NEW_TABLE_ERROR = 'CREATE_NEW_TABLE_ERROR';

export const UPLOAD_NEW_TABLE_INIT = 'UPLOAD_NEW_TABLE_INIT';
export const UPLOAD_NEW_TABLE_SUCCESS = 'UPLOAD_NEW_TABLE_SUCCESS';
export const UPLOAD_NEW_TABLE_ERROR = 'UPLOAD_NEW_TABLE_ERROR';

export const UPDATE_BLOG_INIT = 'UPDATE_BLOG_INIT';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_ERROR = 'UPDATE_BLOG_ERROR';

export const UPDATE_TABLE_INIT = 'UPDATE_TABLE_INIT';
export const UPDATE_TABLE_SUCCESS = 'UPDATE_TABLE_SUCCESS';
export const UPDATE_TABLE_ERROR = 'UPDATE_TABLE_ERROR';

export const UPDATE_BLOG_CATEGORY_INIT = 'UPDATE_BLOG_CATEGORY_INIT';
export const UPDATE_BLOG_CATEGORY_SUCCESS = 'UPDATE_BLOG_CATEGORY_SUCCESS';
export const UPDATE_BLOG_CATEGORY_ERROR = 'UPDATE_BLOG_CATEGORY_ERROR';

export const DELETE_BLOG_INIT = 'DELETE_BLOG_INIT';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_ERROR = 'DELETE_BLOG_ERROR';

export const DELETE_BLOG_CATEGORY_INIT = 'DELETE_BLOG_CATEGORY_INIT';
export const DELETE_BLOG_CATEGORY_SUCCESS = 'DELETE_BLOG_CATEGORY_SUCCESS';
export const DELETE_BLOG_CATEGORY_ERROR = 'DELETE_BLOG_CATEGORY_ERROR';

export const SEND_CHAT_BOT_ANALYSIS_MSG_INIT = 'SEND_CHAT_BOT_ANALYSIS_MSG_INIT';
export const SEND_CHAT_BOT_ANALYSIS_MSG_SUCCESS = 'SEND_CHAT_BOT_ANALYSIS_MSG_SUCCESS';
export const SEND_CHAT_BOT_ANALYSIS_MSG_ERROR = 'SEND_CHAT_BOT_ANALYSIS_MSG_ERROR';

export const FETCH_HOME_PURCHASE_COLL_SUCCESS = 'FETCH_HOME_PURCHASE_COLL_SUCCESS';
export const FETCH_HOME_PURCHASE_COLL_ERROR = 'FETCH_HOME_PURCHASE_COLL_ERROR';
export const FETCH_HOME_PURCHASE_COLL_INIT = 'FETCH_HOME_PURCHASE_COLL_INIT';
export const SUBMIT_HOME_PURCHASE_COLL_SUCCESS = 'SUBMIT_HOME_PURCHASE_COLL_SUCCESS';
export const SUBMIT_HOME_PURCHASE_COLL_ERROR = 'SUBMIT_HOME_PURCHASE_COLL_ERROR';
export const SUBMIT_HOME_PURCHASE_COLL_INIT = 'SUBMIT_HOME_PURCHASE_COLL_INIT';
export const FETCH_HOME_PURCHASE_FILE_DATA_SUCCESS = 'FETCH_HOME_PURCHASE_FILE_DATA_SUCCESS';
export const FETCH_HOME_PURCHASE_FILE_DATA_ERROR = 'FETCH_HOME_PURCHASE_FILE_DATA_ERROR';
export const FETCH_HOME_PURCHASE_FILE_DATA_INIT = 'FETCH_HOME_PURCHASE_FILE_DATA_INIT';
export const SUBMIT_HOME_PURCHASE_FILE_DATA_SUCCESS = 'SUBMIT_HOME_PURCHASE_FILE_DATA_SUCCESS';
export const SUBMIT_HOME_PURCHASE_FILE_DATA_ERROR = 'SUBMIT_HOME_PURCHASE_FILE_DATA_ERROR';
export const SUBMIT_HOME_PURCHASE_FILE_DATA_INIT = 'SUBMIT_HOME_PURCHASE_FILE_DATA_INIT';

export const SET_ACTIVE_SETTINGS_TAB = 'SET_ACTIVE_SETTINGS_TAB';
export const SET_ACTIVE_SETTINGS_ADMIN_PANEL_TAB = 'SET_ACTIVE_SETTINGS_ADMIN_PANEL_TAB';

export const MORTGAGE_REFINANCE_CAL_INIT = 'MORTGAGE_REFINANCE_CAL_INIT';
export const MORTGAGE_REFINANCE_CAL_SUCCESS = 'MORTGAGE_REFINANCE_CAL_SUCCESS';
export const MORTGAGE_REFINANCE_CAL_ERROR = 'MORTGAGE_REFINANCE_CAL_ERROR';

export const FETCH_FIXED_INCOME_HISTORY_INIT = 'FETCH_FIXED_INCOME_HISTORY_INIT';
export const FETCH_FIXED_INCOME_HISTORY_SUCCESS = 'FETCH_FIXED_INCOME_HISTORY_SUCCESS';
export const FETCH_FIXED_INCOME_HISTORY_ERROR = 'FETCH_FIXED_INCOME_HISTORY_ERROR';

export const FETCH_CHAT_MESSAGES_INIT = 'FETCH_CHAT_MESSAGES_INIT';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const FETCH_CHAT_MESSAGES_ERROR = 'FETCH_CHAT_MESSAGES_ERROR';

export const DELETE_CHAT_MESSAGES_INIT = 'DELETE_CHAT_MESSAGES_INIT';
export const DELETE_CHAT_MESSAGES_SUCCESS = 'DELETE_CHAT_MESSAGES_SUCCESS';
export const DELETE_CHAT_MESSAGES_ERROR = 'DELETE_CHAT_MESSAGES_ERROR';

export const DELETE_VALIDATE_CHAT_MESSAGES_INIT = 'DELETE_VALIDATE_CHAT_MESSAGES_INIT';
export const DELETE_VALIDATE_CHAT_MESSAGES_SUCCESS = 'DELETE_VALIDATE_CHAT_MESSAGES_SUCCESS';
export const DELETE_VALIDATE_CHAT_MESSAGES_ERROR = 'DELETE_VALIDATE_CHAT_MESSAGES_ERROR';

export const FETCH_VALIDATED_CHAT_MESSAGES_INIT = 'FETCH_VALIDATED_CHAT_MESSAGES_INIT';
export const FETCH_VALIDATED_CHAT_MESSAGES_SUCCESS = 'FETCH_VALIDATED_CHAT_MESSAGES_SUCCESS';
export const FETCH_VALIDATED_CHAT_MESSAGES_ERROR = 'FETCH_VALIDATED_CHAT_MESSAGES_ERROR';

export const UPDATE_CHAT_MESSAGES_INIT = 'UPDATE_CHAT_MESSAGES_INIT';
export const UPDATE_CHAT_MESSAGES_SUCCESS = 'UPDATE_CHAT_MESSAGES_SUCCESS';
export const UPDATE_CHAT_MESSAGES_ERROR = 'UPDATE_CHAT_MESSAGES_ERROR';

export const UPDATE_CHAT_VALIDATE_MESSAGES_INIT = 'UPDATE_VALIDATE_CHAT_MESSAGES_INIT';
export const UPDATE_CHAT_VALIDATE_MESSAGES_SUCCESS = 'UPDATE_VALIDATE_CHAT_MESSAGES_SUCCESS';
export const UPDATE_CHAT_VALIDATE_MESSAGES_ERROR = 'UPDATE_VALIDATE_CHAT_MESSAGES_ERROR';
export const FETCH_INVESTMENT_HOLDINGS_INIT = 'FETCH_INVESTMENT_HOLDINGS_INIT';
export const FETCH_INVESTMENT_HOLDINGS_SUCCESS = 'FETCH_INVESTMENT_HOLDINGS_SUCCESS';
export const FETCH_INVESTMENT_HOLDINGS_ERROR = 'FETCH_INVESTMENT_HOLDINGS_ERROR';

export const FETCH_INVESTMENT_TRANSACTIONS_INIT = 'FETCH_INVESTMENT_TRANSACTIONS_INIT';
export const FETCH_INVESTMENT_TRANSACTIONS_SUCCESS = 'FETCH_INVESTMENT_TRANSACTIONS_SUCCESS';
export const FETCH_INVESTMENT_TRANSACTIONS_ERROR = 'FETCH_INVESTMENT_TRANSACTIONS_ERROR';

export const UPDATE_PAYMENT_INIT = 'UPDATE_PAYMENT_INIT';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';

export const GET_CARD_INIT = 'GET_CARD_INIT';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_ERROR = 'GET_CARD_ERROR';

export const FETCH_SCROLLING_TICKER_SUCCESS = 'FETCH_SCROLLING_TICKER_SUCCESS';
export const FETCH_SCROLLING_TICKER_ERROR = 'FETCH_SCROLLING_TICKER_ERROR';
export const FETCH_SCROLLING_TICKER_INIT = 'FETCH_SCROLLING_TICKER_INIT';

export const FETCH_NEWS_FEED_INIT = 'FETCH_NEWS_FEED_INIT';
export const FETCH_NEWS_FEED_SUCCESS = 'FETCH_NEWS_FEED_SUCCESS';
export const FETCH_NEWS_FEED_ERROR = 'FETCH_NEWS_FEED_ERROR';

export const SUBMIT_NEWS_FEED_SUCCESS = 'SUBMIT_NEWS_FEED_SUCCESS';
export const SUBMIT_NEWS_FEED_ERROR = 'SUBMIT_NEWS_FEED_ERROR';
export const SUBMIT_NEWS_FEED_INIT = 'SUBMIT_NEWS_FEED_INIT';

export const FETCH_NEWS_BY_ID_INIT = 'FETCH_NEWS_BY_ID_INIT';
export const FETCH_NEWS_BY_ID_SUCCESS = 'FETCH_NEWS_BY_ID_SUCCESS';
export const FETCH_NEWS_BY_ID_ERROR = 'FETCH_NEWS_BY_ID_ERROR';

export const UPLOAD_RECURRING_FILE_INIT = "UPLOAD_RECURRING_FILE_INIT";
export const UPLOAD_RECURRING_FILE_SUCCESS = "UPLOAD_RECURRING_FILE_SUCCESS";
export const UPLOAD_RECURRING_FILE_ERROR = "UPLOAD_RECURRING_FILE_ERROR";
export const GET_RECURRING_FILE_SUCCESS = "GET_RECURRING_FILE_SUCCESS";
export const GET_RECURRING_FILE_ERROR = "GET_RECURRING_FILE_ERROR";
export const GET_RECURRING_FILE_INIT = "GET_RECURRING_FILE_INIT";
export const FETCH_SELECTED_RECURRING_FILE_SUCCESS = "FETCH_SELECTED_RECURRING_FILE_SUCCESS";
export const FETCH_SELECTED_RECURRING_FILE_ERROR = "FETCH_SELECTED_RECURRING_FILE_ERROR";
export const FETCH_SELECTED_RECURRING_FILE_INIT = "FETCH_SELECTED_RECURRING_FILE_INIT";
export const DELETE_RECURRING_FILE_INIT = "DELETE_RECURRING_FILE_INIT";
export const DELETE_RECURRING_FILE_SUCCESS = "DELETE_RECURRING_FILE_SUCCESS";
export const DELETE_RECURRING_FILE_ERROR = "DELETE_RECURRING_FILE_ERROR";

export const SMART_RECURRING_SUCCESS = "SMART_RECURRING_SUCCESS";
export const SMART_RECURRING_ERROR = "SMART_RECURRING_ERROR";
export const SMART_RECURRING_INIT = "SMART_RECURRING_INIT";
export const SUBMIT_RECURRING_INIT = "SUBMIT_RECURRING_INIT";
export const SUBMIT_RECURRING_ERROR = "SUBMIT_RECURRING_ERROR";
export const SUBMIT_RECURRING_SUCCESS = "SUBMIT_RECURRING_SUCCESS";
export const GET_RECURRING_CATEGORY_INIT = "GET_RECURRING_CATEGORY_INIT";
export const GET_RECURRING_CATEGORY_SUCCESS = "GET_RECURRING_CATEGORY_SUCCESS";
export const GET_RECURRING_CATEGORY_ERROR = "GET_RECURRING_CATEGORY_ERROR";