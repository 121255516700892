import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';

const RecurringPaymentCalDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Card>
                <CardContent>
                    <QuesAnsBox>
                        <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                            About Regular Payment and Bank Tracking Tool
                        </Typography>
                        <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                            This tool helps you understand the size of regular payments, and subscriptions as well as payment methods and summary of your main bank accounts used for payments.
                        </Typography>
                        <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                            By providing a few key details, you can gain valuable insight into how your current recurring payments. You can use this calculator to track monthly expenses such as your Apple TV, Hulu and Netflix subscriptions. Also, you can add payment methods and contact details to more easily track payments and methods.
                        </Typography>
                        <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                            Finally, we highlight your largest regular expenses which should all you to target them for reduction or elimination. We also provide a way for you to track contact details for each vendor so you can contact them to cancel or negotiate a reduction.  Recurring expenses such as subscription can add up and can often be reduced or eliminated.
                        </Typography>
                        <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                            We output a number of charts designed to highlight high expenses.
                        </Typography>
                    </QuesAnsBox>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default RecurringPaymentCalDesc;
