import { Button, Card, CardContent, Grid, Slider, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { roseColor, successColor, warningColor } from 'assets/jss/material-kit-react';
import AreaCharts from 'components/Charts/AreaChart';
import BarChart from 'components/Charts/BarChart';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import { fetchInvestDashboardData } from 'redux/actions/InvestDashboardActions';
import { fetchFixedIncomeDashData } from 'redux/actions/FixedIncomeDashActions';
import { a11yProps, numberWithCommas } from 'utils/Utils';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import { Box } from '@mui/system';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { ChartCardParent } from 'assets/jss/PortfolioStyle';
import { SyncTitleTypography } from 'assets/jss/DashboardStyle';
import { ZoomSliderBox } from 'assets/jss/DashboardStyle';
import * as tooltip from 'Tooltip.json';
import FixedDashboardChart from './FixedIncomeChart';
import { CustomButton } from 'assets/jss/ButtonStyle';

function InvestmentDashboard(props) {
    const title = ['Corporate Rates', 'Treasury Rates'];
    const dispatch = useDispatch();
    const theme = useTheme();
    const chartParentRef = useRef(null);
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [result, setResult] = useState({});
    const [collList, setColumns] = useState([]);
    const [chartData, setChartData] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const [rangeMax, setRangeMax] = useState(50);
    const [treasMinMax, setTreasMinMax] = useState({});
    const [historicalResult, setHistoricalReesult] = useState([]);

    const [enableTreasuryChart, setEnableTreasuryChart] = useState({});
    const [enableCorporateChart, setEnableCorporateChart] = useState({});
    const isMobile = window.innerWidth < 768;
    const [chartWidth, setChartWidth] = useState(0);

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);
    const getInvestDashboardData = () => {
        dispatch(fetchInvestDashboardData());
    };
    const getLatestData = () => {
        dispatch(fetchInvestDashboardData(true));
    };
    const handleRangeSelection = (range, value) => {
        //   setEnableChart(range);
        let objToPass = {
            maturity: value,
            range: range
        };
        dispatch(fetchFixedIncomeDashData(objToPass));
    };

    useEffect(() => {
        getInvestDashboardData();
    }, []);
    const chartRef = useRef();

    const handleSymbolClick = (data, value) => {
        let chart_data = {
            ticker: data,
            latest: value[0][data]
        };
        if (data.endsWith('Month') || data.endsWith('year')) {
            setEnableTreasuryChart(chart_data);
        } else {
            setEnableCorporateChart(chart_data);
        }

        let objToPass = {
            maturity: data,
            range: '1 y'
        };
        dispatch(fetchFixedIncomeDashData(objToPass));
    };

    // useEffect(() => {
    //   if (profileDetail?.annualincome != 0 || profileDetail?.annualincome != undefined){
    //   setAnnualIncome(profileDetail?.annualincome)
    //   }
    //   dispatch(fetchLoanDashBoardData());
    // }, []);

    //     // fix this naming in the props!
    //   useEffect(() => {
    //     console.log(props,'props');
    //     if (props.economicDashboardData.ecoDashboardDetail.length) {
    //         const resultData = props.economicDashboardData.ecoDashboardDetail;
    //         setResult(resultData);
    //     }
    // }, [props.economicDashboardData]);

    const responsiveMargin = matches
        ? {
              top: 20,
              right: 50,
              bottom: 200,
              left: 50
          }
        : {
              top: 20,
              right: 50,
              bottom: 120,
              left: 50
          };
    useEffect(() => {
        if (Object.keys(props.investDashboardData.investDashboardDetail).length) {
            const resultData = props.investDashboardData.investDashboardDetail;
            let formatChartData = [];
            let chartarr = [];
            let chartDataPremLists = {},
                chartDataCorpLists = {};
            let premMinVal = [];
            let cropMinVal = [],
                allLength = [],
                minValTreas = [];
            resultData.data.forEach((val, i) => {
                const individualChartData = [];
                const valToLoop = val[0];
                // to create column list for table
                Object.keys(valToLoop).forEach((innerVal) => {
                    const formattedKey = innerVal.replaceAll(/_/g, ' ');
                    const headerStyles = {
                        justifyContent: 'start',
                        textAlign: 'left',
                        width: '130%',
                        // display: "block",
                        padding: 0
                    };
                    individualChartData.push({
                        Header:
                            innerVal !== 'Rating/Premium' && innerVal !== 'Maturity' ? (
                                <CustomButton
                                    key={innerVal}
                                    variant="text"
                                    onClick={() => handleSymbolClick(innerVal, val)}
                                    style={headerStyles}
                                >
                                    {formattedKey}
                                </CustomButton>
                            ) : (
                                innerVal // Render innerVal if it's 'Rating/Premium', or 'Maturity'
                            ),
                        accessor: innerVal,
                        disableFilters: true,
                        width: (innerVal.match(/premium/gi) && 100) || 50,
                        Cell: ({ value }) =>
                            (!isNaN(value) && value?.toString().indexOf('.') !== -1
                                ? numberWithCommas(value?.toFixed(2))
                                : numberWithCommas(value)) ||
                            value ||
                            '-'
                    });
                });
                // to make format to pass in barchart and areachart component
                const premChartArr = [],
                    corpChartArr = [],
                    treachartArr = [];
                val.forEach((value, vali) => {
                    const objVal = Object.assign({}, value);
                    const idName = objVal['Rating/Premium'] || objVal['Maturity'];
                    delete objVal['Rating/Premium'];
                    delete objVal['Maturity'];
                    if (i === 0) {
                        Object.keys(objVal).forEach((subKey) => {
                            const preminumArr = subKey.match(/premium/gi);
                            const index = premChartArr.findIndex((ele) => ele.label === subKey);
                            if (preminumArr) {
                                const index = premChartArr.findIndex((ele) => ele.label === subKey);
                                if (index > -1) {
                                    premChartArr[index] = {
                                        ...premChartArr[index],
                                        [idName]: objVal[subKey]
                                    };
                                } else {
                                    premChartArr.push({ label: subKey, [idName]: objVal[subKey] });
                                }
                                premMinVal.push(objVal[subKey]);
                            } else {
                                const index = corpChartArr.findIndex((ele) => {
                                    return ele.label === subKey;
                                });
                                if (index > -1) {
                                    corpChartArr[index] = {
                                        ...corpChartArr[index],
                                        [idName]: objVal[subKey]
                                    };
                                } else {
                                    const obj = { label: subKey, [idName]: objVal[subKey] };
                                    corpChartArr.push(obj);
                                }
                                // to find every chart's min value from array and store it
                                cropMinVal.push(objVal[subKey]);
                            }
                        });
                    } else {
                        const filteredVal = Object.keys(objVal).map((fv) => {
                            minValTreas.push(objVal[fv]);
                            return { x: fv, y: objVal[fv] };
                        });
                        allLength.push(filteredVal.length);
                        treachartArr.push({
                            id: idName,
                            data: filteredVal
                        });
                    }
                });
                if (i === 0) {
                    chartDataCorpLists = { data: corpChartArr, min: Math.min(...cropMinVal) };
                    chartDataPremLists = { data: premChartArr, min: Math.min(...premMinVal) };
                }
                if (treachartArr.length > 0) {
                    chartarr.push(treachartArr);
                }
                formatChartData.push(individualChartData);
            });
            if (allLength.length) {
                setTreasMinMax({ min: Math.min(...minValTreas), max: Math.max(...minValTreas) });
                setRangeMax(Math.max(...allLength));
            }
            chartarr.unshift(chartDataPremLists);
            chartarr.unshift(chartDataCorpLists);
            setChartData(chartarr);
            setColumns(formatChartData);
            setResult(props.investDashboardData.investDashboardDetail);
        }
    }, [props.investDashboardData]);

    //  useEffect(() => {
    //      const node = chartRef.current;
    //      if (node) {
    //          setTimeout(() => {
    //              const zoom = d3
    //                  .zoom()
    //                  .scaleExtent([1, 10])
    //                  .translateExtent([
    //                      [0, 0],
    //                      [node.clientWidth, node.clientHeight]
    //                  ])
    //                  .on('zoom', (event) => {
    //                      const transform = event?.transform;
    //                      const svgElement = node.querySelector('.chartwrapper svg g');
    //                      if (svgElement && transform) {
    //                          if(transform.x === 0){
    //                            svgElement.setAttribute('transform', 'translate(70, 20)');
    //                           // d3.select(node).transition().call(zoom.scaleTo, 1);
    //                        }else{
    //                        svgElement.setAttribute('transform', transform.toString());
    //                        }
    //                      }
    //                  });

    //              d3.select(node).call(zoom);
    //          }, 0);
    //      }
    //  }, [chartRef, selectedTab]);
    const filteredData = chartData[2]?.map((singleLine) => ({
        ...singleLine,
        data: singleLine.data.filter((d) => d.y < Math.round(rangeMax))
        // data: singleLine.data.filter((d,i) => i < (rangeMax/10))
    }));

    const handleSwipe = (event) => {
        const delta = Math.sign(event.deltaX);
        const newIndex = selectedTab - delta;
        if (newIndex >= 0 && newIndex < 2) {
            setSelectedTab(newIndex);
        }
    };

    const data = props.investDashboardData?.investDashboardDetail?.data?.[1] ?? null;
    const maturities = ['1 Month', '3 Month', '6 Month', '1 year', '2 year', '3 year', '5 year', '7 year', '10 year', '30 year'];

    const transformedData = maturities.map((maturity) => {
        const entry = {
            name: maturity,
            'Latest Data': data?.find((d) => d.Maturity === 'Latest Data')[maturity],
            'Prior Data': data?.find((d) => d.Maturity === 'Prior Data')[maturity],
            '1 Week Ago': data?.find((d) => d.Maturity === '1 Week Ago')[maturity],
            '1 Month Ago': data?.find((d) => d.Maturity === '1 Month Ago')[maturity]
        };
        return entry;
    });
    useEffect(() => {
        const updateWidth = () => {
            if (chartParentRef.current) {
                setChartWidth(chartParentRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [selectedTab]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <SyncTitleTypography variant="subtitle1">
                        Date of Latest Data : {result?.sync_date && moment.utc(result?.sync_date).format('LL')}
                    </SyncTitleTypography>
                </Grid>
                <Grid item xs="auto" id="load">
                    <Button simple color="primary" size="lg" onClick={getLatestData}>
                        Load The Data
                        <CachedIcon color="white" />
                    </Button>
                </Grid>
            </Grid>
            <TopBlockBox>
                <Tabs
                    className="topblock-tabs"
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="scrollable"
                    allowScrollButtonsMobile={true}
                    aria-label="fixed income dashboard panel tabs"
                >
                    <Tab
                        iconPosition="end"
                        icon={
                            <CustomTooltip
                                tooltipText={tooltip['Corporate tab']}
                                customTooltipStyle={{ maxWidth: 450 }}
                                label={'Corporate Rates'}
                            />
                        }
                        label={'Corporate Rates'}
                        {...a11yProps(0)}
                        id="corporate"
                    />
                    <Tab
                        iconPosition="end"
                        icon={
                            <CustomTooltip
                                customTooltipStyle={{ maxWidth: 450 }}
                                tooltipText={tooltip['Treasury Rates Tab']}
                                label={'Treasury Rates'}
                            />
                        }
                        label="Treasury Rates"
                        {...a11yProps(1)}
                        id="treasury"
                    />
                </Tabs>
            </TopBlockBox>
            {result?.data ? (
                <div style={{ overflow: 'hidden', position: 'relative' }} onTouchStart={(event) => event.preventDefault()}>
                    <div
                        style={{
                            display: 'flex',
                            transition: 'transform 0.3s ease',
                            transform: `translateX(-${selectedTab * 100}%)`
                        }}
                        onTouchMove={handleSwipe}
                    >
                        <div style={{ flex: 'none', width: '100%' }}>
                            <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                                {Object.keys(enableCorporateChart).length > 0 ? (
                                    <FixedDashboardChart
                                        chartdetails={enableCorporateChart}
                                        backButtonClicked={() => setEnableCorporateChart({})}
                                        rangeSelectionClicked={(val) => handleRangeSelection(val, enableCorporateChart.ticker)}
                                    />
                                ) : (
                                    // <button>{"try to click me if you can :)"}</button>
                                    <>
                                        <ChartCardParent elevation={2} className="leave-space-dash">
                                            <EnhancedTable
                                                key={'investment_table_corporate'}
                                                enableSearch={false}
                                                // handleLoadData={getInvestDashboardData}
                                                title={title[0]}
                                                columns={collList[0]}
                                                data={result?.data[0] || []}
                                                // enableSort={true}
                                                enablePagination={false}
                                            />
                                        </ChartCardParent>
                                        <ChartCardParent id="crc" elevation={2} className="leave-space-dash large-height">
                                            <Typography m={'5px 0 0px 15px'} variant="subtitle1">
                                                Corporate Rates
                                            </Typography>
                                            <BarChart
                                                rotateLabel={true}
                                                marginProp={responsiveMargin}
                                                divideLegend={matches && true}
                                                customCount={60}
                                                chartData={chartData[0]}
                                                groupMode="grouped"
                                            />
                                        </ChartCardParent>
                                        <ChartCardParent elevation={2} className="leave-space-dash large-height">
                                            <Typography m={'5px 0 0px 15px'} variant="subtitle1">
                                                Corporate Spreads (vs. 10 Year Risk Free Rate)
                                            </Typography>
                                            <BarChart
                                                rotateLabel={true}
                                                marginProp={responsiveMargin}
                                                divideLegend={matches && true}
                                                formatYAxis={false}
                                                customCount={80}
                                                chartData={chartData[1]}
                                                groupMode="grouped"
                                            />
                                        </ChartCardParent>
                                    </>
                                )}
                            </PortfolioTabPanel>
                        </div>
                        <div style={{ flex: 'none', width: '100%' }}>
                            <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                                {Object.keys(enableTreasuryChart).length > 0 ? (
                                    <FixedDashboardChart
                                        chartdetails={enableTreasuryChart}
                                        backButtonClicked={() => setEnableTreasuryChart({})}
                                        rangeSelectionClicked={(val) => handleRangeSelection(val, enableTreasuryChart.ticker)}
                                    />
                                ) : (
                                    <>
                                        <ChartCardParent elevation={2} className="leave-space-dash">
                                            <EnhancedTable
                                                key={'investment_table_treasury'}
                                                enableSearch={false}
                                                hideTooltipColumn={['Maturity']}
                                                // handleLoadData={getInvestDashboardData}
                                                title={title[1]}
                                                columns={collList[1]}
                                                data={result?.data[1] || []}
                                                // enableSort={true}
                                                enablePagination={false}
                                                // onColumnClick={handleColumnClick}
                                            />
                                        </ChartCardParent>
                                        <div ref={chartParentRef} style={{ width: '100%' }}>
                                            <ChartCardParent
                                                id="trd"
                                                elevation={2}
                                                style={{ height: '380px' }}
                                                className="leave-space-dash"
                                            >
                                                <Typography m={'5px 0 0px 15px'} variant="subtitle1">
                                                    Treasury Rate Data
                                                </Typography>
                                                <ZoomSliderBox ref={chartRef} className="chartwrapper">
                                                    <Slider
                                                        id="yaxis-zoom-slider"
                                                        aria-label="Zoom slider"
                                                        defaultValue={4.5}
                                                        aria-labelledby="Zoom slider input"
                                                        // getAriaValueText={valuetext}
                                                        step={0.2}
                                                        marks
                                                        min={treasMinMax.min}
                                                        max={Math.ceil((treasMinMax.max || 10) / 10) * 10}
                                                        // valueLabelDisplay="on"
                                                        orientation="vertical"
                                                        value={rangeMax}
                                                        valueLabelDisplay="auto"
                                                        onChange={(e) => setRangeMax(e.target.value)}
                                                    />
                                                    {/* <AreaCharts
                                                    dateType={true}
                                                    data={chartData[2]}
                                                    // data={filteredData}
                                                    enableArea={false}
                                                    enablePoint={false}
                                                    areamin={treasMinMax.min}
                                                    // rotateLabel={true}
                                                    // halfGraph={true}
                                                    // legendName="Rating/Premium"
                                                    rangeMax={rangeMax}
                                                    leftLegendName={'Rate'}
                                                    customColor={[roseColor, warningColor, successColor, '#c1c1c1']}
                                                    curveType={'linear'}
                                                /> */}
                                                    <div style={{ position: 'relative' }}>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '35%',
                                                                left: '-10px',
                                                                transform: 'rotate(-90deg)',
                                                                color: 'gray'
                                                            }}
                                                        >
                                                            Rate
                                                        </div>
                                                        <LineChart
                                                            width={chartWidth}
                                                            height={310}
                                                            data={transformedData}
                                                            margin={{
                                                                top: 15,
                                                                right: 70,
                                                                // left:  20,
                                                                bottom: 5
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis domain={[treasMinMax.min, rangeMax]} />
                                                            <Tooltip />
                                                            <Legend />
                                                            <Line type="monotone" dataKey="Latest Data" stroke="#FF3333" />
                                                            <Line type="monotone" dataKey="Prior Data" stroke="#33FF49" />
                                                            <Line type="monotone" dataKey="1 Week Ago" stroke="#33A8FF" />
                                                            <Line type="monotone" dataKey="1 Month Ago" stroke="#E633FF" />
                                                        </LineChart>
                                                    </div>
                                                </ZoomSliderBox>
                                            </ChartCardParent>
                                        </div>
                                    </>
                                )}
                            </PortfolioTabPanel>
                        </div>
                    </div>
                </div>
            ) : (
                <Card>
                    <CardContent>
                        <NoDataFound msg="No data found." />
                    </CardContent>
                </Card>
            )}
        </Box>
    );
}

export default InvestmentDashboard;
