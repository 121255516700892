import React, { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import Globals from 'Globals.js';
import { makeStyles } from '@mui/styles';
import { splitArrayIntoChunksOfLen, numberWithCommas } from 'utils/Utils';
import { BoxLegendSvg } from '@nivo/legends';
import styles from 'assets/jss/material-kit-react/views/portfolioPage';
import { Paper, Popover, useTheme } from '@mui/material';
import { trimDecimals } from 'utils/Utils';
const useStyles = makeStyles(styles);

const PieChart = (props) => {
    const { chartData, innerRadius, halfGraph, label, ...rest } = props;
    const classes = useStyles();
    const hasWindow = typeof window !== 'undefined';
    let count = 20;
    const theme = useTheme();
    const [chartSetup, setChartSetup] = useState(getWindowDimensions());
    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        var obj = {
            legendHeight: 50,
            sliceArrCount: 3,
            translateX: props?.initXcount?.[0] || 25,
            translateY: props?.initYcount?.['0'] || 240,
            marginBottom: 50,
            counterButtonTranslateX: (props?.initXcount?.[0] || 25) + 310,
            counterButtonTranslateY: (props?.initYcount?.['0'] || 240) + 50,
            popoverLegendsItemSpacing: -25,
            popoverLegendsTranslateX: -130,
            popoverLegendsTranslateY: -50
        };
        count += 30;
        if (width <= 1300 && width > 1024) {
            obj = {
                ...obj,
                translateY: props?.initYcount?.['1024'] || 240,
                legendHeight: 50,
                counterButtonTranslateX: (props?.initXcount?.[1] || -20) + 70,
                counterButtonTranslateY: (props?.initYcount?.['1024'] || -20) + 80,
                popoverLegendsTranslateY: -50,
                popoverLegendsItemSpacing: -25
            };
        } else if (width <= 1024 && width > 768) {
            obj = {
                ...obj,
                translateY: props?.initYcount?.['1024'] || 240,
                translateX: props?.initXcount?.[1] || -20,
                legendHeight: 50,
                sliceArrCount: 2,
                counterButtonTranslateX: (props?.initXcount?.[1] || -20) + 70,
                counterButtonTranslateY: (props?.initYcount?.['1024'] || -20) + 80,
                popoverLegendsTranslateY: -50,
                popoverLegendsItemSpacing: -25
            };
        } else if (width <= 768 && width > 425) {
            obj = {
                ...obj,
                translateY: props?.initYcount?.['768'] || 240,
                translateX: props?.initXcount?.[2] || 70,
                legendHeight: 100,
                sliceArrCount: 2,
                counterButtonTranslateX: (props?.initXcount?.[2] || 70) + 250,
                counterButtonTranslateY: (props?.initYcount?.['768'] || 240) + 25,
                popoverLegendsItemSpacing: -75,
                popoverLegendsTranslateY: -25
            };
        } else if (width <= 425 && width > 375) {
            obj = {
                ...obj,
                translateY: props?.initYcount?.['425'] || 280,
                translateX: props?.initXcount?.[3] || -70,
                legendHeight: 200,
                sliceArrCount: 2,
                counterButtonTranslateX: (props?.initXcount?.[3] || -70) + 70,
                counterButtonTranslateY: props?.initYcount?.['425'] || 280,
                popoverLegendsItemSpacing: -175,
                popoverLegendsTranslateY: 25
            };
            count += 60;
        } else if (width <= 375) {
            obj = {
                ...obj,
                translateY: props?.initYcount?.['375'] || 280,
                legendHeight: 200,
                sliceArrCount: 2,
                translateX: props?.initXcount?.[4] || -120,
                counterButtonTranslateX: (props?.initXcount?.[4] || -120) + 65,
                counterButtonTranslateY: (props?.initYcount?.['375'] || 280) + 5,
                popoverLegendsItemSpacing: -175,
                popoverLegendsTranslateY: 25
            };
            count += 30;
        }
        if (halfGraph) {
            obj = { ...obj, legendHeight: 200, sliceArrCount: 3, translateX: 30, marginBottom: 30 };
            count = props?.initCount || 20;
            if (width > 1024) {
                obj = {
                    ...obj,
                    legendHeight: 50
                };
            } else if (width > 768) {
                obj = {
                    ...obj,
                    translateX: -20,
                    legendHeight: 100
                };
            } else if (width <= 768 && width > 425) {
                obj = {
                    ...obj,
                    translateX: -110,
                    legendHeight: 200,
                    sliceArrCount: 2,
                    marginBottom: 0
                };
            } else if (width <= 425 && width > 375) {
                obj = {
                    ...obj,
                    translateX: -90,
                    legendHeight: 200,
                    sliceArrCount: 2
                };
            } else if (width <= 375) {
                obj = {
                    ...obj,
                    translateX: -110,
                    legendHeight: 200,
                    sliceArrCount: 2
                };
            }
        }
        return obj;
    }
    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setChartSetup(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    const legendProps = {
        anchor: 'bottom',
        translateY: 0,
        direction: 'row',
        itemWidth: 100,
        itemHeight: chartSetup.legendHeight,
        // itemsSpacing: 20,
        itemOpacity: 0.8,
        symbolShape: 'square'
    };

    const remainingLegendProps = {
        ...legendProps,
        direction: 'column',
        translateX: chartSetup.popoverLegendsTranslateX,
        itemsSpacing: chartSetup.popoverLegendsItemSpacing,
        containerHeight: 100,
        containerWidth: 400
    };
    /**
     * to display tooltip on hover of the
     * @param {*} param
     * @returns
     */
    const CustomTooltip = ({ datum }) => {
        if (datum) {
            const currValue = numberWithCommas(
                rest.hoverTooltipValueKey ? datum.data[rest.hoverTooltipValueKey]?.toFixed(2) : datum.data.value?.toFixed(2)
            );
            return (
                <div className={classes.customTooltip}>
                    <span className={classes.colorBox} style={{ background: datum.color }}></span>
                    <p className={classes.tooltipLabel}>
                        {datum.data.id} : <strong>{currValue}</strong>
                    </p>
                </div>
            );
        }
        return null;
    };
    const PieLegend = (data) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [openLegendsPoper, setOpenLegendsPoper] = useState(false);

        // legend variable contains data with top 3 values.
        const legend = [...data.dataWithArc].sort((a, b) => b.value - a.value).splice(0, chartSetup.sliceArrCount);
        // if more than chartSetup.sliceArrCount data available in data, remaining will be stored in remainingLegend.
        const remainingLegend = [...data.dataWithArc].sort((a, b) => b.value - a.value).splice(chartSetup.sliceArrCount);
        let pieYcount = chartSetup.translateY;
        const legendsWithCroppedText = legend.map((val) => {
            const obj = { ...val, label: val.label?.length > 13 ? val.label.slice(0, 12) + '...' : val.label };
            return obj;
        });
        const remainigLegendsWithCroppedText = remainingLegend.map((val) => {
            return {
                ...val,
                label: `${val.label?.length > 13 ? val.label.slice(0, 12) + '...' : val.label}${
                    rest.label
                        ? rest.label === 'Investment Type' && rest.hoverTooltipValueKey === 'marketValue'
                            ? `, $${numberWithCommas(trimDecimals(val.data.marketValue, 2))}`
                            : `, ${numberWithCommas(trimDecimals(val.data.percentageValue, 2))}%`
                        : ''
                } `
            };
        });

        return (
            <React.Fragment>
                <BoxLegendSvg
                    key={legend.id + '_' + 0 + '_PieLegend'}
                    data={legendsWithCroppedText}
                    {...legendProps}
                    {...legend}
                    translateX={chartSetup.translateX}
                    translateY={pieYcount}
                    containerHeight={100}
                    containerWidth={400}
                />

                {remainingLegend.length && (
                    <>
                        {/* Button for opening popover */}
                        <g transform={`translate(${chartSetup.counterButtonTranslateX},${chartSetup.counterButtonTranslateY})`}>
                            <rect
                                width="30"
                                height="20"
                                rx="10"
                                fill={theme.palette.grey[300]}
                                transform="translate(30,15)"
                                onClick={(event) => {
                                    setAnchorEl(event.target);
                                    setOpenLegendsPoper((prev) => !prev);
                                }}
                                cursor="pointer"
                            />
                            <text
                                x="45"
                                y="24"
                                textAnchor="middle"
                                alignmentBaseline="central"
                                fill="black"
                                fontSize="13"
                                fontWeight="bold"
                                pointerEvents="none"
                            >
                                {`+${remainingLegend.length}`}
                            </text>
                        </g>
                        {/* Popover component */}
                        <Popover
                            open={openLegendsPoper}
                            onClose={() => setOpenLegendsPoper(false)}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            slotProps={{
                                paper: {
                                    component: Paper,
                                    sx: {
                                        maxHeight: '200px'
                                    }
                                }
                            }}
                        >
                            <svg width="200" height={26 * remainingLegend.length + 15}>
                                <BoxLegendSvg
                                    key={remainingLegend.id + '_' + 0 + '_PieLegend'}
                                    data={remainigLegendsWithCroppedText}
                                    {...remainingLegendProps}
                                    {...remainingLegend}
                                    translateY={chartSetup.popoverLegendsTranslateY + (remainingLegend.length - 1) * 25}
                                />
                            </svg>
                        </Popover>
                    </>
                )}
            </React.Fragment>
        );
    };
    return (
        <>
            <ResponsivePie
                key={rest?.hoverTooltipValueKey || 'PieChart'}
                data={chartData}
                margin={{ top: 10, right: 50, bottom: 50 + chartSetup.marginBottom, left: 50 }}
                innerRadius={innerRadius ? 0.5 : 0}
                cornerRadius={3}
                padding={0.3}
                onClick={(node, event) => (props?.handleOnClick ? props.handleOnClick(node, event) : '')}
                tooltip={(val) => CustomTooltip(val)}
                colors={Globals.Colors}
                activeOuterRadiusOffset={8}
                layers={['arcLinkLabels', 'arcs', 'arcLabels', PieLegend]}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                padAngle={2}
                arcLabelsTextColor="black"
                onMouseEnter={(_datum, event) => {
                    event.currentTarget.style.cursor = (label === 'Investment Type' || label === 'Category Pie') ? 'pointer' : '';
                }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={chartData.map((fillData, i) => {
                    return {
                        match: { id: fillData.id },
                        id: `${i % 2 === 0 ? 'dots' : 'lines'}`
                    };
                })}
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'row',
                        justify: false,
                        // itemsSpacing: 102,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        </>
    );
};

export default PieChart;
