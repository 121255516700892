export const toolsDetails = [
    {
        parent: 'Calculators',
        children: [
            {
                title: 'Budgets & Taxes ',
                image: 'budget.svg',
                children: [
                    { to: '/tax-estimator-calculator', name: 'Tax Estimator', image: '' },
                    { to: '/budget-calculator', name: 'Budget Calculator', image: '' },
                    { to: '/budget-analysis-calculator', name: 'Budget Analysis', image: '' },
                    { to: '/tips-calculator', name: 'Tips Calculator', image: 'tipscal.svg' },
                    { to: '/compound-interest-calculator', name: 'Compound Interest Calculator', image: '' },
                    { to: '/recurring-expense-calculator', name: 'Recurring Expense Calculator', image: '' },
                    { to: '/regular-payment-and-bank-tracking-tool', name: 'Regular Payment and Bank Tracking Tool', image: ''}
                ]
            },
            {
                title: 'Investments',
                image: 'investment.svg',
                children: [
                    { to: '/portfolio-valuation-calculator', name: 'Portfolio Valuation', image: 'portfolioval.svg' },
                    { to: '/portfolio-allocation-calculator', name: 'Portfolio Allocation', image: 'portallocation.svg' },
                    { to: '/inflation-bond-calculator', name: 'Inflation Bond Calculator', image: 'tipscal.svg' },
                    {
                        to: '/option-pricing-calculator',
                        name: 'Option Pricing Calculator',
                        image: 'optionpricing.svg'
                    },
                    { to: '/mutual-fund-etf-tool', name: 'Mutual Fund', image: 'mutualfunds.svg' },
                    { to: '/college-score-calculator', name: 'College score card', image: '' }
                ]
            },
            {
                title: 'Debt Calculators',
                image: 'debtcal.svg',
                children: [
                    { to: '/peer-to-peer-calculator', name: 'Peer To Peer Calculator', image: '' },
                    { to: '/student-loan-calculator', name: 'Student Loan Calculator', image: '' },
                    { to: '/debt-calculator', name: 'Debt Calculator', image: '' },
                    { to: '/credit-card-calculator', name: 'Credit Card Calculator', image: '' },
                    { to: '/mortgage-refinance-calculator', name: 'Mortgage Refinance Calculator', image: '' }
                ]
            },
            {
                title: 'Insurance',
                image: 'insurance.svg',
                children: [
                    { to: '/life-insurance-calculator', name: 'Life Insurance Calculator', image: '' },
                    { to: '/annuity-calculator', name: 'Annuity/Pension Analysis', image: '' }
                ]
            },
            {
                title: 'Real Estate',
                image: 'realestate.svg',
                children: [
                    { to: '/home-valuation-calculator', name: 'Home Valuation Tool', image: '' },
                    { to: '/home-purchase-tool', name: 'Home Purchase Tool', image: '' },
                    { to: '/house-affordability-calculator', name: 'House Affordibility Calculator', image: '' },
                    { to: '/property-investment-tool', name: 'Investment Property Calculator', image: '' }
                ]
            },
            {
                title: 'Retirement',
                image: 'retirement.svg',
                children: [
                    { to: '/retirement-calculator', name: 'Retirement', image: '' }
                    //    { to: '/comingSoon', name: ' Roth Conversion' ,image:""},
                    //    { to: '/comingSoon', name: 'Simulation',image:"" }
                ]
            },
            {
                title: 'FX Calculator',
                image: 'fxcal.svg',
                children: [{ to: '/fx-conversion-calculator', name: 'FX Conversion Calculator', image: '' }]
            }
        ]
    },
    {
        parent: 'Dashboards',
        children: [
            { to: '/loan-dashboard', name: 'Consumer Loans Rates', image: 'consumerloansicon.svg' },
            { to: '/equity-dashboard', name: 'Equity', image: 'equityicon.svg' },
            { to: '/fixed-income-dashboard', name: 'Fixed Income', image: 'fixedincome.svg' },
            //    { to: '/comingSoon', name: 'FX Cross Rate Dashboard', image: 'fxcrossrateicon.svg' },
            //    { to: '/comingSoon', name: 'FX Historical Dashboard', image: 'fxicon.svg' },
            { to: '/economic-dashboard', name: 'Economic Dashboard', image: 'economicicon.svg' }
            //    { to: '/comingSoon', name: 'Crypto Dashboard', image: 'cryptoicon.svg' }
            //    { to: '/loandashboard', name: 'Loan Dashboard', image: 'loanicon.svg' }
        ]
    },
    {
        parent: 'Finance task list',
        to: '/finance-task-list'
    },
    {
        parent: 'News & Research',
        to: '/knowledge-and-research/news'
    },
    //    {
    //        parent: 'Pricing',
    //        to: '/subscription'
    //    },
    //    {
    //        parent: 'Blog',
    //        to: '/blog'
    //    },
    {
        parent: 'About us',
        to: '/about-us'
    },
    {
        parent: 'Contact us',
        to: '/contact-us'
    }
];
