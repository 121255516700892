import React, { useState, useEffect } from 'react';
import './index.css';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';

const CustomScroll = ({ tabs, activeTab, handleTabClick, tooltipText }) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)' }}>
            <div
                style={{
                    cursor: 'pointer',
                    marginLeft: '20px',
                    marginRight: '20px',
                    visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                }}
                onClick={() => handleTabNavigation('backward')}
            >
                {' '}
                &lt;
            </div>
            <div
                style={{
                    overflowX: 'auto',
                    display: 'flex'
                }}
                className="scroll"
                onScroll={handleScroll}
            >
                {tabs.map((tab, index) => (
                    <div
                        id={tab.label.slice(0, 5)}
                        key={index}
                        style={{
                            flex: '0 0 auto',
                            padding: '10px 20px',
                            cursor: 'pointer',
                            borderBottom: '3px solid transparent',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                            color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                        }}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.label}
                        {tab.label === tooltipText && (
                            <CustomTooltip
                                tooltipText={tooltip.default?.['Rank of score of expenses']}
                                customTooltipStyle={{ maxWidth: 450 }}
                                label={'Rank of score of expenses'}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div
                style={{
                    cursor: 'pointer',
                    marginLeft: '20px',
                    marginRight: '20px',
                    visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                }}
                onClick={() => handleTabNavigation('forward')}
            >
                &gt;
            </div>
        </div>
    );
};
export default CustomScroll;
