import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";

/**
 * @author disha
 * @use get the all data for loan dashboard
 * @param {*} data
 * @returns
 */
export const fetchLoanDashBoardData = (simpleOutput=false) => async dispatch => {
  try {
    dispatch(fetchLoanDashBoardInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}loandashboard`,"get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchLoanDashBoardSuccess(apiResponse.data, simpleOutput));
    } else {
         handleError(apiResponse,dispatch);
      dispatch(fetchLoanDashBoardError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchLoanDashBoardError());
    throw error;
  }
};
// handle loader when api started to call
export const fetchLoanDashBoardInit = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_INIT,
  };
};
//handle success of api
export const fetchLoanDashBoardSuccess = (val,simpleOutput=false) => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_SUCCESS,
    data: val,
    simpleOutput,
  };
};

// handle api error
export const fetchLoanDashBoardError = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_ERROR,
  };
};



export const reloadLoanDashBoardData = (simpleOutput=false) => async dispatch => {
  try {
    dispatch(fetchLoanDashBoardInit());
    const apiResponse = await apiCall(
        `${Globals.API_ROOT_URL}loandashboard`,"put");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchLoanDashBoardSuccess(apiResponse.data, simpleOutput));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchLoanDashBoardError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchLoanDashBoardError());
    throw error;
  }
};


/**
 * @author Ulyana
 * @use get the all data for loan dashboard
 * @param {*} data
 * @returns
 */
export const fetchLoanDashBoardHistoricalData = (symbol, range) => async dispatch => {
  try {
    dispatch(fetchLoanDashBoardInit());
    const apiResponse = await apiCall(
        `${Globals.API_ROOT_URL}loan_dash_historical_data?symbol=${symbol}&historical_range=${range}`,"get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchLoanDashBoardHistoricalDataSuccess(apiResponse.data));
      return apiResponse?.data?.data
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchLoanDashBoardHistoricalDataError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchLoanDashBoardHistoricalDataError());
    throw error;
  }
};
// handle loader when api started to call
export const fetchLoanDashBoardHistoricalDataInit = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_INIT,
  };
};
//handle success of api
export const fetchLoanDashBoardHistoricalDataSuccess = (val,simpleOutput=false) => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_SUCCESS,
    data: val,
    simpleOutput,
  };
};

// handle api error
export const fetchLoanDashBoardHistoricalDataError = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_ERROR,
  };
};



export const fetchBankRateData = (symbol, range) => async dispatch => {
  try {
    dispatch(fetchLoanDashBoardInit());
    const apiResponse = await apiCall(
        `${Globals.API_ROOT_URL}bank_rate_historical_data?symbol=${symbol}&historical_range=${range}`,"get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchBankRateDataSuccess(apiResponse.data));
      return apiResponse?.data?.data
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchBankRateDataError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchBankRateDataError());
    throw error;
  }
};
// handle loader when api started to call
export const fetchBankRateDataInit = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_INIT,
  };
};
//handle success of api
export const fetchBankRateDataSuccess = (val,simpleOutput=false) => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_SUCCESS,
    data: val,
    simpleOutput,
  };
};

// handle api error
export const fetchBankRateDataError = () => {
  return {
    type: ActionTypes.FETCH_LOAN_DASHBOARD_HISTORICAL_DATA_ERROR,
  };
};

