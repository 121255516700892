
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Fade, Grid, Tooltip, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { CalculatorBox, RatingCard, RatingDiv } from 'assets/jss/CalculatorStyle';
import { DashGrid } from 'assets/jss/LandingStyle';
import moment from 'moment';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { limitLetters } from 'utils/Utils';
import ReadMoreRating from './ReadMoreRating';
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <IconButton aria-label="next arrow">
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <IconButton aria-label="prev arrow">
                <ArrowBackIosIcon />
            </IconButton>
        </div>
    );
}

const arrdata = [
    {name: 'Milos Marunic',rate: '4',time: '7/12/2022 3:24:56',message: `Calculators were the best feature`,},
    {name: 'Ritu Bhutani',rate: '4',time: '7/13/2022 9:19:32',message: `I like it as one platform handling different financial need like loan calculation, pension etc.Easy to understand , data loading is fast. Calculator  to get the financial goal is helpful. To-do tasks options and progress of task management is quiet good and useful feature. The most impressive feature added to the site is chatbot. speech feature is quiet good as sometimes we are not in mood to type and read so this feature is quiet helpful.`},
    {name: 'Dominik Greda',rate: '5',time: '8/1/2022 6:45:56',message: `I like that all the useful tools are gathered on one page.`,},
    {name: 'Ameet Thakkar',rate: '4',time: '8/1/2022 7:31:56',message: `Everything in a single application related to finances, knowledge on investment, savings, and many more.`,},
    {name: 'Irene Babayan',rate: '3',time: '8/1/2022 22:22:33',message: `The pages on the website has nice design, it's pretty clear what to expect from each menu and each page.`,},
    {name: 'Ravi Vinjavarapu',rate: '4',time: '8/2/2022 2:36:40',message: `The most import about TPE is rich functionality of different finance tools and all tools are working fine. user can easy to navigate and use them at ease.`,},
    {name: 'Srikrishna Bharali',rate: '4',time: '8/3/2022 1:11:29',message: `TPE calculator is easy way to Calculate cost and makes plan based on need.Calculator is so easy to user and calculate.All details under site.The site design is so simple and easy to use.`,},
    {name: 'Patrick Stone',rate: '3',time: '8/3/2022 6:54:49',message: `I like that TPE is able to generate ideas for budgeting and makes it easy for the user to set lists for them to do this for themselves. It has useful information that is available very quickly and everything is accessible through the main page, which is really good.`,},
    {name: 'Dario Miocic',rate: '4',time: '8/3/2022 11:19:46',message: `The page looks nice and easy to  use. it has a lot of financial options and calculators, dashboards, ...`,},
    {name: 'Aleksandra Marunic',rate: '4',time: '8/3/2022 14:28:21',message: `The idea of the app and simplicity to use it`,},
    {name: 'Irena Dautovic',rate: '4',time: '8/3/2022 16:45:16',message: `Easy to use app without too many unnecessary options `,}
];
const RatingBlock=()=> {
        const settings = {
            // infinite: true,
            // slidesToShow: 6.5,
            centerMode: true,
            // vertical: false,
            // infinite: true,
            // slidesToShow: arrdata.length <6 ? arrdata.length:6,
            // centerMode: true,
            // vertical: false,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: arrdata.length < 3 ? arrdata.length : 3,
                        slidesToScroll: 3,
                        infinite: true
                        // dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        infinite: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <CalculatorBox disablemargin={'true'}>
                <Box className="title-box">
                    <Typography variant="h3" sx={{ mt: 3.5, display: 'inline-block' }} id="review">
                        Customer Reviews
                    </Typography>
                    {/* <Typography variant="subheading" className="dashboard-subheading" sx={{ mb: 1.5, display: 'block' }}>
                        4 ratings of 11 reviews
                    </Typography> */}
                </Box>
                <Grid container spacing={2} className="grid-container">
                    <DashGrid item xs={12} disablepadd={'true'}>
                        <Slider {...settings} className="rating-slider">
                            {arrdata.map((val, i) => {
                                return (
                                    <RatingCard
                                        elevation={3}
                                        key={i + 'cal-parent-list'}
                                        // onClick={(e) => handleCardSelection(e, val, 'bottom-start')}
                                        // className={`${val.title === openCard?.title && 'selected'}`}
                                    >
                                        <Avatar className="rating-avatar" alt="user" src={'img/user.png'} sx={{ width: 56, height: 56 }} />
                                        <CardContent>
                                            <Typography variant="h6"> {val.name}</Typography>
                                            {/* <Typography gutterBottom variant="h5" component="div" className="blog-date" sx={{ mb: 2 }}> */}
                                            {/* <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                title={moment(val.time).format('MM/DD/YYYY HH:mm:ss')}
                                            >
                                                <Typography gutterBottom variant="cardHeader" component="div" sx={{ mb: 2 }}>
                                                    {moment(val.time, 'MM/DD/YYYY h:mm:ss').fromNow()}
                                                </Typography>
                                            </Tooltip>{' '} */}
                                            <Typography
                                                variant="body1"
                                                className="dashboard-subheading rating-msg1"
                                                sx={{ mb: 1.5, display: 'block' }}
                                            >
                                                {limitLetters(val.message, 65)}
                                            </Typography>
                                            {limitLetters(val.message, 65, true) === 'same' ? '' : <ReadMoreRating data={val} />}
                                            <Box className="rating-footer">
                                                <RatingDiv name="simple-controlled" value={val.rate} />
                                                {/* <FacebookRoundedIcon /> */}
                                            </Box>
                                        </CardContent>
                                    </RatingCard>
                                );
                            })}
                        </Slider>
                    </DashGrid>
                </Grid>
            </CalculatorBox>
        );
}
export default RatingBlock;
