import { Box, Button, Grid, Typography, Card, Rating, Badge, Paper } from '@mui/material';
import Popper from '@mui/material/Popper';

import { styled } from '@mui/styles';
export const CalculatorBox = styled(Box)(({ textalign, disablemargin, theme }) => {
    return {
        minHeight: 442,
        // marginBottom: 100,
        textAlign: textalign || 'center',
        '& .title-box': {
            marginLeft: disablemargin === 'true' ? 0 : '200px',
            marginBottom: 24,
            [theme.breakpoints.down('md')]: {
                margin: '0 0 0 21px'
            }
        },
        '& .dashboard-subheading': {
            fontWeight: 500,
            textTransform: 'capitalize',
            lineHeight: '25px',
            display: 'block'
        },
        '& .slick-slide': {
            marginRight: 26
        },
        '& .slick-next': {
            right: 10
        },
        '& .sub-popper': {
            marginTop: '80px !important',
            width: '100%'
        },
        '&.calBlock .grid-container ': {
            justifyContent: 'space-between',
            padding: '0 16px 16px'
        }
    };
});

export const CalPopper = styled(Popper)(({ theme }) => ({
    //    marginTop:"50px !important"
}));
export const ComparisonPaper = styled(Paper)(({ theme }) => ({
    marginTop: 10,
    '& .MuiTableContainer-root': {
        overflowX: 'initial'
    },
    '& .MuiTable-root': {
        display: 'table'
    }
}));
export const CalCard = styled(Card)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey['400']}`,
    textAlign: 'center',
    padding: 8,
    minHeight: '-webkit-fill-available',
    cursor: 'pointer',
    '& .MuiCardContent-root': {
        padding: 0
    },
    '&:hover,&.selected': {
        boxShadow: '1.04499px 1.04499px 4.70244px rgb(0 0 0 / 25%)',
        padding: '8px'
    }
}));
export const RatingCard = styled(Card)(({ theme }) => ({
    height: 295,
    minHeight: '100%',
    overflow: 'visible',
    position: 'relative',
    textAlign: 'left',
    '& .MuiCardContent-root': {
        padding: '45px 20px 9px'
    },
    '& .rating-avatar': {
        position: 'absolute',
        top: '46%',
        left: '50%',
        marginTop: '-154px',
        marginLeft: '-32px',
        overflow: 'scroll'
    },
    '& .rating-msg': {
        display: '-webkit-box',
        maxWidth: '200px',
        '-webkit-line-clamp': '4',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    },
    '& .rating-footer': {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));
export const CalBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    img: {
        position: 'absolute',
        top: 50
    },
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        display: 'none'
    }
}));
export const RatingDiv = styled(Rating)(({ theme }) => ({
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
    '& .MuiRating-root': {
        color: '#ffb400',
        cursor: 'pointer',
        display: 'inline-flex',
        position: 'relative',
        textAlign: 'left',
        WebkitTapHighlightColor: 'transparent'
    },
    '& label': {
        fontSize: '1.5rem',
        color: 'unset'
    }
}));
export const AnnualInputWrapperGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    '& .MuiFormControl-root': {
        paddingTop: 6,
        width: '8rem'
    },
    '& .actionWrapperAnnual': {
        paddingTop: 6
    }
}));
export const BreadcrumbsTypography = styled(Typography)(({ theme }) => ({
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}));
export const StyledBadge = styled(Badge)(({ theme }) => {
    return {
        right: -13,
        top: 11,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    };
});
export const StyledPeerToPeerBadge = styled(Badge)(({ theme, color }) => {
    return {
        '& .MuiBadge-badge': {
            marginRight: '3px',
            borderRadius: '12px',
            padding: '5px 12px',
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: '500',
            lineHeight: '1',
            color: '#fff',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            display: 'inline-block'
        }
    };
});
export const MutualFundSearchPaginationBox = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    };
});
