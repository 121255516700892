import React from 'react';
import CookieConsent from 'react-cookie-consent';

function Cookie() {
    // const [open, setOpen] = useState(false);

    // useEffect(() => {
    //     const accept_cookie = getCookie('accept_cookie');
    //     if (accept_cookie) {
    //         setOpen(false);
    //     } else {
    //         setOpen(true);
    //     }
    // }, []);
    // const handleClose = () => {
    //     setCookie('accept_cookie', false);
    //     setOpen(false);
    // };
    // const handleAccept = () => {
    //     setCookie('accept_cookie', true);
    //     setOpen(false);
    // };

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            onAccept={() => {
                // logic to enable cookies
            }}
            onDecline={() => {
                // logic to disable cookies
            }}
            style={{ width: '93%' }}
        >
            Our website uses cookies to improve your experience and to comply with GDPR and CCPA regulations. By continuing to use this
            website, you consent to the use of cookies in accordance with our cookies policy. <a href="/privacy-policy">Read More...</a>
        </CookieConsent>
        // <div>
        //     <Dialog open={open} onClose={handleClose} aria-labelledby="cookies-popup-title">
        //         <CustomDialogTitle id="cookies-popup-title" onClose={handleClose}>
        //             Cookies Policy And Disclaimer
        //         </CustomDialogTitle>
        //         <DialogContent>
        //             <DialogContentText>
        //                 Our website uses cookies to improve your experience and to comply with GDPR and CCPA regulations. By continuing to
        //                 use this website, you consent to the use of cookies in accordance with our cookies policy. You can change your
        //                 cookie settings at any time by adjusting your browser settings.
        //             </DialogContentText>
        //             <DialogContentText mt={1}>
        //                 The tools (calculators and dashboards) provided on The People’s Economist (TPE) website are for informational
        //                 purposes only. It is not intended to be a substitute for professional financial advice. The tools should not be used
        //                 as the sole basis for making financial decisions. You should always consult with a qualified financial advisor
        //                 before making any financial decisions. TPE makes no representations or warranties, express or implied, as to the
        //                 accuracy, completeness, or suitability of the information contained herein. TPE shall not be liable for any errors
        //                 or omissions in the information contained herein or for any damages arising from the use of this information. For
        //                 more complete disclaimer see the footer.
        //             </DialogContentText>
        //         </DialogContent>
        //         <DialogActions>
        //             <CustomButton onClick={handleAccept} variant="contained">
        //                 Accept
        //             </CustomButton>
        //         </DialogActions>
        //     </Dialog>
        // </div>
    );
}

export default Cookie;
