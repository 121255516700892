import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    recurringCalData: {},
    recurringFileList: [],
    selectedRecurringDetail: {},
    submitSmartTblSuccess: false,
    submitSuccess: false,
    loader: false,
    savedRecurringData: [],
    recurringCategory: []
};
export const RecurringReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_RECURRING_INIT:
        case ActionTypes.UPLOAD_RECURRING_FILE_INIT:
        case ActionTypes.GET_RECURRING_FILE_INIT:
        case ActionTypes.DELETE_RECURRING_FILE_INIT:
        case ActionTypes.FETCH_SELECTED_RECURRING_FILE_INIT:
        case ActionTypes.SMART_RECURRING_INIT:
        case ActionTypes.GET_RECURRING_CATEGORY_INIT:
            return { ...state, loader: true };

        case ActionTypes.SUBMIT_RECURRING_ERROR:
        case ActionTypes.UPLOAD_RECURRING_FILE_ERROR:
        case ActionTypes.GET_RECURRING_FILE_ERROR:
        case ActionTypes.UPLOAD_RECURRING_FILE_SUCCESS:
        case ActionTypes.DELETE_RECURRING_FILE_ERROR:
        case ActionTypes.FETCH_SELECTED_RECURRING_FILE_ERROR:
        case ActionTypes.DELETE_RECURRING_FILE_SUCCESS:
        case ActionTypes.GET_RECURRING_CATEGORY_ERROR:
            return { ...state, loader: false };

        case ActionTypes.SUBMIT_RECURRING_SUCCESS:
            return { ...state, loader: false, recurringCalData: action.data, submitSuccess: action.data };
        case ActionTypes.GET_RECURRING_FILE_SUCCESS:
            return { ...state, loader: false, recurringFileList: action.data };
        case ActionTypes.FETCH_SELECTED_RECURRING_FILE_SUCCESS:
            return { ...state, loader: false, selectedRecurringDetail: action.data };
        case ActionTypes.SMART_RECURRING_SUCCESS:
            return { ...state, loader: false, submitSmartTblSuccess: true };
        case ActionTypes.SMART_RECURRING_ERROR:
            return { ...state, loader: false, submitSmartTblSuccess: false };
        case ActionTypes.GET_RECURRING_CATEGORY_SUCCESS:
            return { ...state, loader: false, recurringCategory: action.data };
        default:
            return state;
    }
};
