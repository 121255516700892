import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AppBar, Grid, Paper, Tab, Tabs, Typography, createStyles, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonCalculator from 'components/CommonComponent/CommonCalculator';

import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';

import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import CustomScroll from 'components/CustomScroll';
import Meta from 'components/meta';
import NewsCategory from './NewsCategory';
import { getNewsFeed } from 'redux/actions/SettingActions';
import { Helmet } from 'react-helmet';
import Loader from 'components/CommonComponent/Loader';

const fields = [
    { id: 'news', name: 'News' },
    { id: 'blogs', name: 'Articles' }
];

const NewsFeed = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const [categorizeBlogs, setCategorizeBlogs] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const { newsList, loader } = useSelector((state) => state.setting);
    const typographyRef = useRef(null);

    useEffect(() => {
        if (activeTab === 0) {
            navigate('/knowledge-and-research/news');
        } else if (activeTab === 1) {
            navigate('/knowledge-and-research/blog');
        }
    }, [activeTab]);

    useEffect(() => {
        /// to fetch the default news feed values on page load
        dispatch(getNewsFeed('', 100, true));
    }, []);

    useEffect(() => {
        const arr = [];
        newsList.news &&
            newsList.news.forEach((val) => {
                if (val?.is_published) {
                    arr.push(val);
                }
            });
        const ModifiedObj = { News: arr };

        setCategorizeBlogs(ModifiedObj);
    }, [newsList]);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText
                // style={{ cursor: 'pointer' }}
                // onClick={() => navigate('/knowledge-and-research')}
                primary={'News & Research'}
            />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            News Feed
        </Typography>
    ];

    const tabs = fields?.map((category) => {
        return { label: category?.name };
    });

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    return (
        <main>
            <Helmet>
                <title>News Feed | TPE Hub</title>
                <meta
                    name="description"
                    content="Explore comprehensive business news, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                />
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="News Feed | TPE Hub" />
                <meta
                    property="og:description"
                    content="Explore comprehensive business news, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                />
                <meta
                    property="og:image"
                    content="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6z9o-X7Ay_6TKelMmo_R1Ps6pbytlhs4n9Q&s/600x400"
                />
                <meta property="og:url" content={window.location.href} />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="News Feed | TPE Hub" />
                <meta
                    name="twitter:description"
                    content="Explore comprehensive business news, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                />
                <meta
                    name="twitter:image"
                    content="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6z9o-X7Ay_6TKelMmo_R1Ps6pbytlhs4n9Q&s/600x400"
                />
            </Helmet>
            {/* <Meta
                title="News Feed | TPE Hub"
                description="Explore comprehensive business news, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                page="/knowledge-and-research/news"
            /> */}
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="News Feed" />
            <MainBox>
                <LargeContainer maxWidth="xl">
                    {/* <AccessControl allowedPermissions={['todo']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}> */}
                    <Paper elevation={0}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <AppBar position="static" color="default">
                                    <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
                                </AppBar>

                                {fields?.map((category, index) => {
                                    return (
                                        <PortfolioTabPanel value={value} index={index} dir={theme.direction}>
                                            {loader ? <Loader /> : ''}
                                            <NewsCategory
                                                newsCategory={'News'}
                                                newsList={categorizeBlogs[category.name]}
                                                latestNews={newsList.latest_news}
                                                dailyNews={newsList.daily_news}
                                            />
                                        </PortfolioTabPanel>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Paper>
                </LargeContainer>
            </MainBox>
        </main>
    );
};

export default NewsFeed;
