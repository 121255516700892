import React from 'react';
import { Button, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '48rem',
    margin: '0',
    padding: '0',
  },
  card: {
    padding: '0',
    marginBottom: '0',
  },
  buttonContainer: {
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '0.25rem',
    
    '&::-webkit-scrollbar': {
      height: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  button: { 
    padding: "3px",
    flexShrink: 0,
    marginRight: '0.5rem',
    backgroundColor: 'transparent',
    borderRadius: '1rem',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
});

const QueryPanel = ({ onSubmit, inputRef, interactionType, documentType }) => {
  const classes = useStyles();

  const buttons = {
    'budget': [
      "Largest expenses last 3 months?",
      "Top 3 categories last 3 months?",
      "Largest vendor last month?",
      "Top 5 recurring payments last 3 months?",
      "Budget trend last 6 months?",
      "Average spending last 3 months?",
      "Month with highest spending last 12 months?"
    ],
    'portfolio': [
        "Biggest winners and losers yesterday?",
        "5 best and worst performers?",
        "Total market value of the portfolio?",
        "Total gain/loss of the portfolio?",
        "Top 5 sectors?"
      ] 
  };

  return (
    <div className={classes.container}>
      <Card key={documentType} className={classes.card}>
          <div className={classes.buttonContainer}>
          {(buttons[documentType] || []).map((item) => (
              <Button
                key={item}
                variant="contained"
                className={classes.button}
                onClick={(e) => {
                  interactionType.current = 'faq';
                  inputRef.current.value = item;
                  onSubmit(e);
                  inputRef.current.focus();
                  interactionType.current = 'ai';
                }}
                style={{ 
                  color: '#4a4a4a', 
                  fontWeight: 'normal', 
                  fontSize: '0.800rem', 
                  padding: "4px",
                }}
              >
                {item}
              </Button>
          ))}
          </div>
      </Card>
    </div>
  );
};

export default QueryPanel;