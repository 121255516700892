import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
import { debtCalculator } from "Debt.json"

// handle loader when api started to call
export const submitDebtCalInit = () => {
  return {
    type: ActionTypes.SUBMIT_DEBT_CAL_INIT
  };
};
//handle success of api
export const submitDebtCalSuccess = val => {
  return {
    type: ActionTypes.SUBMIT_DEBT_CAL_SUCCESS,
    data: val
  };
};
// handle api error
export const submitDebtCalError = () => {
  return {
    type: ActionTypes.SUBMIT_DEBT_CAL_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to convert currency
 * @returns
 */
export const submitDebtCal = (data, type) => async dispatch => {
  try {
    dispatch(submitDebtCalInit());
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}debtcalculator`, type, data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(submitDebtCalSuccess());
      dispatch(fetchDebtCal());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitDebtCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitDebtCalError());
    throw error;
  }
};

//handle success of api
export const fetchDebtCalSuccess = val => {
  return {
    type: ActionTypes.FETCH_DEBT_CAL_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchDebtCalError = () => {
  return {
    type: ActionTypes.FETCH_DEBT_CAL_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to convert currency
 * @returns
 */
export const fetchDebtCal = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_DEBT_CAL_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}debtcalculator`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchDebtCalSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchDebtCalSuccess(debtCalculator));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchDebtCalError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchDebtCalError());
    throw error;
  }
};

export const fetchIndexTypeValue = (type) => async dispatch => {
  try {
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}indexdebtcalc?index_type=${type}`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      return apiResponse.data.data
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchDebtCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchDebtCalError());
    throw error;
  }
};


export const resetEditTableField = val => {
  return {
    type: ActionTypes.RESET_EDIT_TABLE_FIELD,
    data: val
  }
}

//handle success of api
export const viewDebtCalSuccess = (val) => {
  return {
    type: ActionTypes.VIEW_DEBT_SUCCESS,
    data: val
  };
};
// handle api error
export const viewDebtCalError = () => {
  return {
    type: ActionTypes.VIEW_DEBT_ERROR
  };
};

export const viewDebtCalulator = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.VIEW_DEBT_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}debt_loan_dashboard_view`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(viewDebtCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(viewDebtCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(viewDebtCalError());
    throw error;
  }
};