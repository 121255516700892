import { Card, CardContent, Dialog, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSubcriptions, getTpeModule, submitSubscription, submitSubscriptionSuccess } from "redux/actions/SettingActions";
import RenderField from "utils/RenderField";
import { numberWithCommas } from "utils/Utils";
import { CustomButton } from "assets/jss/ButtonStyle";
import CustomDialogTitle from "components/DialogTitle";

// to create option for level field
const levelOption = [...Array(10)].map((e, i) => { const val = ++i; return ({ "value": val, "label": val }) });
const fieldsDetail = [
  { name: "name", fieldName: "Subscription Name",fieldLabel:"Subscription Name *", type: 'text' ,placeholder:'Enter Subscription Name' ,custompattern:'validString'},
  { name: "price_monthly", fieldName: "Monthly Price",fieldLabel:"Monthly Price *", type: 'number',  placeholder: 'Enter Monthly Price',custompattern:'decimalRate'},
  { name: "price_yearly", fieldName: "Yearly Price",fieldLabel:"Yearly Price *", type: 'number',  placeholder: 'Enter Yearly Price',custompattern:'decimalRate'},
  { name: "payment_link_monthly", fieldName: "Monthly Payment Link",fieldLabel:"Monthly Payment Link *", type: 'text' ,placeholder:'Enter Payment Link'},
  { name: "payment_link_yearly", fieldName: "Yearly Payment Link",fieldLabel:"Yearly Payment Link *", type: 'text' ,placeholder:'Enter Payment Link'},
  { name: "stripe_price_id_monthly", fieldName: "Stripe Monthly Price Id",fieldLabel:"Stripe  Monthly Price Id *", type: 'text' ,placeholder:'Enter Monthly Price Id'},
  { name: "stripe_price_id_yearly", fieldName: "Stripe Yearly Price Id",fieldLabel:"Stripe Yearly Price Id *", type: 'text' ,placeholder:'Enter Yearly Price Id'},
  { name: "level", fieldName: "Priority Level",fieldLabel:"Priority Level *", type: 'select',  placeholder: 'Enter Price',options:levelOption},
  { name: "modules", fieldName: "Permissions",fieldLabel:"Permissions", type: 'select', required:false ,chipSelect:true},
  { name: "planIncludes", fieldName: "Plan Includes",fieldLabel:"Plan Includes *", type: 'text',placeholder:"Enter Plan Labels" },
];

const SubscriptionSetting = (props) => {
  const { classes, setting } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fieldIndex, setIndexes] = useState([0]);
  const [counter, setCounter] = useState(0);
  const [moduleColl, setModuleColl] = React.useState([]);
  const {
    handleSubmit,
    control, reset, formState: { isSubmitting },
    setValue,
  } = useForm({
    mode: "onChange"
  });
  //create option format for permission
  const permissionOptions = (data = null) => {
    let objOptions = {};
    let subArr = data || setting.moduleList;
    subArr.forEach(val => objOptions = { ...objOptions, [val.id]: val.name })
    return objOptions;
  }
  // to increase the count of dynamic field
  const addNewField = () => {
    const newCounter = counter + 1;
    setIndexes((prevIndexes) => [...prevIndexes, newCounter]);
    setCounter((prevCounter) => prevCounter + 1);
  };
  // to decrease the count of dynamic field
  const removeField = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
  };
  // submit the form
  const onSubmit = (data) => {
    let type = 'post';
    if (data.level) {
      const isLevelDubplicate = setting.subscriptionList.some(val => val.level === data.level && data.id !== val.id);
      if (isLevelDubplicate) {
        return toast.error("Selected level is already assigned to other subscription plan.");
      }
    }
    const subFieldNames = ["planIncludes"];
    let finalSubFieldValues = [];
    fieldIndex.filter((fieldIndex) => {
      return subFieldNames.filter((fieldName) => {
        return finalSubFieldValues.push(data[fieldName + "_" + fieldIndex]);

      });
    });
    let objToPass = {
      price: data.priceUnFormattedValue || data.price,
      price_monthly: data.price_monthlyUnFormattedValue || data.price_monthly,
      price_yearly: data.price_yearlyUnFormattedValue || data.price_yearly,
      payment_link_monthly: data.payment_link_monthly,
      payment_link_yearly: data.payment_link_yearly,
      stripe_price_id_monthly: data.stripe_price_id_monthly,
      stripe_price_id_yearly: data.stripe_price_id_yearly,
      name: data.name,
      level: data.level,
      plan_include: finalSubFieldValues || undefined,
      mod_id: data.modules || undefined,
    };
    if (data.id) {
      objToPass = { ...objToPass, "sub_id": data.id }
      type = 'put';
    }
    dispatch(submitSubscription(objToPass, type))

  }
  useEffect(() => {
    // to close opened popup after submittion of api
    if (setting.subscriptionFormDialog) {
      handleClose();
      dispatch(submitSubscriptionSuccess(false));
    }
  }, [setting.subscriptionFormDialog]);

  useEffect(() => {
    //to create array of column to pass in table
    if (setting.subscriptionList.length > 0) {
      const ignoreFields = ["id"];
      let column = [];
      Object.keys(setting.subscriptionList[0]).forEach(keys => {
        if (!ignoreFields.includes(keys)) {
          const formattedKey = keys.replaceAll(/_/g, ' ')
          column.push({
            Header: (keys === "modules" && "Permissions") || formattedKey || keys,
            accessor: keys,
            width: (keys === 'level' && 80) || 150,
            disableFilters: true,
            Cell: ({ cell, value }) => {
              if (cell.column.id === 'modules') {
                const valToPass = value.map((moduleval) => <p className="breakWordCell">{moduleval.name}</p>);
                if (valToPass.length > 5) {
                  const trimmedText = valToPass.slice(0, 5);
                  trimmedText.push(<p>...</p>);
                  return (
                    <CustomTooltip
                      tooltipText={valToPass}
                      textTooltip={false}
                      mannualTrimText={trimmedText}
                      align='left'
                    />
                  );
                } else {
                  return valToPass;
                }
              }
              if (typeof value === "object" && value?.length > 0) {
                let lines = value?.join(",").split(",");
                lines = lines.map((line) => <p>{line}</p>);
                if (lines.length > 5) {
                  const trimmedText = lines.slice(0, 5);
                  trimmedText.push(<p>...</p>);
                  return (
                    <CustomTooltip
                      tooltipText={lines}
                      textTooltip={false}
                      mannualTrimText={trimmedText}
                      align='left'
                    />
                  );
                } else {
                  return lines;
                }

              }
              return (!isNaN(value) && value?.toString().indexOf(".") !== -1
                ? numberWithCommas(value?.toFixed(2))
                : numberWithCommas(value)) || value ||
                "-";
            }
          });
        }
      });
      column.unshift({
        Header: "Action",
        width: 120,
        Cell: ({ row }) => (
          <div className={"actionWrapper"}>
            <Tooltip title='Delete'>
              <IconButton
                aria-label='delete'
                onClick={() => deleteSelectedData(row.original.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                aria-label='edit'
                onClick={(e) => handleEditData(row.original)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      });
      setModuleColl(column);
    }
  }, [setting.subscriptionList]);
  useEffect(() => {
    /// to fetch the default permission list and subscription list values on page load
    dispatch(getTpeModule())
    dispatch(getSubcriptions())
  }, []);

  const handleClickOpen = () => setOpen(true);
  // delete selected subscription plan
  const deleteSelectedData = id => dispatch(submitSubscription({ 'sub_id': id }, 'delete'));
  // enable to edit subscription plan
  const handleEditData = async (val) => {
    setOpen(true);
    setIndexes([0]);
    // to load saved data in form
    for (const key in val) {
      if (Object.hasOwnProperty.call(val, key)) {
        let element = val[key];
        if (key === 'modules') {
          const opt = permissionOptions(element);
          const modids = Object.keys(opt);
          setValue(key, modids);
          continue;
        }
        if (key === "plan_include") {
          let count = 0;
          let arr = [0];
          element.forEach((subval, i) => {
            if (i > 0) {
              arr = [...arr, i];
              count++;
            }
            setValue(`planIncludes_${i}`, subval);
          });
          setIndexes(arr);
          setCounter(count);
          continue;
        }

        setValue(key, element);
      }
    }
  };
  //close popup
  const handleClose = () => {
    const resetObj = fieldsDetail.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
    reset(resetObj || {});
    setIndexes([0])
    setCounter(0)
    setOpen(false);
  }
  return (
      <>
          <Card elevation={2}>
              <CardContent>
                  <Tooltip title="Add" arrow>
                      <CustomButton variant="text" className="read-more" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                          Add New Data
                      </CustomButton>
                  </Tooltip>
                  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                      <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                          Add New Subscription Plan
                      </CustomDialogTitle>
                      <Divider />
                      <CustomDialogTitle>
                          <form onSubmit={handleSubmit(onSubmit)}>
                              <Grid container>
                                  {fieldsDetail.map((val, i) => {
                                      const optionsList = val.name === 'modules' ? permissionOptions() : val.options;
                                      if (val.name === 'planIncludes') {
                                          return (
                                              <Fragment key={'planIncludeParent_' + i}>
                                                  <Grid item xs={12} sm={4}>
                                                      <Typography variant="body2" component="p" className="outputLabel">
                                                          {val.fieldLabel}
                                                      </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={8}>
                                                      {fieldIndex.map((index) => {
                                                          return (
                                                              <React.Fragment key={'planIncludeDynamic' + index}>
                                                                  <Grid container alignItems="baseline">
                                                                      <Grid
                                                                          item
                                                                          xs={12}
                                                                          className={classes.multiFieldWrapper}
                                                                          style={{ display: 'flex' }}
                                                                      >
                                                                          <RenderField
                                                                              name={`planIncludes_${index}`}
                                                                              control={control}
                                                                              id={`planIncludes_${index}`}
                                                                              required
                                                                              fieldName={val.fieldName}
                                                                              placeholder="Plan Title"
                                                                              type="text"
                                                                              customPatternName={/^([a-z0-9A-Z ]{1,50})$/g}
                                                                              setValue={setValue}
                                                                          />
                                                                          {fieldIndex.length - 1 === fieldIndex.indexOf(index) ? (
                                                                              <IconButton className="addIcon" onClick={addNewField}>
                                                                                  <AddCircleIcon />
                                                                              </IconButton>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                          {fieldIndex.length > 1 ? (
                                                                              <IconButton
                                                                                  className="removeIcon"
                                                                                  onClick={removeField(index)}
                                                                              >
                                                                                  <RemoveCircleIcon />
                                                                              </IconButton>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                      </Grid>
                                                                  </Grid>
                                                              </React.Fragment>
                                                          );
                                                      })}
                                                  </Grid>
                                              </Fragment>
                                          );
                                      } else {
                                          return (
                                              <Fragment key={i + '_subscriptionSettingfields'}>
                                                  <Grid item xs={12} sm={4}>
                                                      <Typography variant="body2" component="p" className="outputLabel">
                                                          {val.fieldLabel}
                                                      </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={8}>
                                                      <RenderField
                                                          name={val.name}
                                                          control={control}
                                                          label={''}
                                                          disableEmptyValue={true}
                                                          fieldName={val.fieldName}
                                                          id={val.name}
                                                          timeFormat={false}
                                                          type={val.type}
                                                          options={optionsList}
                                                          required={val.required || true}
                                                          customPatternName={val.custompattern}
                                                          placeholder={val.placeholder}
                                                          setValue={setValue}
                                                          className={(val.name === 'modules' && classes.moduleSelect) || ''}
                                                          {...val}
                                                      />
                                                  </Grid>
                                              </Fragment>
                                          );
                                      }
                                  })}
                              </Grid>
                              <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                  <Grid item xs={12}>
                                      <CustomButton type="submit" variant="contained"  disabled={isSubmitting}>
                                          Submit
                                      </CustomButton>
                                  </Grid>
                              </Grid>
                          </form>
                      </CustomDialogTitle>
                  </Dialog>
                  {setting.subscriptionList.length > 0 ? (
                      <Card className={classes.subscriptionTable}>
                          <EnhancedTable
                              enableSearch={false}
                              columns={moduleColl}
                              data={setting.subscriptionList || []}
                              enableSort={true}
                              enablePagination={false}
                          />
                      </Card>
                  ) : (
                      <NoDataFound msg="Result will be shown after adding subscription plan." />
                  )}
              </CardContent>
          </Card>
      </>
  );
};

export default SubscriptionSetting;
