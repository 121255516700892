import React, { useEffect } from 'react';
import { Box, Grid, Typography, Card, CardMedia, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/system';
import { dangerBoxShadow } from 'assets/jss/material-kit-react';

// Define custom styles for the Carousel arrows
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)'
        }
    },
    '& .MuiButtonBase-root:focus': {
        outline: 'none'
    }
}));

const NewsFeedTileView = ({ newsList, latestNews, dailyNews }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const sortedNewsArray = latestNews?.sort((a, b) => new Date(b['Created At']) - new Date(a['Created At']));

    // Helper function to split news data into chunks of 11
    const chunkArray = (array) => {
        const result = [];
        let chunkLength = 8;
        for (let i = 0; i < array?.length; i += chunkLength) {
            if (i === 0) {
                const q = array.slice(i, i + chunkLength);
                q.splice(3, 0, '', '', '');
                result.push(q);
            } else {
                if (chunkLength === 8) {
                    chunkLength = 11;
                }
                result.push(array.slice(i, i + chunkLength));
            }
        }
        return result;
    };

    const newsChunks = chunkArray(newsList || []);

    useEffect(() => {
        localStorage.setItem('myNews', JSON.stringify(newsList));
        localStorage.setItem('latestNews', JSON.stringify(latestNews));
        localStorage.setItem('dailyNews', JSON.stringify(dailyNews));
    }, [newsList?.length, latestNews?.length]);

    const limitedText = (val) => {
        if (val?.length > 80) {
            return val.slice(0, 80) + '...';
        } else {
            return val;
        }
    };

    const timeAgo = (dateString) => {
        if (!dateString) {
            return 'UNKNOWN TIME';
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'UNKNOWN TIME';
        }

        const now = new Date();
        const secondsAgo = Math.floor((now - date) / 1000);

        if (secondsAgo < 60) {
            return `${secondsAgo} SEC AGO`;
        } else if (secondsAgo < 3600) {
            const minutesAgo = Math.floor(secondsAgo / 60);
            return `${minutesAgo} MIN AGO`;
        } else if (secondsAgo < 86400) {
            const hoursAgo = Math.floor(secondsAgo / 3600);
            return `${hoursAgo} HOUR${hoursAgo > 1 ? 'S' : ''} AGO`;
        } else {
            const daysAgo = Math.floor(secondsAgo / 86400);
            return `${daysAgo} DAY${daysAgo > 1 ? 'S' : ''} AGO`;
        }
    };
    const renderNewsLayout = (dataChunk, index) =>
        !isMobile ? (
            <>
                {index === 0 && dailyNews && (
                    <>
                        <Typography
                            variant="h1"
                            sx={{ backgroundColor: 'white', position: 'sticky', top: '0px', color: 'black', fontSize: '1.5rem' }}
                        >
                            Daily News Updates
                        </Typography>
                        <Grid container spacing={2}>
                            {dailyNews.map((news, index) => (
                                <Grid item xs={12 / dailyNews.length} key={index}>
                                    <a
                                        href={`/knowledge-and-research/news/${news?.id
                                            ?.toLowerCase()
                                            .replace(/\s+/g, '-')
                                            .replace(/[^a-z0-9\-]/g, '')}`}
                                        target="_blank"
                                    >
                                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <CardMedia
                                                component="img"
                                                image={
                                                    news?.Type === 'daily-updates-treasury'
                                                        ? '/img/treasury-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-corporate'
                                                        ? '/img/corporate-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-econ'
                                                        ? '/img/econ-market.jpg'
                                                        : news.Image || '/img/logo.png'
                                                }
                                                alt={news?.Headline}
                                                sx={{ height: '200px', objectFit: 'cover' }}
                                            />
                                            <Box sx={{ padding: '10px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {limitedText(news?.Headline)}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        marginTop: '8px',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['News Source']}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['Created At']}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {news?.Content?.length > 60 ? news?.Content.slice(0, 60) + '...' : news?.Content}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </a>
                                </Grid>
                            ))}
                        </Grid>
                        <hr />
                    </>
                )}
                {/* Main Carousel (0th to 2nd) */}
                <Grid container spacing={2} sx={{ height: '100%', marginTop: '13px', justifyContent: 'space-between' }}>
                    <Grid item xs={6.8} sx={{ marginRight: '5px' }}>
                        <StyledCarousel indicators={false} navButtonsAlwaysVisible>
                            {dataChunk.slice(0, Math.min(dataChunk?.length, 3)).map((news, index) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        ?.toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Box
                                        key={news?.id}
                                        sx={{
                                            position: 'relative',
                                            height: '100%'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                top: '10px',
                                                left: '10px',
                                                // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                // padding: '2px 2px',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <Typography variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }}>
                                                {news?.['News Source']}
                                            </Typography>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={
                                                news?.Type === 'daily-updates-treasury'
                                                    ? '/img/treasury-bonds.jpeg'
                                                    : news?.Type === 'daily-updates-corporate'
                                                    ? '/img/corporate-bonds.jpeg'
                                                    : news?.Type === 'daily-updates-econ'
                                                    ? '/img/econ-market.jpg'
                                                    : news?.Image || '/img/logo.png'
                                            }
                                            alt={news?.Headline}
                                            sx={{
                                                width: '100%',
                                                height: '376px',
                                                objectFit: 'cover',
                                                borderRadius: '10px'
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                padding: '20px',
                                                color: '#fff'
                                            }}
                                        >
                                            {news?.Headline}
                                        </Typography>
                                    </Box>
                                </a>
                            ))}
                        </StyledCarousel>
                    </Grid>

                    {index === 0 ? (
                        <Grid item xs={5}>
                            <Grid
                                container
                                direction="column"
                                spacing={2}
                                sx={{ display: 'block', overflowX: 'hidden', height: '380px', mt: '-10px' }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{ fontWeight: 'bold', backgroundColor: 'white', position: 'sticky', top: '0px', color: 'red' }}
                                >
                                    LATEST NEWS
                                </Typography>
                                {sortedNewsArray &&
                                    sortedNewsArray.map((news, index) => (
                                        <a
                                            key={index}
                                            href={`/knowledge-and-research/news/${news?.id
                                                ?.toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9\-]/g, '')}`}
                                            target="_blank"
                                        >
                                            <Grid item key={news?.id} sx={{ marginBottom: '5px' }}>
                                                <Card sx={{ display: 'flex', height: '100%' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                display: 'flex',
                                                                alignItems: 'baseline',
                                                                '&:hover': {
                                                                    textDecoration: 'underline'
                                                                }
                                                            }}
                                                        >
                                                            {news?.Headline}
                                                        </Typography>

                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                display: 'block',
                                                                color: 'gray',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.8rem'
                                                            }}
                                                        >
                                                            {timeAgo(news?.['Created At'])}
                                                        </Typography>
                                                        <hr style={{ width: '440px' }} />
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        </a>
                                    ))}
                            </Grid>
                        </Grid>
                    ) : (
                        // {/* Right side (3rd to 5th) */}
                        <Grid item xs={5}>
                            <Grid container direction="column" sx={{ height: '100%' }}>
                                {dataChunk.slice(3, Math.min(dataChunk?.length, 6)).map((news, index) => (
                                    <a
                                        key={index}
                                        href={`/knowledge-and-research/news/${news?.id
                                            ?.toLowerCase()
                                            .replace(/\s+/g, '-')
                                            .replace(/[^a-z0-9\-]/g, '')}`}
                                        target="_blank"
                                    >
                                        <Grid item key={news?.id} sx={{ height: '120px', marginBottom: '5px' }}>
                                            <Card sx={{ display: 'flex', height: '100%' }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        padding: '5px',
                                                        width: 'calc(100% - 122px)'
                                                    }}
                                                >
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                        {limitedText(news?.Headline)}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            display: 'block',
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem',
                                                            marginTop: '4px'
                                                        }}
                                                    >
                                                        {news?.['News Source']}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            display: 'block',
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem'
                                                        }}
                                                    >
                                                        {news?.['Created At']}
                                                    </Typography>
                                                </Box>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: '120px' }}
                                                    image={
                                                        news?.Type === 'daily-updates-treasury'
                                                            ? '/img/treasury-bonds.jpeg'
                                                            : news?.Type === 'daily-updates-corporate'
                                                            ? '/img/corporate-bonds.jpeg'
                                                            : news?.Type === 'daily-updates-econ'
                                                            ? '/img/econ-market.jpg'
                                                            : news?.Image || '/img/logo.png'
                                                    }
                                                    alt={news?.Headline}
                                                />
                                            </Card>
                                        </Grid>
                                    </a>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {/* Bottom Row (6th to 10th) */}
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={3.5}>
                        {dataChunk[6] && (
                            <a
                                href={`/knowledge-and-research/news/${dataChunk[6]?.id
                                    ?.toLowerCase()
                                    .replace(/\s+/g, '-')
                                    .replace(/[^a-z0-9\-]/g, '')}`}
                                target="_blank"
                            >
                                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                    <CardMedia
                                        component="img"
                                        image={dataChunk[6]?.Image}
                                        alt={dataChunk[6]?.Headline}
                                        sx={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <Box sx={{ padding: '10px' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {limitedText(dataChunk[6]?.Headline)}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                marginTop: '8px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[6]?.['News Source']}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[6]?.['Created At']}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {dataChunk[6]?.Content?.length > 60
                                                ? dataChunk[6]?.Content.slice(0, 60) + '...'
                                                : dataChunk[6]?.Content}
                                        </Typography>
                                    </Box>
                                </Card>
                            </a>
                        )}
                    </Grid>

                    <Grid item xs={5}>
                        <Grid container direction="column">
                            {dataChunk.slice(7, Math.min(dataChunk?.length, 10)).map((news, index) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        ?.toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Grid item key={news?.id} sx={{ height: '120px', marginBottom: '5px' }}>
                                        <Card sx={{ display: 'flex', height: '100%' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '5px',
                                                    width: 'calc(100% - 122px)'
                                                }}
                                            >
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {limitedText(news?.Headline)}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        marginTop: '4px'
                                                    }}
                                                >
                                                    {news?.['News Source']}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['Created At']}
                                                </Typography>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '120px' }}
                                                image={
                                                    news?.Type === 'daily-updates-treasury'
                                                        ? '/img/treasury-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-corporate'
                                                        ? '/img/corporate-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-econ'
                                                        ? '/img/econ-market.jpg'
                                                        : news?.Image || '/img/logo.png'
                                                }
                                                alt={news?.Headline}
                                            />
                                        </Card>
                                    </Grid>
                                </a>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={3.5}>
                        {dataChunk[10] && (
                            <a
                                href={`/knowledge-and-research/news/${dataChunk[10]?.id
                                    ?.toLowerCase()
                                    .replace(/\s+/g, '-')
                                    .replace(/[^a-z0-9\-]/g, '')}`}
                                target="_blank"
                            >
                                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                    <CardMedia
                                        component="img"
                                        image={dataChunk[10]?.Image}
                                        alt={dataChunk[10]?.Headline}
                                        sx={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <Box sx={{ padding: '10px' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {limitedText(dataChunk[10]?.Headline)}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                marginTop: '8px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[10]?.['News Source']}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[10]?.['Created At']}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {dataChunk[10]?.Content?.length > 60
                                                ? dataChunk[10]?.Content.slice(0, 60) + '...'
                                                : dataChunk[10]?.Content}
                                        </Typography>
                                    </Box>
                                </Card>
                            </a>
                        )}
                    </Grid>
                </Grid>
            </>
        ) : (
            <Grid item xs={12}>
                <>
                    {index === 0 ? (
                        <>
                            <Grid
                                container
                                direction="column"
                                sx={{ display: 'block', overflowX: 'hidden', height: '380px', mt: '-10px', mb: '12px' }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontWeight: 'bold',
                                        backgroundColor: 'white',
                                        position: 'sticky',
                                        top: '0px',
                                        color: 'red',
                                        textAlign: 'center'
                                    }}
                                >
                                    LATEST NEWS
                                </Typography>
                                {latestNews &&
                                    latestNews.map((news, i) => (
                                        <a
                                            key={index}
                                            href={`/knowledge-and-research/news/${news?.id
                                                ?.toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9\-]/g, '')}`}
                                            target="_blank"
                                        >
                                            <Grid item key={news?.id} sx={{ marginBottom: '5px' }}>
                                                <Card sx={{ display: 'flex', height: '100%' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                '&:hover': {
                                                                    textDecoration: 'underline'
                                                                }
                                                            }}
                                                        >
                                                            {news?.Headline}
                                                        </Typography>

                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                display: 'block',
                                                                color: 'gray',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.8rem'
                                                            }}
                                                        >
                                                            {timeAgo(news?.['Created At'])}
                                                        </Typography>
                                                        <hr style={{ width: '260px' }} />
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        </a>
                                    ))}
                            </Grid>
                            {dataChunk.map((news, i) =>
                                i == 3 || i == 4 || i == 5 ? null : (
                                    <a
                                        key={index}
                                        href={`/knowledge-and-research/news/${news?.id
                                            ?.toLowerCase()
                                            .replace(/\s+/g, '-')
                                            .replace(/[^a-z0-9\-]/g, '')}`}
                                        target="_blank"
                                    >
                                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                            <CardMedia
                                                component="img"
                                                image={
                                                    news?.Type === 'daily-updates-treasury'
                                                        ? '/img/treasury-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-corporate'
                                                        ? '/img/corporate-bonds.jpeg'
                                                        : news?.Type === 'daily-updates-econ'
                                                        ? '/img/econ-market.jpg'
                                                        : news?.Image || '/img/logo.png'
                                                }
                                                alt={news?.Headline}
                                                sx={{ height: '200px', objectFit: 'inherit' }}
                                            />
                                            <Box sx={{ padding: '10px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {news?.Headline}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        marginTop: '8px',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['News Source']}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['Created At']}
                                                </Typography>
                                            </Box>
                                        </Card>
                                        {index !== news?.length - 1 ? <hr style={{ marginBottom: '3px' }} /> : null}
                                    </a>
                                )
                            )}
                        </>
                    ) : (
                        <>
                            {dataChunk.map((news, i) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        ?.toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                news?.Type === 'daily-updates-treasury'
                                                    ? '/img/treasury-bonds.jpeg'
                                                    : news?.Type === 'daily-updates-corporate'
                                                    ? '/img/corporate-bonds.jpeg'
                                                    : news?.Type === 'daily-updates-econ'
                                                    ? '/img/econ-market.jpg'
                                                    : news?.Image || '/img/logo.png'
                                            }
                                            alt={news?.Headline}
                                            sx={{ height: '200px', objectFit: 'inherit' }}
                                        />
                                        <Box sx={{ padding: '10px' }}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                {news?.Headline}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    color: 'gray',
                                                    fontWeight: 'bold',
                                                    marginTop: '8px',
                                                    fontSize: '0.8rem'
                                                }}
                                            >
                                                {news?.['News Source']}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    color: 'gray',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8rem'
                                                }}
                                            >
                                                {news?.['Created At']}
                                            </Typography>
                                        </Box>
                                    </Card>
                                    {index !== news?.length - 1 ? <hr style={{ marginBottom: '3px' }} /> : null}
                                </a>
                            ))}
                        </>
                    )}
                </>
            </Grid>
        );

    return (
        <div>
            {newsChunks.map((newsChunk, index) => (
                <React.Fragment key={index}>{renderNewsLayout(newsChunk, index)}</React.Fragment>
            ))}
        </div>
    );
};

export default NewsFeedTileView;
