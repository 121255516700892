import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getSubcriptions, getTpeModule, submitTpeModule, submitTpeModuleSuccess } from "redux/actions/SettingActions";
import RenderField from "utils/RenderField";
import CustomDialogTitle from "components/DialogTitle";
import { CustomButton } from "assets/jss/ButtonStyle";

const fieldsDetail = [
  { name: "name", label: "Name", type: 'text' ,placeholder:'Enter Permission Name' ,custompattern:/^([a-z0-9A-Z ]{1,50})$/g},
  { name: "module_unique_id", label: "Permission Id", type: 'text', placeholder: 'Enter Permission Id', custompattern: /^([a-zA-Z_]{1,50})$/g, helperText: "Text should contain only _ (underscore) and alphabet" },
  { name: "subscription", label: "Subscription Type", type: 'select', required:false },
];
const ModuleSetting = (props) => {
  const {  setting } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [moduleColl, setModuleColl] = React.useState([]);
  const {
    handleSubmit,
    control, reset,
    formState: { isSubmitting },
    setValue,
  } = useForm({
    mode: "onChange"
  });
   const planOptions = () => {
    return setting.subscriptionList.map(val => ({value:val.id,label:val.name}))
}
  // submit the form
  const onSubmit = (data) => {
    let type = 'post';
    let objToPass = {
      name: data.name,
      module_unique_id: data.module_unique_id,
      sub_id: data.subscription || undefined,
    };
    if (data.id) {
      objToPass = { ...objToPass, "mod_id": data.id }
      type = 'put';
    }
    dispatch(submitTpeModule(objToPass,type))

  }
  useEffect(() => {
     // to close opened popup after submittion of api
    if (setting.moduleFormDialog) {
      handleClose();
      dispatch(submitTpeModuleSuccess(false));
    }
  }, [setting.moduleFormDialog]);
  useEffect(() => {
     //to create array of column to pass in table
    if (setting.moduleList.length>0) {
       const ignoreFields = ["id"];
      let column = [];
      Object.keys(setting.moduleList[0]).forEach(keys => {
        if (!ignoreFields.includes(keys)) {
          column.push({
            Header: (keys === 'module_unique_id' && 'Permission name') || keys,
            accessor: (keys === "subscription" && `subscription.name`) || keys,
            disableFilters: true,
            Cell: ({ value }) => value || "-",
          });
        }
      });
      column.unshift({
        Header: "Action",
        width: 120,
        Cell: ({ row }) => (
          <div className={"actionWrapper"}>
            <Tooltip title='Delete'>
              <IconButton
                aria-label='delete'
                onClick={() => deleteSelectedData(row.original.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                aria-label='edit'
                onClick={(e) => handleEditData(row.original)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      });
      setModuleColl(column);
    }
  }, [setting.moduleList]);
  useEffect(() => {
    /// to fetch the default data on page load
    dispatch(getTpeModule())
    dispatch(getSubcriptions())
  }, []);

  const handleClickOpen = () => setOpen(true);
  // delete selected permission
  const deleteSelectedData = id => dispatch(submitTpeModule({ 'mod_id': id }, 'delete'));
  // enable to edit permission
  const handleEditData = val => {
    setOpen(true);
    // to load saved data in form
    for (const key in val) {
      if (Object.hasOwnProperty.call(val, key)) {
        let element = val[key];
        if (typeof element === 'object') {
          element = element?.id;
        }
        setValue(key, element);
      }
    }
  }
  //close popup
  const handleClose = () => {
    const resetObj = fieldsDetail.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
    reset(resetObj || {});
    setOpen(false);
  }
  return (
    <>
          <Card elevation={2}>
              <CardContent>
                 <Tooltip title="Add" arrow>
                      <CustomButton variant="text" className="read-more" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                          Add New Data
                      </CustomButton>
                  </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='form-dialog-title'>
                  <CustomDialogTitle id='form-dialog-title' onClose={handleClose}>
                    Add New TPE Permission
                  </CustomDialogTitle>
                  <Divider />
                  <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container>
                        {fieldsDetail.map((val, i) => {
                          const optionsList = val.name === "subscription" ? planOptions() : val.options;
                          return (
                            <Grid item xs={12} sm={6} key={i + "addnewtpemodule"}>
                              <RenderField
                                name={val.name}
                                control={control}
                                label={val.label}
                                id={val.name}
                                timeFormat={false}
                                type={val.type}
                                options={optionsList}
                                required={val.required || true}
                                customPatternName={val.custompattern}
                                placeholder={val.placeholder}
                                setValue={setValue}
                                {...val}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid container
                        direction='row'
                        style={{ width: "100%", textAlign: "center" }}>
                        <Grid item xs={12}>
                          <CustomButton type="submit" ps={'9px 30px'} variant="contained"
                            disabled={isSubmitting}>
                            Submit
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </form>
                  </DialogContent>
                </Dialog>
                {setting.moduleList.length > 0 ? (
                  <Card>
                    <EnhancedTable
                      enableSearch={false}
                      // handleLoadData={getInvestDashboardData}
                      columns={moduleColl}
                      data={setting.moduleList || []}
                      enableSort={true}
                      enablePagination={false}
                    />
                  </Card>
                ) : (
                  <NoDataFound msg='Result will be shown after adding module permission.' />
                )}{" "}
                </CardContent>
                </Card>
          
    </>
  );
};

export default ModuleSetting;
