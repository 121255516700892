import {
    Box, CardContent, Grid, Typography
  } from "@mui/material";
  import Tabs from "@mui/material/Tabs";
  import { makeStyles, useTheme } from "@mui/styles";
  import { CustomButton } from "assets/jss/ButtonStyle";
  import { SampleFilePaper, SampleFileParentBox, StyledAtag, StyledTextareaAutosize, TopHeadingPaper, UploadCardParent, UploadStyledAppBar } from "assets/jss/GeneralStyle";
  import portfolioPageStyle from "assets/jss/material-kit-react/views/portfolioPage";
  import { PortUploadTab } from "assets/jss/PortfolioStyle";
  import { CalCardActions } from "assets/jss/RetirementStyle";
  import CustomReactDropzone from "components/CommonComponent/CustomReactDropzone";
  import CustomSelect from "components/CustomSelect/CustomSelect";
  import moment from "moment";
  import React,{ useEffect, useState } from "react";
  import SwipeableViews from "react-swipeable-views";
  import { toast } from "react-toastify";
  import { uploadRecurringFile } from "redux/actions/RecurringPaymentActions";
  import { parseCsv, parseExcel } from "utils/Utils";
  import PortfolioTabPanel from "views/PortfolioValueUpload/PortfolioTabPanel";


  const useStyles = makeStyles(portfolioPageStyle);
  const RecurringPaymentUpload = (props) => {
    const requiredFields = ["name", "monthly_amount", "importance"];
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [fileLists, setFileLists] = useState([]);
    const [disableUploadButton, setDisableUploadButton] = useState(false);
    const [resetDropzoneValue, setResetDropzoneValue] = useState(false);
    const [fileBelongings, setFileBelongings] = useState({});
    const [
      stringCsv,
      setStringCsv,
    ] = useState(`name,pay_type,category,auto_renewal,monthly_amount,importance,payment_period
citi,Auto Pay,credit card,yes,2000,8,monthly
Electric,Online,Utility,yes,5000,4,monthly
Capital One,Auto Pay,credit card,yes,1000,9,annualy
Gas,Auto Pay,Utility,yes,3200,3,annualy
WSJ,Auto Pay,Subscription,no,1000,2,annualy`);
  
    useEffect(() => {
      if (
        (fileLists.length === 0 && value === 1) ||
        (stringCsv === "" && value === 0)
      ) {
        setDisableUploadButton(true);
      } else {
        setDisableUploadButton(false);
      }
    }, [fileLists, stringCsv]);
  
    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (event, newValue) => {
      setFileLists([]);
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
    /**
     * get the selected file from dropzone
     * @param {*} fileList
     */
    const getListOfFiles = (fileList) => {
      setFileLists(fileList);
    };
  
    const passFileToParse = async (
      tabIndex,
      file,
      localDeletedData,
      parsedResult,
      skipParsed = false
    ) => {
      let localRes = {};
      const localSkipParsed = skipParsed;
      if (
        file.path &&
        (file.path.match(/.*\.xlsx?$/g) ||
          file.type === "application/vnd.ms-excel")
      ) {
        localRes = await parseExcel(file);
      } else {
        if (!localSkipParsed) {
          localRes = await parseCsv(file);
        } else {
          localRes = file;
        }
      }
      if (localRes.data.length === 0) {
        toast.error("Please enter valid data.");
        return;
      }
      // let reg = "";
      // if (tabIndex === 0) {
      //   reg = new RegExp(localRes.fileName, "g");
      // } else {
      //   reg = new RegExp("^[" + localRes.fileName + "]+$", "g");
      // }
      validateData(tabIndex, localRes, localDeletedData, parsedResult);
    };
    /**
     * convert excel string format to json format
     * @param {*} data
     * @returns
     */
    const convertStringExcelToJson = (data) => {
      let jsonData = [];
      let rows = data.split("\n");
      const header = [];
      for (var rowIndex in rows) {
        if (rowIndex !== undefined) {
          let cells = rows[rowIndex].split("\t");
          if (parseInt(rowIndex, 10) !== 0) {
            jsonData[rowIndex - 1] = {};
          }
          for (let cellIndex in cells) {
            if (rowIndex === 0) {
              header.push(cells[cellIndex]);
            } else {
              jsonData[rowIndex - 1][header[cellIndex]] =
                cells[cellIndex] || null;
            }
          }
        }
      }
      return { data: jsonData, fileName: "Text Data" };
    };
  
    const validateData = (fileType, localRes, localDeletedData, parsedResult) => {
      // if (fileType == 0){
      //   for (let i = 0; i < localRes.data.length; i++) {
      //     if (localRes.data[i]["Zip Code"] !== null) {
      //       localRes.data[i]["Zip Code"] = localRes.data[i]["Zip Code"].replace(/['"]/g, '');
      //     }
      //   }
      // }
      
      let responseLocal = localRes;
      const timeStamp = moment().format("x");
      // // to add number of occurance file after the file name to make it unique name
      const currFileName = responseLocal.fileName.split(".");
      const fileExt =
        currFileName.length > 1
          ? "." + currFileName[currFileName.length - 1]
          : "";
      const fileNameWithoutExt = responseLocal.fileName.replace(fileExt, "");
      responseLocal = {
        ...responseLocal,
        fileName: `${fileNameWithoutExt}_${timeStamp}${fileExt}`,
      };
      const localFileData = JSON.parse(JSON.stringify(responseLocal));
      let arrToSend = [...new Set(responseLocal.data)];
      for (
        let parsedIndex = 0;
        parsedIndex < responseLocal.data.length;
        parsedIndex++
      ) {
        let eachData = responseLocal.data[parsedIndex];
  
        const indexToAppedAt = arrToSend.findIndex(
          (x) => JSON.stringify(x) === JSON.stringify(eachData)
        );
  
        //  Converting all columns to Title case and remove underscore(-)
        eachData = Object.keys(eachData).reduce((acc, key) => {
          const normalizedKey = key.replace(/ /g, '_').toLowerCase();
          acc[normalizedKey] = eachData[key];
          return acc;
        }, {});
  
        const isRequiredIncluded = requiredFields.some((val) => {
          if (eachData[val] === undefined || eachData[val] === null) {
            return val;
          }
        });
        // validating Monthly Amount field
        if( typeof eachData['monthly_amount'] == 'string' ){
          toast.error("Monthly Amount cannot be a string.");
          return;
        }
        if(eachData['importance'] > 10 || eachData['importance'] <= 0){
          toast.error("Importance must be between 1-10 range.");
          return;
        }
        arrToSend[indexToAppedAt] = {
          name: eachData['name'] || null,
          category: eachData['category'] || "",
          pay_type: String(eachData['pay_type']).toLowerCase() || "",
          monthly_amount: eachData['monthly_amount'] || null,
          auto_pay_account_name: eachData['auto_pay_account_name'] || "",
          payment_period: eachData['payment_period'] || "",
          auto_renewal: eachData['auto_renewal'] || "",
          importance: eachData['importance'] || null,
          comment: eachData['comment'] || "",
          contact: eachData['contact'] || ""
        };
  
        // save error rows in state and remove from main array of data
        if (isRequiredIncluded) {
          localDeletedData[responseLocal.fileName] =
            localDeletedData[responseLocal.fileName] || [];
          localDeletedData[responseLocal.fileName].push(eachData);
          localFileData.data.splice(parsedIndex, 1);
          arrToSend.splice(indexToAppedAt, 1);
        }
      }
      if (arrToSend.length > 0) {
        parsedResult.push({
          fileName: localFileData.fileName,
          data: arrToSend,
        });
      }
    };
    /**
     * save selected file with validating data
     * @param {*} e
     */
    const handleParseFile = async (e) => {
      let localDeletedData = {};
      let parsedResult = [];
      if (disableUploadButton) {
        toast.error("Please select file to upload.");
        return;
      }
      switch (value) {
        //text data
        case 0:
          var fileData = "";
          var skipParsed = false;
          if (stringCsv.split(",").length === 1) {
            fileData = convertStringExcelToJson(stringCsv);
            skipParsed = true;
          } else {
            fileData = stringCsv;
          }
          await passFileToParse(0,fileData,localDeletedData,parsedResult,skipParsed);
          break;
        case 1:
          //local files
          for (let index = 0; index < fileLists.length; index++) {
            const file = fileLists[index];
            await passFileToParse(1, file, localDeletedData, parsedResult);
          }
          break;
        default:
          break;
      }
       if (Object.keys(parsedResult).length > 0) {
         props.dispatch(uploadRecurringFile(parsedResult));
       }
       setFileLists([]);
      setResetDropzoneValue(!resetDropzoneValue);
      if (Object.keys(localDeletedData).length) {
        return toast.error(<div className={classes.deletedFileDetail}>
          Below rows are removed due to missing required fields.<br />
          <br />
          {Object.keys(localDeletedData).map((val, deletedIndex) => {
            return (
              <div style={{ wordBreak: "break-all" }}>
                {deletedIndex + 1}. {val} :{" "}
                {localDeletedData[val].length === 1
                  ? localDeletedData[val].length + " row"
                  : localDeletedData[val].length + " rows"}
              </div>
            );
          })}</div>, { autoClose: false })
      }
  
    };
   // to handle selected value of who and what drop down
    const handleOnChange = (e) => {
      setFileBelongings({ ...fileBelongings, [e.target.name]: e.target.value });
    };
  
    return (
        <>
            <TopHeadingPaper elevation={2}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                    Input Form
                </Typography>
            </TopHeadingPaper>
  
            <UploadCardParent elevation={2}>
                <CardContent>
                    <Grid container spacing={2} mb="10px">
                        <UploadStyledAppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                allowScrollButtonsMobile={true}
                                aria-label="full width recurring analysis"
                            >
                                <PortUploadTab label="Text Data" {...props.a11yProps(0)} className={classes.appbarPortUploadTab} />
                                <PortUploadTab label="Local File(s)" {...props.a11yProps(1)} className={classes.appbarTab} />
                            </Tabs>
                        </UploadStyledAppBar>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                            <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                                <StyledTextareaAutosize
                                    className={classes.textarea}
                                    aria-label="minimum height"
                                    minRows={20}
                                    placeholder="String input"
                                    defaultValue={stringCsv}
                                    onChange={(e) => setStringCsv(e.target.value)}
                                />
                                <Box mt={'30px'}>
                                    <Typography variant="h6">{'Note:'}</Typography>
                                    <Typography variant="body1">
                                        1. You can copy and paste your csv file text or excel file text in above box.
                                    </Typography>
                                    <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                                </Box>
                            </PortfolioTabPanel>
                            <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                                <CustomReactDropzone
                                    accept="text/csv, .csv,application/vnd.ms-excel,.xlsx,.xls"
                                    handleDroppedFiles={getListOfFiles}
                                    resetDropzoneValue={resetDropzoneValue}
                                />
                                <Box mt={'30px'}>
                                    <Typography variant="h6">{'Note:'}</Typography>
                                    <Typography variant="body1">1. Acceptable file types: CSV, Excel(.xls,.xlsx)</Typography>
                                    <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                                </Box>
                                {fileLists.length === 0 ? (
                                    <>
                                        {/* <Typography gutterBottom id="sampleTitle" color={'secondary'}>
                                            Please download the sample CSV / Excel file
                                        </Typography>
                                        <SampleFileParentBox>
                                            <SampleFilePaper>
                                                <Box>
                                                    <StyledAtag href="/CSVSample/Budget_Exp_Detail.csv" download>
                                                        <img src="img/csv-file-icon.svg"  alt='TPE csv sample file'/>
                                                    </StyledAtag>
                                                    <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                        CSV Sample
                                                    </Typography>
                                                </Box>
                                            </SampleFilePaper>
                                            <SampleFilePaper>
                                                <Box>
                                                    <StyledAtag href="/CSVSample/Budget_Exp_Detail.xls" download>
                                                        <img src="img/excel-file-icon.svg" alt="excel icon"/>
                                                    </StyledAtag>
                                                    <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                        Excel Sample
                                                    </Typography>
                                                </Box>
                                            </SampleFilePaper>
                                        </SampleFileParentBox> */}
                                    </>
                                ) : (
                                    ''
                                )}
                            </PortfolioTabPanel>
                        </SwipeableViews>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            onClick={handleParseFile}
                            disabled={disableUploadButton}
                            className={'success-btn'}
                            variant="contained"
                        >
                            Upload
                        </CustomButton>
                    </CalCardActions>
                </CardContent>
            </UploadCardParent>
        </>
    );
  };
  
  export default RecurringPaymentUpload;
  