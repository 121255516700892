import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchEquityDashboardData } from "redux/actions/EquityDashboardActions";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import { numberWithCommas } from "utils/Utils";
import { limitWords } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import WatchListChart from "./WatchListChart";
import { fetchWatchListChart } from "redux/actions/EquityDashboardActions";
import { CustomButton } from "assets/jss/ButtonStyle";

const EquityDashTable =(props)=> {
  const dispatch = useDispatch();
  const [resultData, setResult] = useState([]);
  const [colorScaleArr, setColorScaleArr] = useState({});
  const [enableChart, setEnableChart] = useState({});
  const getEquityDashboardData = () => {
    dispatch(fetchEquityDashboardData());
  };
  const handleSymbolClick=(data)=>{
    setEnableChart(data);
    dispatch(fetchWatchListChart(data.symbol));
  }
  const handleRangeSelection = (range) => {
      dispatch(fetchWatchListChart(enableChart.symbol,range));
  };
  useEffect(() => {
    getEquityDashboardData();
  }, []);
  useEffect(() => {
    if (Object.keys(props.equityDashboardData.equityDashboardDetail).length) {
      const resultData = props.equityDashboardData.equityDashboardDetail.data;
      setColorScaleArr(props.equityDashboardData.arrForColorScale);
      setResult(resultData);
    }
  }, [props.equityDashboardData]);
  function compareNumericString(rowA, rowB, id, desc) {
    let a = Number.parseFloat(rowA.values[id]);
    let b = Number.parseFloat(rowB.values[id]);
    if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
      b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
  }
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Symbol",
        accessor: "symbol",
        disableFilters: true,
        sticky: true,
        width:100,
        Cell: ({ row ,value}) => {
          return <CustomButton variant="text" onClick={()=>handleSymbolClick(row.original)}>{value}</CustomButton>;
        }
      },
      {
        Header: "Company Name",
        width:100,
        accessor: "Company Name",
        disableFilters: true,
      },
      {
        Header: "Latest Price",
        width:70,
        accessor: "Latest Price",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        disableFilters: true,
      },
      {
        Header: "Daily Price Change %",
        accessor: "Daily Price Change %",
        sortType: compareNumericString,
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        colorScaleArr: colorScaleArr["Daily Price Change %"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width:70,
      },
      {
        Header: "Closing Price",
        accessor: "Closing Price",
        sortType: compareNumericString,
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        disableFilters: true,
        width:70,
      },
      {
        Header: "5 Day Change %",
        accessor: "5 Day Change%",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        colorScaleArr: colorScaleArr["5 Day Change%"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width:70,
      },
      {
        Header: "1 Year Change %",
        accessor: "1 Year Change%",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        colorScaleArr: colorScaleArr["1 Year Change%"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width:70,
      },
      {
        Header: "Latest Headline",
        accessor: "Latest Headline",
        Cell: ({ value, row, cell }) =>{
          return value ? limitWords(value, 5) !== value ? 
              <CustomTooltip tooltipText={value} textTooltip={false} textLimit={5} align="left" />
              :value:'-'
        },
        disableFilters: true,
      },
    ];
  }, [colorScaleArr]);

  return (
          // <Card elevation={2}>
          <>
          {Object.keys(enableChart).length > 0 ? (
              <WatchListChart
                  chartdetails={enableChart}
                  backButtonClicked={() => setEnableChart({})}
                  rangeSelectionClicked={(val) => handleRangeSelection(val)}
              />
          ) : (
         
            <EnhancedTable
                enableSearch={false}
                handleLoadData={getEquityDashboardData}
                title={'Equities'}
                columns={columns}
                data={resultData}
                enableSort={true}
                enablePagination={false}
                loader={props?.equityDashboardData.loader}
            />)}
              </>
          // </Card>
  );
}

export default EquityDashTable;
