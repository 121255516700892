import React, { useEffect, useState } from 'react';
import CommonTable from 'components/CommonComponent/CommonTable/CommonTable.js';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLoanDashBoardData,
    fetchLoanDashBoardHistoricalData,
    reloadLoanDashBoardData,
    fetchBankRateData
} from 'redux/actions/LoanDashBoardAction';
import { Card, Dialog, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import * as tooltip from 'Tooltip.json';
import LoanDashChart from './LoanDashChart';
import { CustomButton } from '../../assets/jss/ButtonStyle';
import { toast } from 'react-toastify';
import CustomDialogTitle from 'components/DialogTitle';
import { StyledDialogContent } from 'assets/jss/GeneralStyle';
import RenderField from 'utils/RenderField';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { useForm } from 'react-hook-form';
import { viewDebtCalulator } from 'redux/actions/DebtCalActions';
import { SummaryTabPaper } from 'assets/jss/PortfolioStyle';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import { numberWithCommas } from 'utils/Utils';
import { fetchIndexTypeValue } from 'redux/actions/DebtCalActions';

const arr = ['30 YR Fixed Conforming Mtg', '15 YR Fixed Conforming Mtg', '30 YR Fixed Jumbo Mtg'];
const bankRateArr = ['60-month new car', '48-month new car', '48-month used car', '36-month used car', 'Credit Card'];

function LoanDashTable(props) {
    const { classes } = props;
    const parentHeader = [['Mortgage Rates'], ['Consumer Loan Rates'], ['Auto Loan Rates']];
    const debtTypeOptions = ['Auto', 'Personal', 'Student Loan', 'Mortgage'];
    const indexTypeLabels = ['Prime', 'SoFR', 'Other/Custom'];
    const colList = [
        ['mort_actionType', 'Action'],
        ['mort_label', 'Names'],
        ['mort_Values', 'Values'],
        ['consum_actionType', 'Action'],
        ['consum_label', 'Names'],
        ['consum_Values', 'Values'],
        ['car_actionType', 'Action'],
        ['car_label', 'Names'],
        ['car_values', 'Values']
    ];
    const dispatch = useDispatch();
    const debt = useSelector((state) => state.debt);
    const [result, setResult] = useState({});
    const [enableChart, setEnableChart] = useState({});
    const [indexValue, setIndexValue] = useState('');
    const [idxVal, setIdxVal] = useState(0);
    const [indexType, setIndexType] = useState('');
    const [creditCardOption, setCreditCardOption] = useState('');
    const [floatingOption, setFloatingOption] = useState('');
    const [option, setOption] = useState('');
    const [open, setOpen] = useState(false);
    const [openOutput, setOpenOutput] = useState(false);
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    const [fieldsDetail, setFieldsDetail] = useState([
        {
            name: 'debtName',
            label: 'Debt Name',
            type: 'text',
            required: true,
            placeholder: 'Enter your Debt Name'
        },
        {
            name: 'prinAmt',
            label: 'Principal Amount',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'maturityYrs',
            label: 'Maturity Years',
            type: 'number',
            commaSep: true,
            custompattern: /^\d{0,2}\.?\d{1,2}$/,
            placeholder: '2',
            maxLength: 5,
            required: true
        },
        {
            name: 'repayType',
            label: 'Repay Type',
            type: 'select',
            required: true,
            options: [
                { label: 'Interest Only', value: 'io' },
                { value: 'pi', label: 'Principal and Interest' }
            ]
        },
        {
            name: 'interestRate',
            label: 'Interest Rate',
            type: 'number',
            commaSep: false,
            custompattern: 'decimalRate',
            placeholder: '10',
            // maxLength: 3,
            required: true
        },
        {
            name: 'debttype',
            label: 'Debt Type',
            type: 'select',
            options: [
                { label: 'Auto', value: 'auto' },
                { value: 'creditcard', label: 'Credit Card' },
                { label: 'Mortgage', value: 'mortgage' },
                { label: 'Personal', value: 'personal' },
                { label: 'Student Loan', value: 'studloan' }
            ]
        },
        {
            name: 'actual_pay',
            label: 'Actual payment Made',
            type: 'number',
            required: false,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'fixed_floating',
            label: 'Fixed/Floating',
            required: false,
            type: 'select',
            options: [
                { label: 'Fixed', value: 'fixed' },
                { value: 'floating', label: 'Floating' }
            ]
        },
        {
            name: 'index',
            label: 'Index',
            type: 'number',
            required: false,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '5.31',
            disabled: true
        },
        {
            name: 'margin',
            label: 'Margin',
            type: 'number',
            required: false,
            commaSep: false,
            custompattern: 'decimalRate',
            placeholder: '10.54'
        }
    ]);

    const handleClose = () => {
        reset({});
        setOpen(false);
        setFieldsDetail(fieldsDetail.slice(0, 9));
        setOption('');
        setIndexValue('');
        setIndexType('');
        setIdxVal(0);
        setCreditCardOption('');
        setFloatingOption('');
    };

    const handleCloseOutput = () => {
        setOpenOutput(false);
    };

    const handleRangeSelection = async (range) => {
        if (bankRateArr.includes(enableChart.ticker)) {
            const res = await dispatch(fetchBankRateData(enableChart.ticker, range));
            setEnableChart(res);
        } else {
            const res = await dispatch(fetchLoanDashBoardHistoricalData(enableChart.ticker, range));
            setEnableChart(res);
        }
    };

    const handleSymbolClick = async (data) => {
        if (!arr.includes(data) && !bankRateArr.includes(data)) {
            return toast.error('No chart data found.');
        }
        if (bankRateArr.includes(data)) {
            const res = await dispatch(fetchBankRateData(data, '1y'));
            setEnableChart(res);
        } else {
            const res = await dispatch(fetchLoanDashBoardHistoricalData(data, '1y'));
            setEnableChart(res);
        }
    };

    const getLoanDashboardData = () => {
        dispatch(fetchLoanDashBoardData());
    };

    const updateLoanDashboardData = () => {
        dispatch(reloadLoanDashBoardData());
    };

    useEffect(() => {
        getLoanDashboardData();
    }, []);

    useEffect(() => {
        if (Object.keys(props.loanDashboardData.loanDashboardDetail).length) {
            setResult(props.loanDashboardData.loanDashboardDetail);
        }
    }, [props.loanDashboardData]);

    const handleIconClick = (label, val) => {
        setOpen(true);
        setValue('fixed_floating', 'fixed');
        setValue('repayType', 'pi');
        if (label === 'mort_actionType') {
            setValue('debtName', val?.mort_label.slice(6));
            setValue('maturityYrs', val?.mort_label.slice(0, 2));
            setValue(
                'interestRate',
                typeof val?.mort_Values === 'number'
                    ? val.mort_Values.toFixed(2)
                    : parseFloat(val?.mort_Values?.replace('%', '') || 0).toFixed(2)
            );
            setValue('debttype', 'mortgage');
            setOption('Mortgage');
        } else if (label === 'consum_actionType') {
            setValue('debtName', val?.consum_label.slice(0, 16));
            setValue('maturityYrs', 15);
            setValue(
                'interestRate',
                typeof val?.mort_Values === 'number'
                    ? val.mort_Values.toFixed(2)
                    : parseFloat(val?.consum_Values?.replace('%', '') || 0).toFixed(2)
            );
            if (val?.consum_label.includes('Credit Card')) {
                setValue('debttype', 'creditcard');
                setOption('Credit Card');
            } else if (val?.consum_label.includes('Student')) {
                setValue('debttype', 'studloan');
                setOption('Student Loan');
            }
        } else {
            setValue('debtName', val?.car_label.slice(8));
            setValue('maturityYrs', val?.car_label.slice(0, 2) / 12);
            setValue(
                'interestRate',
                typeof val?.mort_Values === 'number'
                    ? val.mort_Values.toFixed(2)
                    : parseFloat(val?.car_values?.replace('%', '') || 0).toFixed(2)
            );
            setValue('debttype', 'auto');
            setOption('Auto');
        }
    };

    useEffect(() => {
        const index_type = {
            name: 'indextype',
            label: 'Index Type',
            type: 'select',
            options: [
                { label: 'Prime', value: 'Prime' },
                { label: 'SoFR', value: 'SoFR' },
                { label: 'Other/Custom', value: 'custom' }
            ]
        };
        const paymentType = {
            name: 'paymenttype',
            label: 'Payment Type',
            type: 'select',
            required: false,
            options: [
                { label: 'USD', value: 'USD' },
                { label: 'PCT', value: 'PCT' }
            ]
        };

        const handleFieldsDetail = async () => {
            if (option === 'Credit Card') {
                fieldsDetail[1] = {
                    ...fieldsDetail[1],
                    required: true
                };
                fieldsDetail[2] = {
                    ...fieldsDetail[2],
                    required: false
                };
                fieldsDetail[5] = {
                    ...fieldsDetail[5],
                    required: true
                };
                setFieldsDetail([...fieldsDetail, paymentType]);
                setCreditCardOption('creditcard');
                setValue('paymenttype', 'USD');
            }
            if (debtTypeOptions?.includes(option)) {
                fieldsDetail[1] = {
                    ...fieldsDetail[1],
                    required: true
                };
                fieldsDetail[2] = {
                    ...fieldsDetail[2],
                    required: true
                };
                fieldsDetail[5] = {
                    ...fieldsDetail[5],
                    required: false
                };
                const objectToRemove = {
                    name: 'paymenttype',
                    label: 'Payment Type',
                    type: 'select',
                    options: [
                        { label: 'USD', value: 'USD' },
                        { label: 'PCT', value: 'PCT' }
                    ]
                };
                let indexOfObjectToRemove = fieldsDetail.findIndex((obj) => obj.name === objectToRemove.name);

                if (indexOfObjectToRemove !== -1) {
                    fieldsDetail.splice(indexOfObjectToRemove, 1);
                    setFieldsDetail([...fieldsDetail]);
                }
                setCreditCardOption('');
            }
            if (option === 'Floating') {
                fieldsDetail[3] = {
                    ...fieldsDetail[3],
                    required: false
                };
                setFieldsDetail([...fieldsDetail, index_type]);
                setFloatingOption('floating');
            }
            if (option === 'Fixed') {
                fieldsDetail[3] = {
                    ...fieldsDetail[3],
                    required: true
                };
                let objectToRemove = {
                    name: 'indextype',
                    label: 'Index Type',
                    type: 'select',
                    options: [
                        { label: 'Prime', value: 'Prime' },
                        { label: 'SoFR', value: 'SoFR' },
                        { label: 'Other/Custom', value: 'custom' }
                    ]
                };

                let indexOfObjectToRemove = fieldsDetail.findIndex((obj) => obj.name === objectToRemove.name);

                if (indexOfObjectToRemove !== -1) {
                    fieldsDetail.splice(indexOfObjectToRemove, 1);
                    setFieldsDetail([...fieldsDetail]);
                    setIndexType('');
                    setIndexValue(idxVal);
                    setValue('indextype', '');
                }
                setFloatingOption('');
            }
            if (indexTypeLabels?.includes(option)) {
                let res;
                if (option === 'Other/Custom') {
                    res = await dispatch(fetchIndexTypeValue('custom'));
                } else {
                    res = await dispatch(fetchIndexTypeValue(option.toLowerCase()));
                }
                setIndexValue(res.index_value);
                setIndexType(res.index_type);
            }
            setOption(option);
        };
        handleFieldsDetail();
    }, [option]);

    const onSubmit1 = (data) => {
        const objToSend = [
            {
                debtName: data?.debtName,
                debttype: data?.debttype,
                interestRate: JSON.parse(data?.interestRate),
                maturityYrs: JSON.parse(data?.maturityYrs),
                prinAmt: parseInt(data?.prinAmtUnFormattedValue)
            }
        ];
        dispatch(viewDebtCalulator(objToSend));
        handleClose();
        setOpenOutput(true);
    };

    const onSubmit = (data) => {
        let objToSend = {
            debtName: data.debtName,
            prinAmt: parseFloat(data.prinAmtUnFormattedValue || data.prinAmt),
            debttype: data.debttype,
            fixed_floating: data.fixed_floating || 'fixed'
        };

        // const existingDebtNames = debtdata?.debtDetail?.debt_table?.map((debt) => debt.debtName) || [];

        // if (existingDebtNames?.includes(data.debtName)) {
        //     return toast.error('This Debt Name is already exist.');
        // }
        if (data.maturityYrs) {
            objToSend = {
                ...objToSend,
                maturityYrs: parseFloat(data.maturityYrs)
            };
        }
        if (data.repayType) {
            objToSend = {
                ...objToSend,
                repayType: data.repayType
            };
        }
        if (data.interestRate) {
            objToSend = {
                ...objToSend,
                interestRate: parseFloat(data.interestRate)
            };
        }
        if (data.actual_payUnFormattedValue || data.actual_pay) {
            objToSend = {
                ...objToSend,
                actual_pay: parseFloat(data.actual_payUnFormattedValue || data.actual_pay)
            };
        }
        if (data.indexUnFormattedValue || data.index || indexValue) {
            objToSend = {
                ...objToSend,
                index: String(data.indexUnFormattedValue || data.index || indexValue)
            };
        }
        if (data.marginUnFormattedValue || data.margin) {
            objToSend = {
                ...objToSend,
                margin: String(data.marginUnFormattedValue || data.margin)
            };
        }
        if (indexType) {
            objToSend = {
                ...objToSend,
                index_type: indexType
            };
        }
        if (data.debttype === 'creditcard') {
            objToSend = {
                ...objToSend,
                payment_type: data.paymenttype
            };
        }

        if (data.debttype !== 'creditcard') {
            if (!objToSend.maturityYrs || !objToSend.repayType) {
                return toast.error('Please fill Maturity Years and Repay Type fields.');
            }
        }
        if (objToSend.fixed_floating === 'fixed') {
            if (!objToSend.interestRate) {
                return toast.error('Please fill Interest Rate field.');
            }
        }
        if (objToSend.payment_type === 'PCT') {
            if (objToSend.actual_pay > objToSend.prinAmt) {
                return toast.error('Actual payment should not greater then principal amount.');
            }
        }
        if (indexType === 'custom' && objToSend.fixed_floating === 'floating' && !data.index) {
            return toast.error('Please fill Index field.');
        }
        if (data.margin > 50) {
            return toast.error('Margin should be less then or equal to 50');
        }
        dispatch(viewDebtCalulator([objToSend]));
        handleClose();
        setOpenOutput(true);
    };

    return (
        <>
            {Object.keys(enableChart ?? {}).length > 0 ? (
                <LoanDashChart
                    chartdetails={enableChart}
                    backButtonClicked={() => setEnableChart({})}
                    rangeSelectionClicked={(val) => handleRangeSelection(val)}
                />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card elevation={2}>
                            <Typography variant="subtitle1" className={classes.syncTitle}>
                                The Last Sync occured on {moment.utc(result?.sync_date).local().format('L LT')}
                            </Typography>
                            <CommonTable
                                colSpan={3}
                                HeaderAlign="center"
                                parentHeader={parentHeader}
                                tableColumns={colList}
                                tooltipColumns={{
                                    'Mortgage Rates': tooltip['Mortgage Rates'],
                                    Auto: tooltip['Auto Loan Rate'],
                                    'Student loan - Direct': tooltip['Student Loan Rates'],
                                    'Action': tooltip['Loan Dash Action']
                                }}
                                title={'Latest Loan Rates'}
                                data={result?.data || []}
                                label="Latest Loan Rates"
                                handleLoadData={updateLoanDashboardData}
                                handleSymbolClick={handleSymbolClick}
                                handleIconClick={handleIconClick}
                                customRender={{
                                    // Define custom rendering for specific cells
                                    mort_Values: ({ value }) => {
                                        return value !== '15 YR Fixed Jumbo Mtg' &&
                                            value !== 'Student Loan - Direct' &&
                                            value !== 'Student Loan - Grad' &&
                                            value !== 'Student Loan - Parent' ? (
                                            <CustomButton variant="text" onClick={() => handleSymbolClick(value)}>
                                                {value}
                                            </CustomButton>
                                        ) : (
                                            value // Render value if it's 'Rating/Premium', or 'Maturity'
                                        );
                                    }
                                    // Define custom rendering for other cells if needed
                                }}
                            />
                        </Card>
                    </Grid>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add Debt
                        </CustomDialogTitle>
                        <Divider />
                        <StyledDialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    {fieldsDetail.map((val, i) => {
                                        return (
                                            <Grid item xs={12} sm={6} key={i + 'DebtFormFields'}>
                                                <RenderField
                                                    name={val.name}
                                                    disableEmptyValue={true}
                                                    control={control}
                                                    label={val.label}
                                                    id={val.name}
                                                    type={val.type}
                                                    options={val.options}
                                                    required={val.required || true}
                                                    thousandSeparator={val.commaSep}
                                                    customPatternName={val.custompattern}
                                                    placeholder={val.placeholder}
                                                    setValue={setValue}
                                                    option={option}
                                                    setOption={setOption}
                                                    indexValue={indexValue}
                                                    setIndexValue={setIndexValue}
                                                    indexType={indexType}
                                                    creditCardOption={creditCardOption}
                                                    floatingOption={floatingOption}
                                                    {...val}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <CalCardActions className="contactus-cardaction">
                                    <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                        <Grid item xs={12}>
                                            <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                                Calculate
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </CalCardActions>
                            </form>
                        </StyledDialogContent>
                    </Dialog>
                    <Dialog open={openOutput} onClose={handleCloseOutput} aria-labelledby="form-dialog-title">
                        {!debt?.viewDebtCal?.length && (
                            <>
                                <CustomDialogTitle id="form-dialog-title" onClose={handleCloseOutput}>
                                    Summary Output
                                </CustomDialogTitle>
                                <Divider />
                                <Grid container spacing={2} sx={{ padding: '10px' }}>
                                    {Object.keys(debt.viewDebtCal).map((val, i) => {
                                        return typeof debt.viewDebtCal[val] !== 'object' ? (
                                            <>
                                                <Grid item xs={12} sm={3} key={i + 'budgetsummarytext'} className="widget-grid-item">
                                                    <SummaryTabPaper elevation={3}>
                                                        <Typography variant="subtitle1" className="summary-text">
                                                            {val}
                                                            {tooltip.default?.[val] && (
                                                                <CustomTooltip
                                                                    tooltipText={tooltip.default?.[val]}
                                                                    customTooltipStyle={{ maxWidth: 450 }}
                                                                    label={val}
                                                                />
                                                            )}
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="summary-text"
                                                            sx={{ '& .value-with-signal': { display: 'flex', justifyContent: 'center' } }}
                                                        >
                                                            {/* {debt.viewDebtCal['Debt Ratio'] === debt.viewDebtCal[val] ? (
                                                      CompoColorType !== undefined ? (
                                                          <CompoColorType.name className="value-with-signal">
                                                              <Typography variant="h6">
                                                                  {numberWithCommas(debt.viewDebtCal[val])}
                                                              </Typography>
                                                              <CustomTooltip
                                                                  tooltipText={CompoColorType.msg}
                                                                  imgTooltip={true}
                                                                  img={
                                                                      <img
                                                                          src={`img/Signal-${CompoColorType.imgname}.png`}
                                                                          alt="traffic signal"
                                                                      />
                                                                  }
                                                              />
                                                          </CompoColorType.name>
                                                      ) : (
                                                          ''
                                                      )
                                                  ) : ( */}
                                                            {numberWithCommas(debt.viewDebtCal[val])}
                                                            {/* )} */}
                                                            <br />
                                                            {debt.viewDebtCal['Total Annual Debt Payments'] === debt.viewDebtCal[val] ? (
                                                                <Typography component="span" style={{ color: 'black', fontSize: '12px' }}>
                                                                    *
                                                                    {numberWithCommas(
                                                                        (debt.viewDebtCal[val] / 12).toFixed(2).replace(/\.00$/, '')
                                                                    )}{' '}
                                                                    monthly
                                                                </Typography>
                                                            ) : null}
                                                        </Typography>
                                                        {/* {val.toLowerCase() !== 'debt ratio' && (
                                                  <>
                                                      <Button onClick={() => handleExpandClick(i)} size="small" color="primary">
                                                          {expanded[i]?.bool ? 'Less Info' : 'More Info'}
                                                      </Button>
                                                      {expanded[i]?.bool && (
                                                          <Collapse in={expanded[i]?.bool} timeout="auto" unmountOnExit>
                                                              {Object.keys(debt.viewDebtCal[expanded[i]?.key]).map((k) => (
                                                                  <TableContainer component={Paper}>
                                                                      <Table>
                                                                          <TableBody>
                                                                              <TableRow>
                                                                                  <TableCell align="left">{k}</TableCell>
                                                                                  <TableCell align="right">
                                                                                      {numberWithCommas(
                                                                                          Math.floor(
                                                                                              debt.viewDebtCal[expanded[i]?.key][k]
                                                                                          )
                                                                                      )}
                                                                                      <br />
                                                                                      {debt.viewDebtCal['Annual by Type'][k] ==
                                                                                      debt.viewDebtCal[expanded[i]?.key][k] ? (
                                                                                          <Box
                                                                                              sx={{
                                                                                                  backgroundColor: '#3d3d3d',
                                                                                                  color: 'white',
                                                                                                  borderRadius: '6px',
                                                                                                  width: 'max-content',
                                                                                                  padding: '2px',
                                                                                                  display: 'inline-block'
                                                                                              }}
                                                                                          >
                                                                                              <Typography
                                                                                                  component="span"
                                                                                                  style={{
                                                                                                      color: 'white',
                                                                                                      fontSize: '12px'
                                                                                                  }}
                                                                                              >
                                                                                                  *
                                                                                                  {numberWithCommas(
                                                                                                      (
                                                                                                          Math.floor(
                                                                                                              debt.viewDebtCal[
                                                                                                                  expanded[i]?.key
                                                                                                              ][k]
                                                                                                          ) / 12
                                                                                                      )
                                                                                                          .toFixed(2)
                                                                                                          .replace(/\.00$/, '')
                                                                                                  )}{' '}
                                                                                                  monthly
                                                                                              </Typography>
                                                                                          </Box>
                                                                                      ) : (
                                                                                          ''
                                                                                      )}
                                                                                  </TableCell>
                                                                              </TableRow>
                                                                          </TableBody>
                                                                      </Table>
                                                                  </TableContainer>
                                                              ))}
                                                          </Collapse>
                                                      )}
                                                  </>
                                              )} */}
                                                    </SummaryTabPaper>
                                                </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        );
                                    })}
                                </Grid>
                            </>
                        )}
                    </Dialog>
                </Grid>
            )}
        </>
    );
}

export default LoanDashTable;
