import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ResponsiveBar } from "@nivo/bar";
import styles from "assets/jss/material-kit-react/views/portfolioPage";
import Globals from "Globals.js";
import { numberWithCommas, splitArrayIntoChunksOfLen } from "utils/Utils";
import { abbreviate_number } from "utils/Utils";

const useStyles = makeStyles(styles);

function BarChart({ chartData, marginProp = null, colorProps, legendName, bottomLegendName, ...rest }) {
  const { data, min } = chartData;
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const hasWindow = typeof window !== "undefined";
  const [graphColor, setGraphColor] = useState(Globals.Colors);
  const [chartSetup, setChartSetup] = useState(getWindowDimensions());

  useEffect(() => {
    rest.customColor && setGraphColor(rest.customColor);
  }, []);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    // Adjust dimensions based on screen size
    // ...
    return {
      sliceArrCount: 3,
      legendHeight: 5,
      xAxisTickSlice: (data.length <= 4 && 0) || Math.round(data.length / 10),
      tickRotation: 0,
      marginBottom: 90,
    };
  }

  // Handle window resize for responsiveness
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setChartSetup(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  // Create modified data with unique keys for duplicate labels
  const processedData = data.map((item, index) => ({
    ...item,
    uniqueLabel: `${item.label}__${index}`,
  }));

  /**
   * Tooltip component
   */
  const CustomTooltip = (data) => {
    if (data) {
      let value = data.value;
      if (!isNaN(data.value) && data.value.toString().indexOf(".") !== -1) {
        value = data.value.toFixed(2);
      }
      const currValue = numberWithCommas(value);
      return (
        <div className={classes.customBarChartTooltip}>
          <div className={classes.barChartInnerParent}>
            <span
              className={classes.colorBox}
              style={{ background: data.color }}
            ></span>
            <span>
              <strong>{data.id}</strong>
            </span>
          </div>
          <p className={classes.tooltipLabel}>
            {data.indexValue.split("__")[0]} : <strong>{currValue}</strong>
          </p>
        </div>
      );
    }
    return null;
  };

  const legendProps = {
    anchor: "bottom",
    direction: "row",
    itemWidth: rest.divideLegend ? 50 : 120,
    translateX: 0,
    translateY: 73,
    itemHeight: chartSetup.legendHeight,
    itemsSpacing: 0,
    itemOpacity: 0.8,
    symbolShape: "square",
  };

  const chartKey = data.map(({ label, ...rest }) => Object.keys(rest))[0];

  return (
    <ResponsiveBar
      data={processedData}
      indexBy="uniqueLabel"
      keys={chartKey}
      margin={marginProp || { top: 20, right: 20, bottom: chartSetup.marginBottom, left: 30 }}
      padding={0.3}
      groupMode={rest.groupMode}
      minValue={min < 0 ? parseInt(min / 10, 10) * 10 - 10 : "auto"}
      valueFormat={{ format: " >-.2f", enabled: true }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={colorProps || Globals.Colors}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      tooltip={(val) => CustomTooltip(val)}
      axisBottom={{
        format: (value) => value.split("__")[0],
        tickSize: 5,
        tickPadding: 5,
        legend: bottomLegendName,
        tickRotation: chartSetup.tickRotation,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        format: (tick) => (rest.formatYAxis ? numberWithCommas(abbreviate_number(tick)) : tick),
        tickSize: 5,
        legend: legendName || "",
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          ...legendProps,
          data: chartKey.map((key, i) => ({
            id: key,
            label: key,
            color: Globals.Colors[i],
          })),
        },
      ]}
    />
  );
}

BarChart.defaultProps = {
  minVal: 0,
  legendName: "",
  colorProps: null,
  bottomLegendName: "",
  enableLegend: true,
  groupMode: "grouped",
  formatYAxis: true,
};

export default React.memo(BarChart);
