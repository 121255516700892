import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledTableSlider } from 'assets/jss/GeneralStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { smartTblRecurring } from 'redux/actions/RecurringPaymentActions';
import { smartTblRecurringError } from 'redux/actions/RecurringPaymentActions';
import RenderField from 'utils/RenderField';
import { abbreviate_number, dynamicSort, numberWithCommas } from 'utils/Utils';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import todoPageStyle from 'assets/jss/material-kit-react/views/todoPage';
import { TodoForm } from 'views/Todo/TodoForm';
import { submitTodo, submitTodoError } from "redux/actions/TodoActions";
import { useSelector } from "react-redux";


const todoUseStyles = makeStyles(todoPageStyle);
export const includes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id] || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
        });
    });
};

includes.autoRemove = (val) => !val || !val.length;
export const RecurringSummary = (props) => {
    const [enableEdit, setEnableEdit] = useState({});
    const [updatedVal, setUpdatedVal] = useState({});
    const { data, classes, dispatch, searchcontainer } = props;
    const [editTodoPopup, setEditTodoPopup] = useState({});
    const todoClasses = todoUseStyles();
    const [addTodoPopup, setAddTodoPopup] = useState({ open: false, name: "" });
    const todoProps = useSelector((state) => state.todo);


    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (props.recurringPayment?.submitSmartTblSuccess) {
            setEnableEdit({});
            setUpdatedVal({});
            props.setInputRenderList(true);
            dispatch(smartTblRecurringError());
        }
    }, [props.recurringPayment?.submitSmartTblSuccess]);
    // method will call when tick mark will click after editing data done
    const onSubmit = (val) => {

        const objToSend = {
            recurringFileId: enableEdit.id,
            name: val['name'] || null,
            category: val['category'] || "",
            pay_type: val['pay_type'] || null,
            monthly_amount: parseFloat(val.monthly_amountUnFormattedValue) || parseFloat(val.monthly_amount.replace(/,/g, '')) || 0,
            auto_pay_account_name: val['auto_pay_account_name'] || null,
            payment_period: val['payment_period'] || null,
            auto_renewal: val['auto_renewal'] || null,
            importance: val['importance'] || null,
            comment: val['comment'] || null,
            contact: val['contact'] || null
        };
        // call the update api
        dispatch(smartTblRecurring(objToSend, 'put', props.selectedBudgetCount));
        reset();
        setEnableEdit({});
    };
    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id] || '-');
            });
            let list = [...options.values()];
            list = list.map((option, i) => ({ value: option, label: option }));
            list.unshift({ value: 'all', label: 'All' });
            return list;
        }, [id, preFilteredRows]);
        // Render a multi-select box
        return (
            <CustomSelect
                disableEmptyValue={true}
                defaultValue={filterValue || 'all'}
                formControlProps={{
                    fullWidth: true
                }}
                options={options}
                onChange={(e) => {
                    setFilter(e.target.value || null);
                }}
                size="small"
            />
        );
    }
    // Define a custom filter filter function!
    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue >= filterValue;
        });
    }
    // This is a custom filter UI that uses a
    // slider to set the filter value between a column's
    // min and max values
    function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
        // Calculate the min and max
        // using the preFilteredRows

        const [min, max] = useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] ?? 0 : 0;
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] ?? 0 : 0;
            preFilteredRows.forEach((row) => {
                min = Math.min(row.values[id] ?? 0, min);
                max = Math.max(row.values[id] ?? 0, max);
            });
            return [min, max];
        }, [id, preFilteredRows]);
        return (
            <>
                <div className={classes.sliderWrapperFilter}>
                    <StyledTableSlider
                        id={id}
                        key={id}
                        value={filterValue || min}
                        min={min}
                        max={max}
                        onChange={(e, val) => setFilter(parseInt(val, 10))}
                    />
                    <span className={classes.sliderValue}>{abbreviate_number(filterValue || min)}</span>
                </div>
                <CustomButton className={classes.offBtn} variant="contained" size="xs" onClick={() => setFilter(undefined)}>
                    Off
                </CustomButton>
            </>
        );
    }
    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange'
    });
    const requiredFields = ["Name", "Monthly Amount", "Importance"];
    // Create an editable cell renderer
    const EditableCell = (initialValue, type, row, cell, options = [], extra = { commaSep: true }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        const [value, setCellValue] = useState(updatedVal);

        switch (type) {
            case 'number':
                custompattern = 'decimalRate';
                valToPass = extra.commaSep
                    ? !isNaN(initialValue) && initialValue?.toString().indexOf('.') !== -1
                        ? numberWithCommas(parseFloat(initialValue)?.toFixed(2))
                        : numberWithCommas(initialValue)
                    : initialValue;
                break;
            case 'select':
                valToPass = initialValue?.toString().toLowerCase();
                break;
            case 'searchSelect':
                valToPass = initialValue;
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        const id = [row.original.id, cell.column.id].join('_');
        // Check to make sure not all columns are editable
        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={cell.column.id}
                type={type}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.flag !== '' ? (
                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                            ) : (
                                ''
                            )}
                            <Typography variant="body2">{option.label}</Typography>
                        </Box>
                    );
                }}
                fieldName={cell.column.id}
                options={options}
                control={control}
                required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                {...extra}
            />
        );
    };
    const deleteSelectedData = (ids) => dispatch(smartTblRecurring([ids], 'delete', props.selectedRecurringCount));

    const columns = useMemo(() => {
        const result = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            },
            {
                Header: 'Action',
                width: 100,
                Cell: ({ row }) => {
                    if (enableEdit.id === row.original.id) {
                        return (
                            <div className={'actionWrapper'}>
                                <Tooltip title="Cancel">
                                    <IconButton aria-label="cancel" onClick={(e) => setEnableEdit({})}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div className={'actionWrapper'}>
                                <Tooltip title="Delete">
                                    <IconButton
                                        disabled={row.original?.['Name']}
                                        aria-label="delete"
                                        onClick={() => deleteSelectedData(row.original.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                    <IconButton
                                        disabled={row.original?.['Name']}
                                        aria-label="edit"
                                        onClick={(e) => {
                                            reset();
                                            setUpdatedVal({});
                                            setEnableEdit(row.original);
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    }
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                sticky: true,
                width: 150,

                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: 'descriptionString' })
                        : value || '-'
            },
            {
                Header: 'Category',
                accessor: 'category',
                width: 150,
                Filter: SelectColumnFilter,
                filter: includes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'text', row, cell, { custompattern: 'descriptionString' })
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Pay Type',
                accessor: 'pay_type',
                width: 150,
                Filter: SelectColumnFilter,
                filter: includes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, [
                                    { value: 'auto pay', label: 'Auto Pay' },
                                    { value: 'online', label: 'Online' },
                                    { value: 'check', label: 'Check' }
                                ])
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Monthly Amount',
                accessor: 'monthly_amount',
                width: 170,

                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, [])
                        : numberWithCommas(parseFloat(value)?.toFixed(2)) || '-'
            },
            {
                Header: 'Auto Pay Account Name',
                accessor: 'auto_pay_account_name',
                width: 150,

                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: 'descriptionString' })
                        : value || '-'
            },
            {
                Header: 'Payment Period',
                accessor: 'payment_period',
                width: 160,
                Filter: SelectColumnFilter,
                filter: includes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, [
                                    { value: 'monthly', label: 'Monthly' },
                                    { value: 'quaterly', label: 'Quaterly' },
                                    { value: 'annualy', label: 'Annualy' }
                                ])
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Auto Renewal',
                accessor: 'auto_renewal',
                Filter: SelectColumnFilter,
                filter: includes,
                width: 170,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, [
                                    { value: 'yes', label: 'Yes' },
                                    { value: 'no', label: 'No' }
                                ])
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Importance',
                accessor: 'importance',
                width: 150,
                Filter: SelectColumnFilter,
                filter: includes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, [
                                    { value: '1', label: '1' },
                                    { value: '2', label: '2' },
                                    { value: '3', label: '3' },
                                    { value: '4', label: '4' },
                                    { value: '5', label: '5' },
                                    { value: '6', label: '6' },
                                    { value: '7', label: '7' },
                                    { value: '8', label: '8' },
                                    { value: '9', label: '9' },
                                    { value: '10', label: '10' },
                                ])
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Comments',
                accessor: 'comment',
                width: 150,

                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: 'descriptionString' })
                        : value || '-'
            },
            {
                Header: 'Contact',
                accessor: 'contact',
                width: 150,

                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: 'descriptionString' })
                        : value || '-'
            },
            {
                Header: 'Todo Task',
                accessor: 'todo_task',
                width: 120,

                Cell: ({ value, row, cell }) => {
                    return (
                        <div className={'actionWrapper'}>
                            <Tooltip title="Add TODO Task">
                                <IconButton aria-label="Add TODO Task" onClick={() => handleEditTodo(row.original)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
        return result;
    }, [enableEdit, updatedVal, data]);

    const handleEditTodo = (data) => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 7);
        const updatedData = {
            ...data,
            category: { id: "dnVd", name: "Budget" },
            priority: 'high',
            due_date: currentDate
        };
        setEditTodoPopup(updatedData);
    };

    const handleCloseTodoForm = () => {
        setEditTodoPopup({});
    };

    const categoryList = [
        { id: "dnVd", name: "Budget" },
    ]

    return (
        <>
            {data?.length > 0 ? (
                <Grid item xs={12} sm={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <EnhancedTable
                            container={props?.container}
                            enableSearch={true}
                            columns={columns}
                            data={data}
                            enableSort={true}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            searchcontainer={searchcontainer}
                        // addDataDialogCompo={AddUserDialog}
                        />
                    </form>
                    {Object.keys(editTodoPopup).length ? (
                        <TodoForm
                            addTodoPopup={addTodoPopup}
                            edit={editTodoPopup}
                            updateTodo={(e) => dispatch(submitTodo(e))}
                            resetSubmitTodoFlag={(e) => dispatch(submitTodoError())}
                            submitTodoSuccess={todoProps.submitTodoSuccess}
                            categoryList={categoryList}
                            handleClose={handleCloseTodoForm}
                            classes={todoClasses}
                            status_dropdown={true}>
                        </TodoForm>
                    ) : (
                        ""
                    )}
                </Grid>
            ) : (
                <Grid item xs={12} sm={12} className={classes.summaryBudgetGridItem}>
                    <Card>
                        <CardContent id="outputWrapper">
                            <NoDataFound />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </>
    );
};
