import {
    Box,
    Button,
    CardContent,
    CardMedia,
    Dialog,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle';
import Loader from 'components/CommonComponent/Loader';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import 'suneditor/dist/css/suneditor.min.css';
import moment from 'moment';
import { fetchDashboardBlogById } from 'redux/actions/BlogTypes';
import AdjustIcon from '@mui/icons-material/Adjust';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { BlogBGImageBox } from 'assets/jss/BlogStyle';
import { Close } from '@mui/icons-material';
import { FooterFormControl } from 'assets/jss/FooterStyle';
import { toast } from 'react-toastify';
import { getCookie } from 'utils/Utils';
import { setCookie } from 'utils/Utils';
import { submitNewsletter } from 'redux/actions/GeneralCalActions';
import { submitProfileSettings } from 'redux/actions/SettingActions';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { colorScale } from 'utils/Utils';
import { franc } from 'franc';
import Meta from 'components/meta';
import { ArticlePage } from 'components/meta';

const extractBase64Image = (htmlString) => {
    const regex = /<img.*?src="data:image\/(.*?);base64,(.*?)".*?>/g;
    const matches = regex.exec(htmlString);

    if (matches && matches.length >= 3) {
        return `data:image/${matches[1]};base64,${matches[2]}`;
    }
    return null;
};

const Blog = () => {
    const dispatch = useDispatch();
    const { dahsboardBlogDetails, loader } = useSelector((state) => state.blogReducer);
    const setting = useSelector((state) => state.setting);
    const { blogId } = useParams();
    const apiToken = getCookie('auth_token');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [blogsemail, setBlogsEmail] = useState('');
    const [show, setShow] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const researchTable = dahsboardBlogDetails?.research_table_data;
    const blogs = localStorage.getItem('myBlog');
    const [totalDuration, setTotalDuration] = useState(0);
    const showDialog = () => {
        // setTimeout(() => {
        setShow(true);
        // }, 5000);
    };
    const closeDialog = () => {
        setShow(false);
    };

    const handleChangeNewseltter = (e) => {
        setBlogsEmail(e.target.value);
    };

    useEffect(() => {
        if (apiToken) {
            if (setting.profileDetail.blog_notify === 'Off') {
                showDialog();
            }
        } else {
            if (!getCookie('blog')) {
                showDialog();
            }
        }
    }, [setting.profileDetail]);

    const handleNewsSeltterSubmit = () => {
        const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        if (blogsemail.toString().match(emailReg)) {
            if (apiToken) {
                dispatch(submitProfileSettings({ blog_email_notify: true }));
            } else {
                dispatch(submitNewsletter({ email: blogsemail }));
                setCookie('blog', blogsemail);
            }

            setShow(false);
            setBlogsEmail('');
        } else {
            toast.error('Please enter valid email id.');
        }
    };

    useEffect(() => {
        const matchedBlog = JSON.parse(blogs)?.find(
            (blog) =>
                blog?.title
                    .toLowerCase()
                    .replace(/\s+/g, '-')
                    .replace(/[^a-z0-9\-]/g, '') === blogId
        );
        if (matchedBlog) {
            dispatch(fetchDashboardBlogById(matchedBlog?.id));
        }
    }, [blogs, blogId]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    const theme = useTheme();

    // ------------------------Table -----------------------

    const data = researchTable?.table_data || [];
    // Function to format numbers
    const formatNumber = (value) => {
        const numberValue = Number(value);

        if (isNaN(numberValue)) return value;

        // Format the number to 2 decimal places if it's a floating point number
        let formattedValue = numberValue.toFixed(2);

        // Add commas for numbers greater than 1000
        if (numberValue > 1000) {
            formattedValue = Number(formattedValue).toLocaleString();
        }

        return formattedValue;
    };

    // Initialize heatmapEnabled state with all columns enabled
    const initialHeatmapState = useMemo(() => {
        const initialState = {};
        Object.keys(researchTable?.table_data[0] ?? {}).forEach((key) => {
            initialState[key] = true;
        });
        return initialState;
    }, [researchTable?.table_data]);

    const [heatmapEnabled, setHeatmapEnabled] = useState({ initialHeatmapState });
    const toggleHeatmap = (key, event) => {
        event.stopPropagation();
        setHeatmapEnabled((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const convertStringToNumber = (value) => {
        if (typeof value === 'string') {
            value = value.replace(/,/g, '');
            if (value.includes('%')) {
                value = value.replace('%', '');
                const parsedValue = parseFloat(value);
                if (!isNaN(parsedValue)) {
                    return Math.round(parsedValue) + '%';
                }
            } else {
                const parsedValue = parseFloat(value);
                if (!isNaN(parsedValue)) {
                    return Math.round(parsedValue);
                }
            }
        }
        return value;
    };

    const processResponseData = (data) => {
        return data.map((row) => {
            const newRow = {};
            for (const key in row) {
                newRow[key] = convertStringToNumber(row[key]);
            }
            return newRow;
        });
    };

    const columns = useMemo(() => {
        const processedData = processResponseData(data);
        return Object.keys(researchTable?.table_data[0] ?? {}).map((key, index) => {
            const hasNumericValues = processedData.some((row) => {
                const value = row[key];
                return typeof value === 'number' || (typeof value === 'string' && value.includes('%'));
            });
            const columnData = data.map((row) => row[key]);

            return {
                accessorKey: key,
                header: (
                    <Box display="flex" alignItems="center">
                        <span>{key}</span>
                        {hasNumericValues && (
                            <Button size="small" onClick={(event) => toggleHeatmap(key, event)} sx={{ marginLeft: '8px' }}>
                                {heatmapEnabled[key] ? (
                                    <Tooltip title="Disable HeatMap">
                                        <IconButton aria-label="close">
                                            <AdjustIcon style={{ color: 'green' }} />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Enable HeatMap">
                                        <IconButton aria-label="close">
                                            <AdjustIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Button>
                        )}
                    </Box>
                ),
                visibleInShowHideMenu: false,
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    return (
                        <Box
                            sx={{
                                backgroundColor: heatmapEnabled[key] ? colorScale(columnData, value, false, true) : 'transparent',
                                padding: '8px',
                                borderRadius: '4px',
                                color: 'black'
                            }}
                        >
                            {formatNumber(value)}
                        </Box>
                    );
                }
            };
        });
    }, [researchTable?.table_data, heatmapEnabled]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnPinning: true,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enablePagination: false,
        enableStickyHeader: true,
        enableBottomToolbar: false,
        enableSortingRemoval: false,
        visibleInShowHideMenu: false,
        enableHiding: false,

        initialState: {
            columnPinning: { left: [columns[0]?.accessorKey] } // Pin the first column
        },

        onTableStateChange: (state) => {
            if (!state.columnPinning.left.includes(columns[0].accessorKey)) {
                state.columnPinning.left.unshift(columns[0].accessorKey);
            }
        },
        renderColumnActionsMenuItems: ({ internalColumnMenuItems, visibleInShowHideMenu }) => {
            return [internalColumnMenuItems[4], internalColumnMenuItems[6]];
        },
        renderTopToolbarCustomActions: () => <Typography variant="h3">{researchTable?.table_name}</Typography>,

        muiTableHeadCellProps: () => ({
            sx: {
                backgroundColor: 'rgb(225, 242, 254)',
                color: 'black',
                ':before': {
                    backgroundColor: 'rgb(225, 242, 254) !important'
                }
            }
        }),

        muiTableBodyCellProps: ({ cell, column, row }) => ({
            sx: {
                backgroundColor: row.index % 2 === 0 ? '#dddddd' : 'transparent',
                ':before': {
                    backgroundColor: row.index % 2 === 0 ? '#dddddd !important' : 'transparent'
                }
            }
        })
    });

    // useEffect(() => {
    //     const synth = window.speechSynthesis;

    //     return () => {
    //         if (isSpeaking) {
    //             synth.cancel();
    //         }
    //     };
    // }, [isSpeaking]);

    useEffect(() => {
        // This is a safeguard to ensure the column pinning is applied correctly
        if (columns.length > 0 && !table.getState().columnPinning.left.includes(columns[0]?.accessorKey)) {
            table.setState((prevState) => ({
                ...prevState,
                columnPinning: { left: [columns[0]?.accessorKey] }
            }));
        }
    }, [columns, table]);

    const languageMap = {
        eng: 'en', // English
        fra: 'fr', // French
        deu: 'de', // German
        spa: 'es', // Spanish
        por: 'pt', // Portuguese
        tur: 'tr', // Turkish
        ita: 'it', // Italian
        rus: 'ru', // Russian
        hin: 'hi', // Hindi
        jpn: 'ja', // Japanese
        zho: 'zh', // Chinese
        cat: 'ca', // Catalan
        sqi: 'sq' // Albanian
    };

    // Utility function to strip HTML tags
    const stripHTML = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    useEffect(() => {
        if (dahsboardBlogDetails?.content) {
            const text = stripHTML(dahsboardBlogDetails.content);
            const chunksArray = text.match(/[^\.!\?]+[\.!\?]+/g) || [];

            const words = text.split(/\s+/).length;
            const wordsPerMinute = 150;
            const estimatedDuration = Math.ceil((words / wordsPerMinute) * 60);
            setTotalDuration(estimatedDuration);
        }
    }, [dahsboardBlogDetails]);

    // const handleSliderChange = (event, newValue) => {
    //     const synth = window.speechSynthesis;
    //     synth.cancel();

    //     const newIndex = Math.floor((newValue / totalDuration) * chunks.length);
    //     setCurrentIndex(newIndex);
    //     setElapsedTime(newValue);

    //     if (isSpeaking) {
    //         handleSpeakerClick(); // Restart speaking from the new position
    //     }
    // };

    // Timer functionality with useEffect
    useEffect(() => {
        let timer;
        if (isSpeaking && !isPaused) {
            timer = setInterval(() => {
                setElapsedTime((prev) => prev + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isSpeaking, isPaused]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleSpeakerClick = () => {
        const synth = window.speechSynthesis;

        if (isSpeaking) {
            synth.cancel();
            setIsSpeaking(false);
            setIsPaused(false);
            setCurrentIndex(0);
            setElapsedTime(0);
            return;
        }

        const text = stripHTML(dahsboardBlogDetails?.content);
        const chunks = text.match(/[^\.!\?]+[\.!\?]+/g);

        if (chunks) {
            const detectedLangCode = franc(text);
            const lang = languageMap[detectedLangCode] || 'en';

            const speakChunks = (index) => {
                if (index < chunks.length) {
                    const utterance = new SpeechSynthesisUtterance(chunks[index]);
                    utterance.lang = lang;

                    utterance.onend = () => {
                        if (!isPaused) {
                            if (index + 1 < chunks.length) {
                                setCurrentIndex(index + 1);
                                speakChunks(index + 1);
                            } else {
                                // End of content: reset states
                                setIsSpeaking(false);
                                setCurrentIndex(0);
                                setElapsedTime(0);
                            }
                        }
                    };

                    synth.speak(utterance);
                } else {
                    setIsSpeaking(false);
                    setCurrentIndex(0);
                    setElapsedTime(0);
                }
            };

            speakChunks(currentIndex);
            setIsSpeaking(true);
            setIsPaused(false);
        }
    };

    const handlePauseClick = () => {
        const synth = window.speechSynthesis;

        if (isPaused) {
            synth.resume();
            setIsPaused(false);
        } else {
            synth.pause();
            setIsPaused(true);
        }
    };

    const imageSrc = extractBase64Image(dahsboardBlogDetails?.content);

    return (
        <MainBox style={{ padding: '0px' }}>
            <Meta
                title="Blog | TPE Hub"
                description="Explore the latest articles and updates on TPE Hub. Stay informed with our in-depth blog posts on industry trends and insights."
                page="/knowledge-and-research/blog"
            />
            <ArticlePage
                title={dahsboardBlogDetails?.title}
                description={dahsboardBlogDetails?.content}
                image={dahsboardBlogDetails?.image_url}
                url={`https://tpehub.com/knowledge-and-research/blog/${dahsboardBlogDetails?.title
                    .toLowerCase()
                    .replace(/\s+/g, '-')
                    .replace(/[^a-z0-9\-]/g, '')}`}
            />
            <div>
                <BlogBGImageBox
                    backgroundImage={
                        dahsboardBlogDetails?.image_url !== 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                            ? dahsboardBlogDetails?.image_url
                            : imageSrc
                            ? imageSrc
                            : 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
                    }
                >
                    <Typography
                        variant="h1"
                        sx={{
                            position: 'relative',
                            top: windowWidth < 768 ? '0%' : '70%',
                            color: 'white',
                            margin: '10px',
                            padding: '15px',
                            backdropFilter: 'blur(2px)',
                            borderRadius: '4px'
                        }}
                    >
                        {dahsboardBlogDetails?.title}
                    </Typography>
                </BlogBGImageBox>
                <Box
                    sx={{
                        padding: '10px 10px 10px 22px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        background: theme.palette.grey[300],
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                        gap: '25%',
                        '@media (max-width: 767px)': {
                            gap: '0%',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }
                    }}
                >
                    <Box
                        sx={{
                            '@media (max-width: 767px)': { order: '2' },
                            width: '300px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            bgcolor: 'rgb(245, 245, 245)',
                            borderRadius: '30px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <Box display="flex" justifyContent="space-around" width="100%" alignItems="center">
                            <Tooltip title={isSpeaking ? 'Stop Reading' : 'Read Aloud'}>
                                <IconButton onClick={handleSpeakerClick}>
                                    {isSpeaking ? <StopCircleIcon /> : <PlayCircleFilledIcon />}
                                </IconButton>
                            </Tooltip>
                            {/* <Button
                                variant="contained"
                                sx={{
                                    minWidth: '30px',
                                    height: '30px',
                                    backgroundColor: !isSpeaking ? 'rgb(111 116 125)' : '#163366',
                                    color: 'white',
                                    borderRadius: '50%',
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: '#163366'
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={isSpeaking ? handlePauseClick : handleSpeakerClick}
                            > */}
                            {isSpeaking && (
                                <Tooltip title={isPaused ? 'Resume' : 'Pause'}>
                                    <IconButton onClick={handlePauseClick}>{isPaused ? <PlayArrowIcon /> : <PauseIcon />}</IconButton>
                                </Tooltip>
                            )}
                            {/* {isSpeaking ? isPaused ? <PlayArrowIcon /> : <PauseIcon /> : <VolumeUpIcon />} */}
                            {/* </Button> */}
                            <Typography variant="caption">{formatTime(elapsedTime)}</Typography>

                            {/* <Slider
                                value={elapsedTime}
                                onChange={handleSliderChange}
                                min={0}
                                max={totalDuration}
                                aria-labelledby="progress-slider"
                                sx={{ width: '50%' }}
                            /> */}
                            <Box
                                sx={{
                                    height: '6px',
                                    width: '150px',
                                    bgcolor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '5px',
                                    position: 'relative',
                                    overflow: 'hidden'
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: `${(elapsedTime / totalDuration) * 100}%`,
                                        bgcolor: '#163366',
                                        position: 'absolute',
                                        borderRadius: '5px'
                                    }}
                                />
                            </Box>
                            {/* <Typography variant="caption" color="text.secondary">
                                {formatTime(totalDuration)}
                            </Typography> */}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            '@media (max-width: 767px)': {
                                flexDirection: 'column'
                            }
                        }}
                        display="flex"
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                padding: '0px 10px',
                                borderRight: '1px solid black',
                                color: theme.palette.grey[400],
                                '@media (max-width: 767px)': {
                                    border: 'none'
                                }
                            }}
                            style={{ textTransform: 'capitalize' }}
                        >
                            Category : {dahsboardBlogDetails?.category_name.join(', ') || '-'}
                        </Typography>
                        <Typography variant="h6" sx={{ padding: '0px 10px', color: theme.palette.grey[400] }}>
                            {/* Published on {moment(dahsboardBlogDetails?.published_at, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY') || '12/12/2012'} */}
                            Published Date :{' '}
                            {dahsboardBlogDetails?.published_at
                                ? moment(dahsboardBlogDetails.published_at, 'DD-MM-YYYY HH:mm:ss').format('MM/DD/YYYY')
                                : 'Not Published Yet'}
                        </Typography>
                    </Box>
                </Box>
                <div
                    className="sun-editor-editable se-ltr"
                    style={{ padding: '40px' }}
                    dangerouslySetInnerHTML={{ __html: dahsboardBlogDetails?.content }}
                />

                {researchTable ? (
                    <Box sx={{ width: '90%', margin: 'auto' }}>
                        <MaterialReactTable table={table} enableHiding={false} />
                    </Box>
                ) : (
                    ''
                )}
                {show && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: '20px',
                            left: '480px',
                            width: '295px',
                            borderRadius: '16px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            background: 'white',
                            padding: '16px',
                            color: 'white',
                            zIndex: 1300,
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-10px',
                                left: '130px',
                                width: '20px',
                                height: '20px',
                                background: 'white',
                                transform: 'rotate(45deg)',
                                boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.1)',
                                borderRadius: '2px'
                            }
                        }}
                    >
                        <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                            <IconButton onClick={closeDialog} sx={{ color: 'black' }}>
                                <Close />
                            </IconButton>
                        </Box>

                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
                            Hey, TPE-HUB Reader
                        </Typography>
                        <Typography variant="subtitle2" sx={{ pb: 1, fontWeight: 500, color: 'black' }}>
                            Enter your email here to be notified of our next insightful article.
                        </Typography>

                        {/* Input for Email */}
                        <FooterFormControl
                            sx={{
                                borderRadius: '5px',
                                width: '100%',
                                backgroundColor: 'white',
                                boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <OutlinedInput
                                type="email"
                                value={blogsemail}
                                onChange={handleChangeNewseltter}
                                placeholder="Your Email"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#ffc107',
                                                color: '#000',
                                                ':hover': {
                                                    backgroundColor: '#ffca28'
                                                }
                                            }}
                                            onClick={handleNewsSeltterSubmit}
                                        >
                                            Subscribe
                                        </Button>
                                    </InputAdornment>
                                }
                            />
                        </FooterFormControl>

                        <Typography variant="caption" sx={{ color: 'red', fontWeight: 'bold', mt: 1, display: 'block' }}>
                            *Don't worry, we don’t spam.
                        </Typography>
                    </Box>
                )}
            </div>
            {loader && <Loader />}
        </MainBox>
    );
};

export default Blog;
