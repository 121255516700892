import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { recuringFiles, selectedRecurringDetail } from "RecurringPayment.json"

//handle success of api
export const uploadRecurringFileSuccess = (val) => {
  return {
    type: ActionTypes.UPLOAD_RECURRING_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const uploadRecurringFileError = () => {
  return {
    type: ActionTypes.UPLOAD_RECURRING_FILE_ERROR
  };
};
export const uploadRecurringFile = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.UPLOAD_RECURRING_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurring_payment_upload`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success("Your data has been saved Successfully");
      dispatch(uploadRecurringFileSuccess(apiResponse.data.data));
      dispatch(getRecurringFiles());
    } else {
      toast.error(apiResponse.data.message);
      handleError(apiResponse, dispatch);
      dispatch(uploadRecurringFileError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(uploadRecurringFileError());
    throw error;
  }
};

//handle success of api
export const getRecurringFilesSuccess = (val) => {
  return {
    type: ActionTypes.GET_RECURRING_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const getRecurringFilesError = () => {
  return {
    type: ActionTypes.GET_RECURRING_FILE_ERROR
  };
};
export const getRecurringFiles = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.GET_RECURRING_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurring_payment_upload`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(getRecurringFilesSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(getRecurringFilesSuccess(recuringFiles));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(getRecurringFilesError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(getRecurringFilesError());
    throw error;
  }
};

//handle success of api
export const fetchSelectedRecurringSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_SELECTED_RECURRING_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchSelectedRecurringError = () => {
  return {
    type: ActionTypes.FETCH_SELECTED_RECURRING_FILE_ERROR
  };
};
export const fetchSelectedRecurring = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.FETCH_SELECTED_RECURRING_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurring_payment_calc`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchSelectedRecurringSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchSelectedRecurringSuccess(selectedRecurringDetail));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchSelectedRecurringError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchSelectedRecurringError());
    throw error;
  }
};

//handle success of api
export const deleteRecurringFilesSuccess = (val) => {
  return {
    type: ActionTypes.DELETE_RECURRING_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const deleteRecurringFilesError = () => {
  return {
    type: ActionTypes.DELETE_RECURRING_FILE_ERROR
  };
};
export const deleteRecurringFiles = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.DELETE_RECURRING_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurring_payment_upload`, 'delete', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(deleteRecurringFilesSuccess());
      dispatch(getRecurringFiles());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteRecurringFilesError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteRecurringFilesError());
    throw error;
  }
};

//handle success of api
export const smartTblRecurringSuccess = () => {
  return {
    type: ActionTypes.SMART_RECURRING_SUCCESS
  };
};

// handle api error
export const smartTblRecurringError = () => {
  return {
    type: ActionTypes.SMART_RECURRING_ERROR
  };
};
//api to submit/delete/update data in holding table
export const smartTblRecurring =
  (data, type, ids = []) =>
    async (dispatch) => {
      try {
        // handle loader when api started to call
        dispatch({
          type: ActionTypes.SMART_RECURRING_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurring_payment_calc`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
          toast.success(apiResponse.data.data.message);
          dispatch(smartTblRecurringSuccess());
        } else {
          handleError(apiResponse, dispatch);
          dispatch(smartTblRecurringError());
        }
      } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(smartTblRecurringError());
        throw error;
      }
    };

//handle success of api
export const submitRecurringSuccess = (val) => {
  return {
      type: ActionTypes.SUBMIT_RECURRING_SUCCESS,
      data: val
  };
};

// handle api error
export const submitRecurringError = () => {
  return {
      type: ActionTypes.SUBMIT_RECURRING_ERROR
  };
};
export const submitRecurring = (data, type) => async (dispatch) => {
  try {
      dispatch({
          type: ActionTypes.SUBMIT_RECURRING_INIT
      });
      const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurringpaymentsingleupload`, 'post', data);
      if (apiResponse !== undefined && apiResponse.status === 200) {
          dispatch(submitRecurringSuccess(true));
          dispatch(getRecurringFiles());
          return toast.success(apiResponse.data.data.message);
      } else {
          handleError(apiResponse, dispatch);
          dispatch(submitRecurringError());
      }
  } catch (error) {
      toast.error(Globals.errorMsg);
      dispatch(submitRecurringError());
      throw error;
  }
};



//handle success of api
export const getRecurringCategorySuccess = (val) => {
  return {
    type: ActionTypes.GET_RECURRING_CATEGORY_SUCCESS,
    data: val
  };
};
// handle api error
export const getRecurringCategoryError = () => {
  return {
    type: ActionTypes.GET_RECURRING_CATEGORY_ERROR
  };
};
export const getRecurringCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.GET_RECURRING_CATEGORY_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}recurringpaymentcategory`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(getRecurringCategorySuccess(apiResponse.data.data));
    }else {
        handleError(apiResponse, dispatch);
        dispatch(getRecurringCategoryError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(getRecurringCategoryError());
    throw error;
  }
};
