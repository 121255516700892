import React, { Fragment } from "react";
import {Box, Card, CardContent, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import NoDataFound from "components/CommonComponent/NoDataFound";
import { OutputBox } from "assets/jss/GeneralStyle";
import { numberWithCommas } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import * as tooltip from 'Tooltip.json';

const HomeValuationOutput = (props) => {
  return (
      <Card elevation={2} sx={{ mb: 3.5 }}>
          <CardContent>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                  Summary Output
              </Typography>
              <Box id="outputWrapper" className="widget-full-width">
                  {Object.keys(props.homeValuationData.homeValuationDetail).length ? (
                      <Grid container spacing={2}>
                          {props.homeValuationData.homeValuationDetail.data.map((val, i) => {
                              const currValue = val.value;
                              const allowedPrefix = (val.label === 'Calculated Output');
                              const allowedCurrPrefix = (val.label === 'Current Home Value' || val.label === 'Current Home Value - Adjusted');
                              return (
                                  <Fragment key={i + 'HomeValuationOutput'}>
                                      <Grid item xs={12} md={4}>
                                          <OutputBox>
                                              <Typography variant="body2" className="outputLabel" component="p">
                                                  {val.label === 'Calculated Output' ? 'Net Home Equity Value' : val.label }
                                                  {tooltip.default?.[val.label] &&
                                                  <CustomTooltip
                                                      tooltipText={tooltip.default?.[val.label]}
                                                      customTooltipStyle={{ maxWidth: 450 }}
                                                      label={val.label}
                                                  />}
                                              </Typography>
                                              <Typography variant="h5">
                                                  {allowedPrefix ? '$' : ''}
                                                  {allowedCurrPrefix ? props.currSymbol : ''}
                                                  {numberWithCommas(currValue)}
                                              </Typography>
                                          </OutputBox>
                                      </Grid>
                                  </Fragment>
                              );
                          })}
                      </Grid>
                  ) : (
                      <NoDataFound />
                  )}
              </Box>
          </CardContent>
      </Card>
  );
};

export default HomeValuationOutput;