import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Add, Close, Create, Delete, Done, FactCheck, RemoveRedEye } from '@mui/icons-material';
import RenderField from '../../../utils/RenderField';
import { useForm } from 'react-hook-form';
import { deleteBlog, fetchBlogCategory, fetchBlogsList, updateBlogCategory } from '../../../redux/actions/BlogTypes';
import Button from '../../../components/CustomButtons/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { a11yProps } from '../../../utils/Utils';
import SwipeableViews from 'react-swipeable-views';
import PortfolioTabPanel from '../../PortfolioValueUpload/PortfolioTabPanel';
import EnhancedTable from '../../../components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from '../../../components/CommonComponent/NoDataFound';
import CustomDialogTitle from '../../../components/DialogTitle';
import BlogEditor from '../../BlogEditor';
import moment from 'moment/moment';
import {
    getChatMessages,
    getValidatedChatMessages,
    putChatMessages,
    putValidateChatMessages,
    getDeleteChatMessages,
    getValidateDeleteChatMessages
} from '../../../redux/actions/SettingActions';
import { CustomButton } from '../../../assets/jss/ButtonStyle';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ChatbotMessagesAdmin = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const paginationContainer = useRef(null);
    const paginationValidateContainer = useRef(null);
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [enableEdits, setEnableEdits] = useState([]);
    const [selectedRowsSet, setSelectedRowsSet] = useState({});
    const [updatedVal, setUpdatedVal] = useState({});
    const [categorizeMessages, setCategorizeMessages] = useState({});
    const [openMessageEditor, setOpenMessageEditor] = useState(false);
    const [editMessageData, setEditMessageData] = useState(null);
    const [editMessageId, setEditMessageId] = useState('');
    const [isMessageEdit, setIsMessageEdit] = useState(false);
    // const { messagesList, botMessagesList } = useSelector((state) => state.chatMessages);
    const setting = useSelector((state) => state.setting);
    const [messagesList, setMessagesList] = useState([]);
    const [validatedMessagesList, setValidatedMessagesList] = useState([]);
    const handleTabChange = (_, newValue) => setSelectedTab(newValue);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { isSubmitting }
    } = useForm({
        mode: 'onChange'
    });

    const onSubmit = (message_data) => async (input) => {
        const data = {
            message_id: message_data.id,
            question: input.question,
            answer: input.answer
        };

        const validate_data = {
            validated_message_id: message_data.id,
            question: input.question,
            answer: input.answer
        };

        selectedTab !== 1
            ? await dispatch(putChatMessages(data, { document_type: 'regular' }))
            : await dispatch(putValidateChatMessages(validate_data));

        await getChatMessagesData({ document_type: message_data.document_type });

        handleMessageEditorClose();
        // setEnableEdits([]);
        // setSelectedRowsSet({});
        // reset();
        // setEditMessageData(null);
    };

    const handleMessageEditorClose = () => {
        setOpenMessageEditor(false);
        reset();
        setEditMessageData(null);

        // setIsMessageEdit(false);
        // setEditMessageId('');
    };

    const handleEditData = (id, message) => {
        setEditMessageData(message);
        setOpenMessageEditor(true);
        setValue('question', message.user_id ? message.user_message : message.question);
        setValue('answer', message.user_id ? message.ai_message : message.answer);
    };

    // TODO: add logic for deletion of the selected item
    const deleteSelectedData = (message_id) => {
        // dispatch(deleteMessage(id));
        dispatch(getDeleteChatMessages(message_id));
    };

    // TODO: add logic for Validate deletion of the selected item
    const deleteValidateSelectedData = (message_id) => {
        dispatch(getValidateDeleteChatMessages(message_id, { document_type: 'regular' }));
    };

    // TODO: add logic for Validate of the selected item
    const validateSelectedData = (message_id) => {
        const data = {
            message_id: message_id
        };
        dispatch(putChatMessages(data, { document_type: 'regular' }));
    };

    const getValidatedChatMessagesData = () => {
        dispatch(getValidatedChatMessages());
    };
    useEffect(() => {
        // This effect will run every time setting.validatedChatMessages changes
        setValidatedMessagesList(setting.validatedChatMessages);
    }, [setting.validatedChatMessages]);

    const getChatMessagesData = (data) => {
        dispatch(getChatMessages(data));
    };

    useEffect(() => {
        getChatMessagesData({ document_type: 'regular' });
    }, []);

    useEffect(() => {
        // This effect will run every time setting.chatMessages changes
        setMessagesList(setting.chatMessages);
    }, [setting.chatMessages]);

    useEffect(() => {
        const data = {};
        messagesList.forEach((message) => {
            const categoryName = message.document_type;
            if (!data[categoryName]) {
                data[categoryName] = [];
            }
            data[categoryName].push(message);
        });
        setCategorizeMessages(data);
    }, [messagesList]);

    // Function to limit the length of the answer to 100 symbols
    const limitAnswerLength = (answer) => {
        const maxLength = 100;
        return answer.length > maxLength ? answer.slice(0, maxLength) + '...' : answer;
    };

    const EditableCell = (initialValue, type, row, cell, options = [], extra = { commaSep: true }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        const [value, setCellValue] = useState(updatedVal);
        switch (type) {
            case 'select':
                valToPass = initialValue?.toString().toLowerCase();
                break;
            case 'searchSelect':
                valToPass = initialValue;
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        const id = [row.original.id, cell.column.id].join('_');
        // Check to make sure not all columns are editable
        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={`${row.values.id}__${cell.column.id}`}
                type={type}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.flag !== '' ? (
                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                            ) : (
                                ''
                            )}
                            <Typography variant="body2">{option.label}</Typography>
                        </Box>
                    );
                }}
                fieldName={cell.column.id}
                options={options}
                control={control}
                // required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                {...extra}
            />
        );
    };

    const categoryColumns = useMemo(() => {
        const result = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.id]
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: /^[\s\S]+$/g })
                        : value;
                }
            },
            {
                Header: 'Action',
                width: 50,
                Cell: ({ row }) => {
                    let actionIcons;
                    if (enableEdits?.[0]?.[row.original.id]) {
                        actionIcons = (
                            <>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <Done />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={() => {
                                            setEnableEdits([]);
                                            setSelectedRowsSet({});
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                    return <div className={'actionWrapper'}>{actionIcons}</div>;
                }
            }
        ];
        return result;
    }, [enableEdits, updatedVal]);

    const columns = useMemo(() => {
        const result = [
            {
                Header: 'Action',
                width: 132,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" onClick={(e) => handleEditData(row.original.id, row.original)}>
                                <Create />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="delete">
                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="validate">
                            <IconButton aria-label="validate" onClick={() => validateSelectedData(row.original.id)}>
                                <FactCheck />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            },
            {
                Header: 'User ID',
                accessor: 'user_id',
                disableFilters: false,
                Cell: ({ value }) => value || '-'
            },
            {
                Header: 'Question',
                accessor: 'user_message',
                disableFilters: true,
                Cell: ({ value, row }) => value || '-'
            },
            {
                Header: 'Answer',
                accessor: 'ai_message',
                disableFilters: true,
                Cell: ({ value, row }) => limitAnswerLength(value) || '-'
            },
            {
                Header: 'Bot Version',
                accessor: 'document_type',
                disableFilters: true,
                Cell: ({ value }) => value || '-'
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
                disableFilters: true,
                Cell: ({ value }) => moment(value, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('DD/MM/YYYY h:mm A') || '-'
            }
        ];
        return result;
    });

    const validatedColumns = useMemo(() => {
        const result = [
            {
                Header: 'Action',
                width: 120,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={(e) => deleteValidateSelectedData(row.original.id)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" onClick={(e) => handleEditData(row.original.id, row.original)}>
                                <Create />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            },
            {
                Header: 'Message ID',
                accessor: 'id',
                disableFilters: false,
                Cell: ({ value }) => value || '-'
            },
            {
                Header: 'Question',
                accessor: 'question',
                disableFilters: true,
                Cell: ({ value, row }) => value || '-'
            },
            {
                Header: 'Answer',
                accessor: 'answer',
                disableFilters: true,
                Cell: ({ value, row }) => limitAnswerLength(value) || '-'
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
                disableFilters: true,
                Cell: ({ value }) => moment(value, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('DD/MM/YYYY h:mm A') || '-'
            }
        ];
        return result;
    });

    return (
        <Card>
            <CardContent>
                <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6"> Chat Messages </Typography>
                    <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap="10px"></Stack>
                </Stack>
                <Box height="10px" />
                <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                <Box height="10px" />

                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    sx={{ background: 'white' }}
                    // variant="scrollable"
                    allowScrollButtonsMobile={true}
                >
                    <Tab
                        key={'Unvalidated Regular'}
                        label={'unvalidated messages'}
                        {...a11yProps('regular')}
                        onClick={() => getChatMessagesData({ document_type: 'regular' })}
                    />
                    ;
                    {/* <Tab key={"Unvalidated Budget"} label={"Budget Messages"} {...a11yProps("budget")} onClick={() => getChatMessagesData({"document_type": "budget"})} />; */}
                    {/* <Tab key={"Unvalidated Portfolio"} label={"Portfolio Messages"} {...a11yProps("portfolio")} onClick={() => getChatMessagesData({"document_type": "portfolio"})}  />; */}
                    <Tab
                        key={'Validated'}
                        label={'Knowledge Base'}
                        {...a11yProps('Validated')}
                        onClick={() => getValidatedChatMessagesData()}
                    />
                    ;
                </Tabs>
                <SwipeableViews
                    index={selectedTab}
                    onChangeIndex={(index) => setSelectedTab(index)}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                >
                    {/*TODO: we are using the "selectedTab !== 3" in case when we have 3 different unvalidated messages views */}
                    {selectedTab !== 1
                        ? // Render content for other tabs
                          messagesList.map((message, index) => (
                              <PortfolioTabPanel value={selectedTab} index={index} dir={theme.direction}>
                                  {categorizeMessages[message.document_type]?.length > 0 ? (
                                      <>
                                          <EnhancedTable
                                              container={paginationContainer}
                                              columns={columns}
                                              data={categorizeMessages[message.document_type] || []}
                                          />
                                          <Box
                                              ref={paginationContainer}
                                              style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  '.css-tsnvvp-MuiTablePagination-displayedRows': {
                                                      right: '35px'
                                                  }
                                              }}
                                          />
                                      </>
                                  ) : (
                                      <NoDataFound msg={`No data found`} style={{ padding: '20px', fontWeight: '700' }} />
                                  )}
                              </PortfolioTabPanel>
                          ))
                        : // Render content for the "Knowledge Base" tab
                          validatedMessagesList?.map((message, index) => (
                              <PortfolioTabPanel value={selectedTab} index={index} dir={theme.direction}>
                                  {validatedMessagesList?.length > 0 ? (
                                      <>
                                          <EnhancedTable
                                              container={paginationValidateContainer}
                                              columns={validatedColumns}
                                              data={validatedMessagesList || []}
                                          />
                                          <Box
                                              ref={paginationValidateContainer}
                                              style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  '.css-tsnvvp-MuiTablePagination-displayedRows': {
                                                      right: '35px'
                                                  }
                                              }}
                                          />
                                      </>
                                  ) : (
                                      <NoDataFound msg={`No data found`} style={{ padding: '20px', fontWeight: '700' }} />
                                  )}
                              </PortfolioTabPanel>
                          ))}
                </SwipeableViews>
            </CardContent>
            <Dialog open={openMessageEditor} onClose={handleMessageEditorClose} aria-labelledby="form-todo-title" maxWidth>
                <CustomDialogTitle id="form-todo-title" onClose={handleMessageEditorClose}>
                    Chat Message Validator
                </CustomDialogTitle>
                <Divider />
                <Box sx={{ width: '1000px' }}>
                    <form onSubmit={handleSubmit(onSubmit(editMessageData))}>
                        <Grid container>
                            <Grid item xs={12} style={{ margin: '20px' }}>
                                <RenderField
                                    key={editMessageData?.user_message || editMessageData?.question}
                                    name="question"
                                    control={control}
                                    label={'Question'}
                                    id={'user_message'}
                                    type={'text'}
                                    required={true}
                                    placeholder={'User Question'}
                                    valid={'noValid'}
                                    // defaultValue={editMessageData?.user_message || editMessageData?.question}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ margin: '20px' }}>
                                {/*<div style={{marginBottom: "10px", marginLeft: "5px"}}>Answer</div>*/}
                                <RenderField
                                    key={editMessageData?.ai_message || editMessageData?.answer}
                                    name="answer"
                                    control={control}
                                    label={'Answer'}
                                    id={'ai_message'}
                                    // type={'text'}
                                    required={true}
                                    placeholder={'AI Answer'}
                                    setValue={setValue}
                                    // defaultValue={editMessageData?.ai_message || editMessageData?.answer}
                                    type={'multiline'}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                    {selectedTab === 1 ? 'Edit' : 'Validate'}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Dialog>
        </Card>
    );
};

export default ChatbotMessagesAdmin;
