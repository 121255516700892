import { Breadcrumbs, Card, CardContent, Popover, Tooltip, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from 'components/CustomButtons/Button';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchSelectedRecurring } from 'redux/actions/RecurringPaymentActions';
import { StyledPortfolioBreadcrumb } from 'assets/jss/CommonCalStyle';
import { StyledBreadCrumbLink } from 'assets/jss/GeneralStyle';
import { StyledBadge } from 'assets/jss/CalculatorStyle';
import { useNavigate } from 'react-router-dom';
import { setActiveSettingsTab } from 'redux/actions/SettingActions';
import { submitProfile } from 'redux/actions/SettingActions';
var refetchVal = false;
const RecurringBreadcrumb = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setting = useSelector((state) => state.setting);
    const { classes } = props;
    const [selectedRecurring, setSelectedRecurring] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = React.useState([]);
    const openBredcrumbDropdown = Boolean(anchorEl);
    const id = openBredcrumbDropdown ? 'simple-popover' : undefined;

    useEffect(() => {
        if(props.list[0] == undefined){
            return;
        }
        else{
            props.setSelecteRecurringVal(selectedRecurring);
        }
    }, [selectedRecurring]);

    useEffect(() => {
        //auto select first portfolio file
        if (Object.keys(selectedRecurring).length === 0 || refetchVal) {
            if (props.list.length > 0) {
                refetchVal = false;
                fetchFirstRecurringValue();
            } else {
                refetchVal = false;
                setSelectedRecurring({});
                setChecked([]);
            }
        }
    }, [props.list]);

    useEffect(() => {
        // when data will update in table then need to fetch updated list from api
        if (props.renderInputList) {
            handleRecurringSelection();
            props.resetInputList();
        }
    }, [props.renderInputList]);
    const fetchFirstRecurringValue = () => {
        const isSavedAvail = props.list.filter((val) => val.is_saved === true);
        if (isSavedAvail.length > 0) {
            const fid = props.list.filter((val) => val.is_saved === true).map(({ id }) => id);
            setChecked(fid);
            setSelectedRecurring(isSavedAvail[0]);
            handleRecurringSelection(fid);
        } else {
            handleToggle(props.list[0].id);
            setSelectedRecurring(props.list[0]);
            handleRecurringSelection([props.list[0].id]);
        }
    };
    /**
     * trim file name with extention and return only file name
     * @param {*} name
     * @returns
     */
    const fetchFileName = (name) => {
        let fName = name;
        const currFileName = fName.split('.');
        const fileExt = currFileName.length > 1 ? '.' + currFileName[currFileName.length - 1] : '';
        return fName.replace(fileExt, '');
    };
    // call when need to fetch selected Recurring file
    const handleRecurringSelection = (val = null, save = false) => {
        props.setSelectedTab(0)
        const bankFiles = [];
        const normalFiles = [];
        checked.forEach((id) => {
            const file = props.list.find((list) => list.id === id);
            if (file?.is_bank) {
                bankFiles.push(id);
            } else {
                normalFiles.push(id);
            }
        });
        const idToPass = val || normalFiles;
        setAnchorEl(null);
        if (idToPass.length > 0 || bankFiles.length > 0) {
            const updatedList = props.list.filter((val) => val.id === idToPass[0] || val.id === bankFiles[0]);
            setSelectedRecurring(updatedList[0]);
            props.selectedRecurringCount(normalFiles);
            dispatch(fetchSelectedRecurring({ ids: idToPass, bank_ids: bankFiles, is_saved: save || undefined }));
        } else {
            toast.error('Please select at least one Recurring Payment file.');
        }
    };

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        props.selectedRecurringCount(newChecked);
        setChecked(newChecked);
    };

    const handleAllowAccess = () => {
        dispatch(submitProfile({ allow_external_account: true }));
    };
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledPortfolioBreadcrumb>
                    <Link color="text.primary" underline="none" onClick={(e) => props.handleTabChange(e, 1)} component="button">
                        <Typography variant="subtitle1">
                            {Object.keys(selectedRecurring).length === 0 ? 'Click here to upload Recurring Payments' : 'My Recurring Payments(s)'}
                        </Typography>
                    </Link>
                    {Object.keys(selectedRecurring).length > 0 ? (
                        <StyledBreadCrumbLink
                            // color="textPrimary"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            aria-describedby={id}
                            underline="none"
                            color="inherit"
                        // component="button"
                        >
                            <Typography className={classes.portfolioFileNameBread}>{fetchFileName(selectedRecurring.file_name)} </Typography>
                            {checked.length > 1 ? <StyledBadge badgeContent={`+${checked.length - 1}`} color="secondary" /> : ''}
                            {openBredcrumbDropdown ? <ExpandLess className={'arrow-icon'} /> : <ExpandMore className={'arrow-icon'} />}
                        </StyledBreadCrumbLink>
                    ) : (
                        ''
                    )}
                </StyledPortfolioBreadcrumb>
                {setting.plaidDetail.user_detail.length > 0 ? (
                    <Tooltip
                        title={
                            !setting.profileDetail.allow_external_account
                                ? 'We noticed you already have linked a bank account. Would you like us to use this to access your Recurring files? '
                                : 'Already allowed'
                        }
                    >
                        <Box>
                            <Button
                                size="small"
                                color="info"
                                // onClick={handleAllowAccess}
                                disabled={setting.profileDetail.allow_external_account}
                            >
                                Allow to use External Account
                            </Button>
                        </Box>
                    </Tooltip>
                ) : (
                    <Tooltip title="Click here to use our highly secure parneter to connect you to your external acccount">
                        <Button
                            size="small"
                            color="info"
                            onClick={() => {
                                // navigate('/settings');
                                // dispatch(setActiveSettingsTab(3));
                            }}
                        >
                            Link External Account
                        </Button>
                    </Tooltip>
                )}
            </Box>
            <Popover
                id={id}
                open={openBredcrumbDropdown}
                anchorEl={anchorEl}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {props.list.map((val, listIndex) => {
                    return (
                        <List key={'breadcrumbList' + listIndex} component="nav" aria-labelledby="budget-nested-list-subheader">
                            <Collapse in={openBredcrumbDropdown} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem role={undefined} dense button onClick={() => handleToggle(val.id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(val.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': val.id }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={val.id} primary={fetchFileName(val.file_name)} />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    );
                })}
                {/* <div className={classes.submitPortSelectionBtn}>
                  <Button variant="contained" size="sm" color="info" onClick={() => handleRecurringSelection()}>
                      Load Data!
                  </Button>
              </div> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: 1 }}>
                    <CustomButton variant="contained" size="sm" color="info" onClick={() => handleRecurringSelection()}>
                        Load Data!
                    </CustomButton>
                    <CustomButton variant="contained" size="sm" color="success" onClick={() => handleRecurringSelection(null, true)}>
                        Save Settings
                    </CustomButton>
                </Box>
            </Popover>
        </>
    );
};

export default RecurringBreadcrumb;
